import { combineReducers } from 'redux'
import uniqBy from 'lodash.uniqby'

import * as actionTypes from 'constants/actionTypes/notifications'
import { fetchStatusHelpers } from '@mindz/react-helpers'

const {
    initialState,
    requestState,
    successState,
    failureState
} = fetchStatusHelpers

const fetchStatusReducer = (state = initialState(), action) => {
    if (actionTypes.FETCH_NOTIFICATIONS_REQUEST === action.type) {
        return requestState()
    }

    if (actionTypes.FETCH_NOTIFICATIONS_SUCCESS === action.type) {
        return successState(action)
    }

    if (actionTypes.FETCH_NOTIFICATIONS_FAILURE === action.type) {
        return failureState(action)
    }

    return state
}

const dataReducer = (state = [], action) => {
    if (actionTypes.FETCH_NOTIFICATIONS_SUCCESS === action.type) {
        return uniqBy([...state, ...action.payload.data], 'id')
    }

    if (actionTypes.TAG_NOTIFICATION_AS_READ_SUCCESS === action.type) {
        const notificationId = action.notificationId
        if (notificationId) {
            const index = state.findIndex(
                notification => notification.id === Number(notificationId)
            )

            if (index !== -1) {
                return [
                    ...state.slice(0, index),
                    {
                        ...state[index],
                        read: true
                    },
                    ...state.slice(index + 1)
                ]
            }
        }
    }

    return state
}

const pagesReducer = (state = { current: 1, last: null }, action) => {
    if (actionTypes.FETCH_NOTIFICATIONS_SUCCESS === action.type) {
        const { current_page, last_page } = action.payload.meta

        return {
            current: current_page,
            last: last_page
        }
    }

    return state
}

export default combineReducers({
    fetchStatus: fetchStatusReducer,
    data: dataReducer,
    pages: pagesReducer
})
