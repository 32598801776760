import React, { Component } from 'react'
import { hot } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import AdminSignInPage from 'pages/AdminSignInPage'
import SignInPage from 'pages/SignInPage'
// import RegisterPage from 'pages/RegisterPage'
import ResetPasswordPage from 'pages/ResetPasswordPage'
import AccountActivatePage from 'pages/AccountActivatePage'
import LogoutPage from 'pages/LogoutPage'
import PrivateRoute from './routes/PrivateRoute'
import AppRoutes from 'routes/AppRoutes'

import configureStore from 'store/configureStore'

import './reset.css'
import './global.css'
import './App.css'

const store = configureStore()

const Router = hot(module)(() => (
    <BrowserRouter>
        <Switch>
            <Route path="/logowanie" component={SignInPage} />
            {/*<Route path="/rejestracja" component={RegisterPage} />*/}
            <Route
                path="/aktywacja-konta/:token?"
                component={AccountActivatePage}
            />
            <Route
                path="/resetowanie-hasla/:token?"
                component={ResetPasswordPage}
            />
            <Route path="/admin" component={AdminSignInPage} />
            <Route path="/wyloguj" component={LogoutPage} />
            <PrivateRoute path="/" component={AppRoutes} />
        </Switch>
    </BrowserRouter>
))

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router />
            </Provider>
        )
    }
}

export default App
