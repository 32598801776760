import React, { Component } from 'react'
import CreateFirstProduct from './CreateFirstProduct'
import withLogic from './withLogic'

class CreateFirstProductContainer extends Component {
    render() {
        return <CreateFirstProduct {...this.props} />
    }
}

export default withLogic()(CreateFirstProductContainer)
