import React, { Component } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'

import { InputField } from 'components/forms'

class DebounceInputField extends Component {
    constructor(props) {
        super(props)
        this.emitChangeDebounced = debounce(this.emitChange, props.wait)
    }

    componentWillUnmount() {
        this.emitChangeDebounced.cancel()
    }

    render() {
        return (
            <InputField
                {...this.props}
                handleChange={this.handleChange}
                handleClear={this.handleClear}
            />
        )
    }

    handleChange = e => {
        const value = e.target.value
        this.props.handleChange(value)
        this.emitChangeDebounced(value)
    }

    handleClear = () => {
        const value = ''
        this.props.handleChange(value)
        this.emitChangeDebounced(value)
    }

    emitChange(value) {
        this.props.handleDebounceChange(value)
    }
}

DebounceInputField.defaultProps = {
    wait: 350
}

DebounceInputField.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleDebounceChange: PropTypes.func.isRequired
}

export default DebounceInputField
