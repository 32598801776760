import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import ProductListPage from 'pages/ProductListPage'
import ProductPage from 'pages/ProductPage'
import ProductCreatePage from 'pages/ProductCreatePage'

class ProductRoutes extends Component {
    render() {
        const { match } = this.props

        return (
            <Switch>
                <Route path={match.path} exact component={ProductListPage} />
                <Route path={match.path + '/:id'} exact component={ProductPage} />
                <Route
                    path={match.path + '/:id/edytuj'}
                    component={ProductCreatePage}
                />
            </Switch>
        )
    }
}

export default ProductRoutes
