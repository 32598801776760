import { combineReducers } from 'redux'

import userReducer from './user'
import accountReducer from './account'
import categoriesReducer from './categories'
import notificationsReducer from './notifications'
import productsReducer from './products'
import companiesReducer from './companies'
import flashMessagesReducer from './flashMessages'

export default combineReducers({
    user: userReducer,
    account: accountReducer,
    categories: categoriesReducer,
    products: productsReducer,
    notifications: notificationsReducer,
    companies: companiesReducer,
    flashMessages: flashMessagesReducer
})
