import DashboardIcon from 'assets/dashboard.module.svg'
import ProductIcon from 'assets/product.module.svg'
import CompanyIcon from 'assets/company.module.svg'

export const shortcuts = (isManufacturer, isSalesman) => {
    if (isManufacturer) {
        return [
            {
                name: 'zgłoszenia',
                link: '/zgloszenia',
                icon: ProductIcon
            }
        ]
    }

    if (isSalesman) {
        return [
            {
                name: 'zgłoszenia',
                link: '/zgloszenia',
                icon: ProductIcon
            },
            {
                name: 'firmy',
                link: '/firmy',
                icon: CompanyIcon
            }
        ]
    }

    return [
        {
            name: 'dashboard',
            link: '/',
            icon: DashboardIcon
        },
        {
            name: 'zgłoszenia',
            link: '/zgloszenia',
            icon: ProductIcon
        },
        {
            name: 'firmy',
            link: '/firmy',
            icon: CompanyIcon
        }
    ]
}
