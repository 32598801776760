import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@mindz/react-hoc'
import jump from 'jump.js'
import moment from 'moment'
import idx from 'idx'

import styles from './index.module.css'
import Table from 'components/Table'
import Gallery from 'components/Gallery'
import ProductStatus from './ProductStatus'
import { displayNumber } from 'helpers/products'

import IconArrow from 'assets/icons/arrow.module.svg'
import IconSuitcase from 'assets/icons/suitcase.module.svg'
import IconWatch from 'assets/icons/watch.module.svg'
import IconLoupe from 'assets/icons/loupe.module.svg'
import IconCamera from 'assets/icons/camera.module.svg'
import IconNoImage from 'assets/icons/no-image.module.svg'

const IMAGE_DIMENSIONS = '?width=270&height=270'

class ProductListPage extends Component {
    componentDidMount() {
        document.getElementsByTagName('BODY')[0].classList.add('pageProduct')

        window.scrollTo(0, 0)
    }

    componentWillUnmount() {
        document.getElementsByTagName('BODY')[0].classList.remove('pageProduct')
    }

    render() {
        const { cx, product } = this.props

        return (
            <div className={cx('root')}>
                <Link to="/zgloszenia" className={cx('goBack')}>
                    <IconArrow />
                    <span>Wróć do zgłoszeń</span>
                </Link>
                <section className={cx('holder')}>
                    <section className={cx('content')}>
                        {this.renderHeader()}
                        {this.renderTabs()}
                        {this.renderAbout()}
                        {this.renderNutritions()}
                        {this.renderWrapping()}
                        {this.renderCollectiveWrapping()}
                        {this.renderInformations()}
                    </section>
                    <aside className={cx('sidebar')}>
                        <ProductStatus product={product} />
                    </aside>
                </section>
            </div>
        )
    }

    get tabs() {
        return [
            {
                id: 'about',
                label: 'O produkcie',
                predicate: true
            },
            {
                id: 'nutritions',
                label: 'Wartość odżywcza',
                predicate: this.hasNutritions()
            },
            {
                id: 'wrapping',
                label: 'Opakowanie',
                predicate: this.hasWrapping()
            },
            {
                id: 'informations',
                label: 'Dodatkowe informacje',
                predicate: this.hasInformations()
            }
        ].filter(item => item.predicate)
    }

    isFood() {
        const { product } = this.props

        return !!idx(product, _ => _.category.food)
    }

    hasComponents() {
        const { product } = this.props

        if (!this.isFood()) {
            return false
        }

        return !!product.components
    }

    hasNutritions() {
        const { product } = this.props

        if (!this.isFood()) {
            return false
        }

        return !!product.nutritions
    }

    hasWrapping() {
        const { product } = this.props

        return !!product.wrapping
    }

    hasWrappingValueAndUnit() {
        const { product } = this.props

        return (
            this.hasWrapping() &&
            product.wrapping.value &&
            product.wrapping.unit
        )
    }

    hasCollectiveWrapping() {
        const { product } = this.props

        return !!product.collective_wrapping
    }

    hasInformations() {
        const { product } = this.props

        return !!product.informations
    }

    renderHeader() {
        const { cx, product, handleToggleGallery, isGalleryOpen } = this.props

        const requestedAt = product.requested_at
            ? moment(product.requested_at).format('YYYY/MM/DD')
            : null

        const mainImage = product.images.filter(image => image.main === 1)[0]
        const isAnyPhoto =
            product && product.images && product.images.length > 0

        return (
            <header className={cx('header')}>
                {isAnyPhoto && (
                    <Gallery
                        title=""
                        isOpen={isGalleryOpen}
                        handleClose={handleToggleGallery}
                        images={product.images}
                        firstImageIndex={product.images.findIndex(
                            image => image.main === 1
                        )}
                    />
                )}
                <div className={cx('gallery')} onClick={handleToggleGallery}>
                    {!isAnyPhoto && (
                        <div className={cx('placeholder')}>
                            <IconNoImage />
                            <p>Brak zdjęć</p>
                        </div>
                    )}

                    {mainImage && (
                        <div
                            className={cx('image')}
                            style={{
                                backgroundImage: `url(${
                                    mainImage.path
                                }${IMAGE_DIMENSIONS})`
                            }}
                        >
                            {product.name}
                        </div>
                    )}
                    {isAnyPhoto && (
                        <Fragment>
                            <div className={cx('imageCount')}>
                                <IconCamera />
                                <span>{product.images.length}</span>
                            </div>
                            <div className={cx('loupeContainer')}>
                                <IconLoupe />
                            </div>
                        </Fragment>
                    )}
                </div>
                <div className={cx('description')}>
                    <h1>{product.name}</h1>
                    {this.renderCategory()}
                    <ul>
                        <li>
                            <IconSuitcase />
                            <strong>Producent</strong>
                            <p>{this.renderCompanyName()}</p>
                        </li>
                        {!!requestedAt && (
                            <li>
                                <IconWatch />
                                <strong>Data zgłoszenia</strong>
                                <p>{requestedAt}</p>
                            </li>
                        )}
                    </ul>
                </div>
            </header>
        )
    }

    renderCompanyName() {
        const { product, isManufacturer } = this.props

        const id = idx(product, _ => _.manufacturer.company.id)
        const name = idx(product, _ => _.manufacturer.company.name)

        if (isManufacturer) {
            return name
        }

        return <Link to={'/firmy/' + id}>{name}</Link>
    }

    renderCategory() {
        const { cx, product } = this.props

        if (!product.category) {
            return null
        }

        return (
            <p className={cx('categories')}>
                {product.category.name}
                {this.renderSubcategory()}
            </p>
        )
    }

    renderSubcategory() {
        const { cx, product } = this.props

        if (!product.subcategory) {
            return null
        }

        return (
            <span className={cx('subcategories')}>
                {' '}
                / {product.subcategory.name}
            </span>
        )
    }

    renderTabs() {
        const { cx } = this.props

        return (
            <ul className={cx('tabs')}>
                {this.tabs.map(tab => (
                    <li
                        key={tab.id}
                        onClick={() =>
                            jump(document.getElementById(tab.id), {
                                offset: -70
                            })
                        }
                    >
                        {tab.label}
                    </li>
                ))}
            </ul>
        )
    }

    renderAbout() {
        const { product } = this.props

        const priceNettoDisplay = displayNumber(
            product.netto_price,
            'PLN / szt.'
        )

        return (
            <article id="about">
                <h2>Informacje o produkcie</h2>
                <Table>
                    <Table.Content>
                        <tr>
                            <td>Czy produkt jest dostępny na rynku?</td>
                            <td>
                                {!!product.available_on_market_from
                                    ? 'Tak'
                                    : 'Nie'}
                            </td>
                            <td>
                                {!!product.available_on_market_from &&
                                    `od ${product.available_on_market_from} r.`}
                            </td>
                        </tr>
                        <tr>
                            <td>Czy produkt jest zarejestrowany jako marka?</td>
                            <td>{!!product.brand_name ? 'Tak' : 'Nie'}</td>
                            <td>{product.brand_name}</td>
                        </tr>
                        {!!priceNettoDisplay && (
                            <tr>
                                <td>Cena sprzedaży netto w Kaufland</td>
                                <td>{priceNettoDisplay}</td>
                                <td />
                            </tr>
                        )}
                    </Table.Content>
                </Table>
                {this.renderDescription()}
                {this.renderComponents()}
            </article>
        )
    }

    renderDescription() {
        const { cx, product } = this.props

        if (!product.description) {
            return null
        }

        return (
            <div className={cx('description')}>
                <h3>Opis produktu</h3>
                <p>{product.description}</p>
            </div>
        )
    }

    renderComponents() {
        const { cx, product } = this.props

        if (!this.hasComponents()) {
            return null
        }

        return (
            <div className={cx('ingredients')}>
                <hr />
                <h3>Składniki</h3>
                <p>{product.components}</p>
            </div>
        )
    }

    renderNutritions() {
        const { product } = this.props

        if (!this.hasNutritions()) {
            return null
        }

        const data = product.nutritions
        const wrapping = product.wrapping
        let smallUnit = "g"

        if (wrapping.unit === "ml" || wrapping.unit === "l") {
            smallUnit = "ml"
        }

        return (
            <article id="nutritions">
                <h2>Wartość odżywcza</h2>
                <Table>
                    <Table.Header>
                        <th>WARTOŚĆ ODŻYWCZA</th>
                        <th>100 {smallUnit}</th>
                        <th>
                            {this.hasWrappingValueAndUnit() &&
                                displayNumber(
                                    wrapping.value,
                                    wrapping.unit +
                                        (wrapping.kind
                                            ? ' (' + wrapping.kind + ')'
                                            : '')
                                )}
                        </th>
                    </Table.Header>
                    <Table.Content>
                        <tr>
                            <td>Wartość energetyczna</td>
                            <td>{displayNumber(data.energy_value, 'kcal')}</td>
                            <td>
                                {displayNumber(
                                    data.energy_value_per_item,
                                    'kcal'
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Tłuszcz</td>
                            <td>{displayNumber(data.fat, 'g')}</td>
                            <td>{displayNumber(data.fat_per_item, 'g')}</td>
                        </tr>
                        <tr>
                            <td>- w tym kwasy tłuszcz. nasycone</td>
                            <td>{displayNumber(data.saturated_fat, 'g')}</td>
                            <td>
                                {displayNumber(
                                    data.saturated_fat_per_item,
                                    'g'
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Węglowodany</td>
                            <td>{displayNumber(data.carbohydrate, 'g')}</td>
                            <td>
                                {displayNumber(data.carbohydrate_per_item, 'g')}
                            </td>
                        </tr>
                        <tr>
                            <td>- w tym cukry</td>
                            <td>{displayNumber(data.sugar, 'g')}</td>
                            <td>{displayNumber(data.sugar_per_item, 'g')}</td>
                        </tr>
                        <tr>
                            <td>Błonnik</td>
                            <td>{displayNumber(data.roughage, 'g')}</td>
                            <td>
                                {displayNumber(data.roughage_per_item, 'g')}
                            </td>
                        </tr>
                        <tr>
                            <td>Białko</td>
                            <td>{displayNumber(data.protein, 'g')}</td>
                            <td>{displayNumber(data.protein_per_item, 'g')}</td>
                        </tr>
                        <tr>
                            <td>Sól</td>
                            <td>{displayNumber(data.salt, 'g')}</td>
                            <td>{displayNumber(data.salt_per_item, 'g')}</td>
                        </tr>
                    </Table.Content>
                </Table>
            </article>
        )
    }

    renderWrapping() {
        const { product } = this.props

        if (!this.hasWrapping()) {
            return null
        }

        const data = product.wrapping

        return (
            <article id="wrapping">
                <h2>Opakowanie jednostkowe</h2>
                <Table>
                    <Table.Content>
                        <tr>
                            <td>Rodzaj opakowania</td>
                            <td>{data.kind}</td>
                        </tr>
                        <tr>
                            <td>Waga</td>
                            <td>{displayNumber(data.value, data.unit)}</td>
                        </tr>
                    </Table.Content>
                </Table>

                <h3>Wymiary produktu</h3>
                <Table>
                    <Table.Content>
                        <tr>
                            <td>Długość</td>
                            <td>{displayNumber(data.length, 'cm')}</td>
                        </tr>
                        <tr>
                            <td>Szerokość</td>
                            <td>{displayNumber(data.width, 'cm')}</td>
                        </tr>
                        <tr>
                            <td>Wysokość</td>
                            <td>{displayNumber(data.height, 'cm')}</td>
                        </tr>
                    </Table.Content>
                </Table>
            </article>
        )
    }

    renderCollectiveWrapping() {
        const { product } = this.props

        if (!this.hasCollectiveWrapping()) {
            return null
        }

        const data = product.collective_wrapping

        return (
            <article id="collective-wrapping">
                <h2>Opakowanie zbiorcze</h2>
                <Table>
                    <Table.Content>
                        <tr>
                            <td>Rodzaj opakowania</td>
                            <td>{data.kind}</td>
                        </tr>
                        <tr>
                            <td>Ilość sztuk produktu</td>
                            <td>{displayNumber(data.product_count, 'szt.')}</td>
                        </tr>
                        <tr>
                            <td>Waga</td>
                            <td>{displayNumber(data.weight, 'kg')}</td>
                        </tr>
                        <tr>
                            <td>Długość</td>
                            <td>{displayNumber(data.length, 'cm')}</td>
                        </tr>
                        <tr>
                            <td>Szerokość</td>
                            <td>{displayNumber(data.width, 'cm')}</td>
                        </tr>
                        <tr>
                            <td>Wysokość</td>
                            <td>{displayNumber(data.height, 'cm')}</td>
                        </tr>
                    </Table.Content>
                </Table>
            </article>
        )
    }

    renderInformations() {
        const { product } = this.props

        if (!this.hasInformations()) {
            return null
        }

        const data = product.informations

        return (
            <article id="informations">
                <h2>Dodatkowe informacje</h2>
                <Table>
                    <Table.Content>
                        <tr>
                            <td>Kraj pochodzenia</td>
                            <td>{data.country}</td>
                        </tr>
                        <tr>
                            <td>Miejscowość produkcji</td>
                            <td>{data.city}</td>
                        </tr>
                        <tr>
                            <td>Rok powstania produktu</td>
                            <td>{data.created_since}</td>
                        </tr>
                    </Table.Content>
                </Table>
            </article>
        )
    }
}

export default withStyles(styles)(ProductListPage)
