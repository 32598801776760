import * as yup from 'yup'

export default yup.object().shape({
    oldPassword: yup.string().required('To pole jest wymagane'),
    newPassword: yup
        .string()
        .min(8, 'Hasło musi zawierać co najmniej 8 znaków')
        .notOneOf(
            [yup.ref('oldPassword')],
            'Nowe hasło musi się różnić od poprzedniego'
        )
        .required('To pole jest wymagane'),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref('newPassword')], 'Powtórzone hasło nie pasuje')
        .required('To pole jest wymagane')
})
