import { combineReducers } from 'redux'
import currentCompanyReducer from './currentCompany'

import * as actionTypes from 'constants/actionTypes/companies'
import { fetchStatusHelpers } from '@mindz/react-helpers'

const {
    initialState,
    requestState,
    successState,
    failureState
} = fetchStatusHelpers

const fetchStatusReducer = (state = initialState(), action) => {
    if (actionTypes.FETCH_COMPANIES_REQUEST === action.type) {
        return requestState()
    }

    if (actionTypes.FETCH_COMPANIES_SUCCESS === action.type) {
        return successState()
    }

    if (actionTypes.FETCH_COMPANIES_FAILURE === action.type) {
        return failureState()
    }

    return state
}

const dataReducer = (state = [], action) => {
    if (actionTypes.FETCH_COMPANIES_SUCCESS === action.type) {
        return [...action.payload.data]
    }

    if (actionTypes.FETCH_COMPANIES_NEXT_SUCCESS === action.type) {
        return [...state, ...action.payload.data]
    }

    return state
}

export const metaReducer = (
    state = { current: 1, last: null, count: 0 },
    action
) => {
    if (
        [
            actionTypes.FETCH_COMPANIES_SUCCESS,
            actionTypes.FETCH_COMPANIES_NEXT_SUCCESS
        ].includes(action.type)
    ) {
        const { total, current_page, pages } = action.payload.meta
        return {
            current: current_page,
            last: pages,
            count: total
        }
    }

    return state
}

export default combineReducers({
    fetchStatus: fetchStatusReducer,
    data: dataReducer,
    current: currentCompanyReducer,
    meta: metaReducer
})
