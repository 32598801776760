import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@mindz/react-hoc'
import { Formik } from 'formik'

import { Button, InputField, Field } from 'components/forms'
import FlashMessage from 'components/FlashMessage'
import UserCategories from './UserCategories'

import styles from './index.module.css'
import buttonStyles from './overrides/Button.module.css'

import IconArrow from 'assets/arrow_left.module.svg'

class UserPage extends Component {
    render() {
        const { cx } = this.props

        return (
            <div className={cx('root')}>
                <Link className={cx('return')} to="/uzytkownicy">
                    <IconArrow /> Wróć do użytkowników
                </Link>

                <div className={cx('content')}>
                    {this.props.isEdit ? (
                        <h2>Edytuj użytkownika</h2>
                    ) : (
                        <h2>Dodaj nowego użytkownika</h2>
                    )}
                    {this.renderForm()}
                </div>
            </div>
        )
    }

    renderForm() {
        const { cx, id, user, handleSubmit, handleCancel, isEdit } = this.props

        return (
            <Formik
                initialValues={{
                    email: user.email || '',
                    first_name: user.first_name || '',
                    last_name: user.last_name || '',
                    categories: user.categories || []
                }}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                re
                render={({
                    handleSubmit,
                    isSubmitting,
                    values,
                    errors,
                    status,
                    setFieldValue
                }) => {
                    return (
                        <form
                            className={cx('form')}
                            onSubmit={e => {
                                e.stopPropagation()
                                handleSubmit(e)
                            }}
                        >
                            {status && status.success && (
                                <FlashMessage
                                    type="success"
                                    title="Dane zostały zaktualizowane"
                                />
                            )}

                            {errors.error && (
                                <FlashMessage
                                    type="failure"
                                    title={errors.error}
                                />
                            )}

                            <section className={cx('user')}>
                                <div className={cx('inputWrapper')}>
                                    <Field
                                        name="email"
                                        headerLabel="E-mail"
                                        type="text"
                                        placeholder=""
                                        component={InputField}
                                        isReadOnly={isEdit}
                                    />
                                </div>

                                <div className={cx('inputWrapper')}>
                                    <Field
                                        name="first_name"
                                        headerLabel="Imię"
                                        type="text"
                                        placeholder=""
                                        component={InputField}
                                    />
                                </div>
                                <div className={cx('inputWrapper')}>
                                    <Field
                                        name="last_name"
                                        headerLabel="Nazwisko"
                                        type="text"
                                        placeholder=""
                                        component={InputField}
                                    />
                                </div>
                            </section>

                            <UserCategories
                                name="categories"
                                id={id}
                                categories={values.categories}
                                setFieldValue={setFieldValue}
                            />

                            <div className={cx('actions')}>
                                <Button
                                    type="button"
                                    size="m-size"
                                    theme="white"
                                    onClick={handleCancel}
                                    className={cx('grow0')}
                                    customStyles={buttonStyles}
                                >
                                    Anuluj
                                </Button>
                                <Button
                                    type="submit"
                                    size="m-size"
                                    isLoading={isSubmitting}
                                    className={cx('grow1')}
                                    customStyles={buttonStyles}
                                >
                                    Zapisz
                                </Button>
                            </div>
                        </form>
                    )
                }}
            />
        )
    }
}

export default withStyles(styles)(UserPage)
