import React, { Component, Fragment } from 'react'
import { Formik } from 'formik'
import { withStyles } from '@mindz/react-hoc'
import { Field } from '@mindz/react-admin-ui'

import SignInLayout from 'layouts/SignInLayout'

import { InputField, Button, Checkbox } from 'components/forms'
import FlashMessageContainer from 'components/FlashMassageContainer'
import RemindPasswordModal from './RemindPasswordModal'

import styles from './index.module.css'
import buttonStyles from './overrides/Button.module.css'
import inputFieldStyles from './overrides/InputField.module.css'

class SignInPage extends Component {
    render() {
        const {
            cx,
            message: Message,
            handleSubmit,
            isRemindPasswordModalOpen,
            toggleRemindPasswordModal
        } = this.props

        return (
            <SignInLayout
                title="Zaloguj się do swojego konta"
                subtitle="Logowanie"
                message={() => (
                    <Fragment>
                        <FlashMessageContainer />
                        {Message && <Message />}
                    </Fragment>
                )}
            >
                <div className={cx('root')}>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                            remember: false
                        }}
                        onSubmit={handleSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                        render={({
                            handleSubmit,
                            isSubmitting,
                            values,
                            errors,
                            setFieldValue
                        }) => {
                            return (
                                <form
                                    className={cx('form')}
                                    onSubmit={handleSubmit}
                                >
                                    <div className="container-fluid">
                                        {errors.error && (
                                            <div className={cx('error')}>
                                                {errors.error}
                                            </div>
                                        )}

                                        <div className="row">
                                            <div className="col">
                                                <div className={cx('field')}>
                                                    <Field
                                                        customStyles={
                                                            inputFieldStyles
                                                        }
                                                        headerLabel="E-mail"
                                                        placeholder=""
                                                        name="email"
                                                        type="text"
                                                        component={InputField}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className={cx('field')}>
                                                    <Field
                                                        customStyles={
                                                            inputFieldStyles
                                                        }
                                                        headerLabel="Hasło"
                                                        name="password"
                                                        placeholder=""
                                                        type="password"
                                                        component={InputField}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className={cx('field')}>
                                                    <Field
                                                        id="signInRemember"
                                                        name="remember"
                                                        value={values.remember}
                                                        render={({
                                                            name,
                                                            value
                                                        }) => (
                                                            <Checkbox
                                                                id="signInRemember"
                                                                name={name}
                                                                value={value}
                                                                handleChange={() => {
                                                                    setFieldValue(
                                                                        name,
                                                                        !value
                                                                    )
                                                                }}
                                                            >
                                                                Zapamiętaj mnie
                                                            </Checkbox>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className={cx('actions')}>
                                                    <Button
                                                        type="submit"
                                                        isLoading={isSubmitting}
                                                        customStyles={
                                                            buttonStyles
                                                        }
                                                        size="m-size"
                                                        loadingIcon="round-arrow"
                                                    >
                                                        Zaloguj się
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div
                                                    className={cx(
                                                        'forgotPassword'
                                                    )}
                                                >
                                                    Nie pamiętasz hasła?{' '}
                                                    <span
                                                        className={cx('link')}
                                                        onClick={
                                                            toggleRemindPasswordModal
                                                        }
                                                    >
                                                        Odzyskaj hasło
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )
                        }}
                    />

                    <RemindPasswordModal
                        isOpen={isRemindPasswordModalOpen}
                        handleClose={toggleRemindPasswordModal}
                    />
                </div>
            </SignInLayout>
        )
    }
}

export default withStyles(styles)(SignInPage)
