import React from 'react'
import { Button } from 'components/forms'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@mindz/react-hoc'

import styles from './index.module.css'

const Actions = ({ cx, isLoading, isValidating, history, productId, handleDisableValidation,  }) => {
    return (
        <div className={cx('root')}>
            <Button
                size="m-size"
                type="button"
                theme="white"
                disabled={isValidating}
                loadingIcon="round-arrow"
                className="upper"
                onClick={() => history.push('/zgloszenia/' + productId)}
            >
                Zapisz jako robocze
            </Button>
            <Button
                size="m-size"
                type="submit"
                isLoading={isLoading}
                disabled={isValidating}
                loadingIcon="round-arrow"
                className="upper"
                onClick={() => handleDisableValidation()}
            >
                Wyślij
            </Button>
        </div>
    )
}

export default withRouter(withStyles(styles)(Actions))
