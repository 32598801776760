import React, { Component } from 'react'
import { Formik } from 'formik'
import { withStyles } from '@mindz/react-hoc'
import { Field } from '@mindz/react-admin-ui'
import * as yup from 'yup'

import { Modal } from 'components/modal'
import { Button, InputField } from 'components/forms'
import FlashMessage from 'components/FlashMessage'

import styles from './index.module.css'
import dialogStyles from './overrides/Dialog.module.css'
import buttonStyles from './overrides/Button.module.css'

const passwordSchema = yup.object().shape({
    password: yup.string().required('To pole jest wymagane'),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref('password')], 'Powtórzone hasło nie pasuje')
        .required('To pole jest wymagane')
});

class ResetPasswordModal extends Component {
    render() {
        const { cx, isOpen, handleSubmit, handleClose } = this.props;
        return (
            <Modal isOpen={isOpen}>
                <Modal.Dialog customStyles={dialogStyles}>
                    <Modal.Header handleClose={handleClose}>
                        <Modal.Title>Ustaw nowe hasło</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                password: '',
                                passwordConfirm: ''
                            }}
                            validateOnChange={false}
                            validationSchema={passwordSchema}
                            onSubmit={handleSubmit}
                            render={({
                                isSubmitting,
                                handleSubmit,
                                errors
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="container-fluid">
                                        {errors.error && (
                                            <FlashMessage
                                                type="failure"
                                                title={errors.error}
                                            />
                                        )}

                                        <div className="row">
                                            <div className="col">
                                                <Field
                                                    name="password"
                                                    headerLabel="Nowe hasło"
                                                    headerSecondLabel="Minimum 8 znaków"
                                                    placeholder=""
                                                    type="password"
                                                    component={InputField}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <Field
                                                    name="passwordConfirm"
                                                    headerLabel="Powtórz nowe hasło"
                                                    placeholder=""
                                                    type="password"
                                                    component={InputField}
                                                />
                                            </div>
                                        </div>

                                        <div className={cx('actions')}>
                                            <Button
                                                type="button"
                                                size="m-size"
                                                theme="white"
                                                customStyles={buttonStyles}
                                                className={cx('grow0')}
                                                onClick={handleClose}
                                            >
                                                Anuluj
                                            </Button>
                                            <Button
                                                type="submit"
                                                size="m-size"
                                                customStyles={buttonStyles}
                                                className={cx('grow1')}
                                                isLoading={isSubmitting}
                                            >
                                                Zapisz
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        />
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        )
    }
}

export default withStyles(styles)(ResetPasswordModal)
