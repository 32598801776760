import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'

import rootReducer from 'reducers'
import customMiddlewares from 'middlewares'

const initialState = {}

const configureStore = () => {
    const composeEnhancers =
        process.env.NODE_ENV !== 'production'
            ? typeof window === 'object' &&
              window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                      name: 'K-Starter',
                      actionsBlacklist: []
                  })
                : compose
            : compose

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(thunkMiddleware, ...customMiddlewares))
    )

    return store
}

export default configureStore
