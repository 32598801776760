import { combineReducers } from 'redux'

import currentReducer from './current'
import listReducer from './list'
import countReducer from './count'

export default combineReducers({
    current: currentReducer,
    list: listReducer,
    count: countReducer
})
