import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { withPermissions } from 'HOC'
import * as productActions from 'actions/products'
import * as flashMessagesActions from 'actions/flashMessages'
import ProductPage from './ProductPage'
import Skeleton from 'components/Skeleton'
import SectionLoader from 'components/SectionLoader'

class ProductPageContainer extends Component {
    state = {
        isGalleryOpen: false
    }

    get id() {
        return this.props.match.params.id
    }

    componentDidMount() {
        this.readProduct()
    }

    componentDidUpdate(prevProps) {
        const { actions } = this.props

        if (this.id !== prevProps.match.params.id) {
            actions.resetCurrentProduct()
        }

        if (actions.shouldReadProduct()) {
            this.readProduct()
        }
    }

    readProduct() {
        const { actions, history } = this.props

        actions.readProduct(this.id).catch(response => {
            actions.addFlashMessage({
                type: 'failure',
                title: 'Nie ma takiego zgłoszenia',
                content: 'Możesz wybrać zgłosznie z już istniejących.'
            })

            history.push('/zgloszenia')
        })
    }

    render() {
        const { product, ...props } = this.props

        return (
            <Skeleton
                fetchStatus={product.fetchStatus}
                component={SectionLoader}
                render={() => (
                    <ProductPage
                        {...props}
                        {...this.state}
                        product={product.data}
                        handleToggleGallery={this.handleToggleGallery}
                    />
                )}
            />
        )
    }

    handleToggleGallery = () => {
        const { isGalleryOpen } = this.state
        this.setState({
            isGalleryOpen: !isGalleryOpen
        })
    }
}

const mapStateToProps = state => {
    return {
        product: state.db.products.current
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                ...productActions,
                ...flashMessagesActions
            },
            dispatch
        )
    }
}

export default withPermissions()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ProductPageContainer)
)
