import React, { Component } from 'react'
import { connect } from 'react-redux'

import CategoryListPage from './CategoryListPage'

class CategoryListPageContainer extends Component {
    render() {
        return <CategoryListPage {...this.props} />
    }
}

const mapStateToProps = state => {
    return {
        user: state.local.user
    }
}

export default connect(mapStateToProps)(CategoryListPageContainer)
