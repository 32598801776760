import React, { Component } from 'react'
import Gallery from './Gallery'
const defaultTimeoutValue = 1000

class GalleryContainer extends Component {
    static defaultProps = {
        firstImageIndex: 0,
        handleClose: () => {}
    }

    state = {
        isListenerAdded: false,
        currentImageIndex: 0,
        isDisabledGalleryButton: false,
        displayedIndexes: [],
        isLoadingImage: true
    }

    render() {
        const { images, isOpen, title } = this.props
        const {
            currentImageIndex,
            isListenerAdded,
            isDisabledGalleryButton,
            isLoadingImage
        } = this.state
        return (
            <Gallery
                isOpen={isOpen}
                isListenerAdded={isListenerAdded}
                handleListen={this.handleListen}
                handleDisplayed={this.handleDisplayed}
                images={images}
                title={title}
                handleChangeSlide={this.handleChangeSlide}
                isDisabledGalleryButton={isDisabledGalleryButton}
                handleClose={this.handleClose}
                current={currentImageIndex}
                isLoadingImage={isLoadingImage}
            />
        )
    }

    handleListen = () => {
        this.setState({ isListenerAdded: true })
    }

    componentWillReceiveProps(nextProps) {
        this.setFirstImage(nextProps)
        if (this.props.isOpen === false && nextProps.isOpen) {
            setTimeout(() => {
                this.setState({
                    isLoadingImage: false
                })
            }, defaultTimeoutValue)
        }
    }

    componentDidMount() {
        this.setFirstImage(this.props)
    }

    setFirstImage = props => {
        if (this.state.displayedIndexes.length < 2) {
            return (
                this.setState({
                    currentImageIndex: props.firstImageIndex,
                    displayedIndexes: [props.firstImageIndex]
                })
            )
        }
        this.setState({
            currentImageIndex: props.firstImageIndex
        })
    }

    handleClose = () => {
        const { handleClose } = this.props
        this.setState({ isListenerAdded: false })
        handleClose()
    }

    handleChangeSlide = direction => {
        this.handleCheckIfWasDisplayed(direction)
    }

    handleCheckIfWasDisplayed = direction => {
        const { images } = this.props
        const { currentImageIndex, displayedIndexes } = this.state
        const firstImage = currentImageIndex === 0
        const lastImage = currentImageIndex + 1 === images.length
        let nextImageIndex = ''
        let setTimeoutValue = 0
        
        const addToDisplayed = nextImageIndex => {
            this.setState({
                displayedIndexes: [...this.state.displayedIndexes, nextImageIndex],
                isDisabledGalleryButton: true,
                isLoadingImage: true
            })
        }

        if (firstImage) {
            if (direction === 'previous') {
                nextImageIndex = images.length - 1
            }
            if (direction === 'next') {
                nextImageIndex = 1
            }
        }

        if (!firstImage && !lastImage) {
            if (direction === 'previous') {
                nextImageIndex = currentImageIndex - 1
            }
            if (direction === 'next') {
                nextImageIndex = currentImageIndex + 1
            }
        }

        if (lastImage) {
            if (direction === 'next') {
                nextImageIndex = 0
            }
            if (direction === 'previous') {
                nextImageIndex = images.length - 2
            }
        }

        if (!displayedIndexes.includes(nextImageIndex)) {
            addToDisplayed(nextImageIndex)
            setTimeoutValue = defaultTimeoutValue
        }

        return (
            this.handleChangeWithTimeout(direction, setTimeoutValue)
        )
    }

    handleChangeWithTimeout = (direction, setTimeoutValue) => {
        const { images } = this.props
        const { currentImageIndex } = this.state
        return (
            setTimeout(() => {
                if (direction === 'next') {
                    return this.setState(prevState => {
                        return {
                            currentImageIndex:
                                currentImageIndex + 1 >= images.length
                                    ? 0
                                    : currentImageIndex + 1,
                            isDisabledGalleryButton: false,
                            isLoadingImage: false
                        }
                    })
                }
    
                return this.setState(prevState => {
                    return {
                        currentImageIndex:
                            currentImageIndex - 1 < 0
                                ? images.length - 1
                                : currentImageIndex - 1,
                        isDisabledGalleryButton: false,
                        isLoadingImage: false
                    }
                })
            }, setTimeoutValue)
        )
    }
}

export default GalleryContainer
