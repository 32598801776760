import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import moment from 'moment'

import { MONTHS } from 'constants/months'
import * as categoryActions from 'actions/categories'
import * as dashboardActions from 'actions/dashboard'
import { withPermissions } from 'HOC'
import DashboardPage from './DashboardPage'
import Skeleton from 'components/Skeleton'
import SectionLoader from 'components/SectionLoader'

class DashboardPageContainer extends Component {
    state = {
        filters: {}
    }

    componentDidMount() {
        this.fetchCategories()
        this.fetchDashboard()

        window.scrollTo(0, 0)
    }

    fetchDashboard() {
        const { actions } = this.props
        const { filters } = this.state

        const params = {
            ...filters
        }

        return actions.fetchDashboard(params)
    }

    fetchCategories() {
        const { actions } = this.props

        actions.fetchCategories()
    }

    render() {
        const { dashboard, categories } = this.props
        const { filters } = this.state

        return (
            <Skeleton
                fetchStatus={categories.fetchStatus}
                component={SectionLoader}
                render={this.renderContent}
            >
                <DashboardPage
                    {...this.props}
                    filters={filters}
                    periods={this.generatePeriods()}
                    dashboard={dashboard.data}
                    categories={categories.data}
                    fetchStatusDashboard={dashboard.fetchStatus}
                    handleChangeFilter={this.handleChangeFilter}
                />
            </Skeleton>
        )
    }

    generatePeriods() {
        const currentYear = moment().year()
        const currentMonth = moment().month()

        let periods = []

        for (let i = 1; i <= currentMonth + 1; i++) {
            const monthDisplay = i < 10 ? '0' + i : i

            periods.push({
                id: `${currentYear}-${monthDisplay}`,
                name: MONTHS[i - 1] + ' ' + currentYear
            })
        }

        periods.reverse()

        for (let year = currentYear - 1; year >= 2019; year--) {
            for (let i = 12; i >= 1; i--) {
                const monthDisplay = i < 10 ? '0' + i : i

                periods.push({
                    id: `${year}-${monthDisplay}`,
                    name: MONTHS[i - 1] + ' ' + year
                })
            }
        }

        return periods
    }

    handleChangeFilter = (name, value) => {
        const { filters } = this.state

        const nextFilters = {
            ...filters,
            [name]: value
        }

        this.setState({ filters: nextFilters }, () => {
            this.fetchDashboard()
        })
    }
}

const mapStateToProps = state => {
    return {
        dashboard: state.db.dashboard,
        categories: state.db.categories
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                ...dashboardActions,
                ...categoryActions
            },
            dispatch
        )
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withPermissions()
)

export default enhance(DashboardPageContainer)
