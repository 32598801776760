import { CALL_API } from 'middlewares/api'
import { urlHelpers, fetchStatusHelpers } from '@mindz/react-helpers'

import * as actionTypes from 'constants/actionTypes'

const { shouldFetch } = fetchStatusHelpers

export const fetchProductCount = (params = {}) => dispatch => {
    params = {
        sort_by: 'created_at',
        sort_direction: 'desc',
        page: 1,
        ...params
    }

    const queryString = urlHelpers.objToQueryString(params)

    return dispatch({
        [CALL_API]: {
            endpoint: '/products' + (queryString ? '?' + queryString : ''),
            types: [
                actionTypes.FETCH_PRODUCT_COUNT_REQUEST,
                actionTypes.FETCH_PRODUCT_COUNT_SUCCESS,
                actionTypes.FETCH_PRODUCT_COUNT_FAILURE
            ]
        }
    })
}

export const fetchProducts = (params = {}) => dispatch => {
    params = {
        sort_by: 'created_at',
        sort_direction: 'desc',
        page: 1,
        ...params
    }

    params = Object.keys(params).reduce((acc, key) => {
        if (params[key]) {
            acc[key] = params[key]
        }

        return acc
    }, {})

    const queryString = urlHelpers.objToQueryString(params)

    return dispatch({
        [CALL_API]: {
            endpoint: '/products' + (queryString ? '?' + queryString : ''),
            types: [
                actionTypes.FETCH_PRODUCT_LIST_REQUEST,
                actionTypes.FETCH_PRODUCT_LIST_SUCCESS,
                actionTypes.FETCH_PRODUCT_LIST_FAILURE
            ]
        }
    })
}

export const fetchNextProducts = params => dispatch => {
    params = {
        sort_by: 'created_at',
        sort_direction: 'desc',
        page: 1,
        ...params
    }

    params = Object.keys(params).reduce((acc, key) => {
        if (params[key]) {
            acc[key] = params[key]
        }

        return acc
    }, {})

    const queryString = urlHelpers.objToQueryString(params)

    return dispatch({
        [CALL_API]: {
            endpoint: '/products' + (queryString ? '?' + queryString : ''),
            types: [
                actionTypes.FETCH_PRODUCT_LIST_NEXT_REQUEST,
                actionTypes.FETCH_PRODUCT_LIST_NEXT_SUCCESS,
                actionTypes.FETCH_PRODUCT_LIST_NEXT_FAILURE
            ]
        }
    })
}

export const readProduct = productId => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/products/' + productId,
            options: {
                method: 'GET'
            },
            types: [
                actionTypes.READ_PRODUCT_REQUEST,
                actionTypes.READ_PRODUCT_SUCCESS,
                actionTypes.READ_PRODUCT_FAILURE
            ]
        }
    })
}

export const createProduct = (values = {}) => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/products',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    ...values
                })
            },
            types: [
                actionTypes.CREATE_PRODUCT_REQUEST,
                actionTypes.CREATE_PRODUCT_SUCCESS,
                actionTypes.CREATE_PRODUCT_FAILURE
            ]
        }
    })
}

export const updateProduct = (
    { productId, values } = { productId: null, values: {} }
) => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/products/' + productId,
            options: {
                method: 'PATCH',
                body: JSON.stringify({
                    ...values
                })
            },
            types: [
                actionTypes.UPDATE_PRODUCT_REQUEST,
                actionTypes.UPDATE_PRODUCT_SUCCESS,
                actionTypes.UPDATE_PRODUCT_FAILURE
            ]
        }
    })
}

export const deleteProductImage = ({ id, imageId }) => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/products/' + id + '/images/' + imageId,
            options: {
                method: 'DELETE'
            },
            types: [
                actionTypes.DELETE_PRODUCT_IMAGE_REQUEST,
                actionTypes.DELETE_PRODUCT_IMAGE_SUCCESS,
                actionTypes.DELETE_PRODUCT_IMAGE_FAILURE
            ]
        }
    })
}

export const setMainProductImage = ({ id, imageId }) => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/products/' + id + '/images/' + imageId + '/main',
            options: {
                method: 'PATCH'
            },
            types: [
                actionTypes.SET_MAIN_PRODUCT_IMAGE_REQUEST,
                actionTypes.SET_MAIN_PRODUCT_IMAGE_SUCCESS,
                actionTypes.SET_MAIN_PRODUCT_IMAGE_FAILURE
            ]
        }
    })
}

export const shouldReadProduct = () => (dispatch, getState) => {
    const product = getState().db.products.current

    return shouldFetch(product)
}

export const updateProductStatus = (id, status) => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/products/' + id + '/statuses',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    name: status
                })
            },
            types: [
                actionTypes.UPDATE_PRODUCT_STATUS_REQUEST,
                actionTypes.UPDATE_PRODUCT_STATUS_SUCCESS,
                actionTypes.UPDATE_PRODUCT_STATUS_FAILURE
            ]
        }
    })
}

export const updateProductListFilters = filters => dispatch => {
    return dispatch({
        type: actionTypes.UPDATE_PRODUCT_LIST_FILTERS,
        payload: filters
    })
}

export const resetCurrentProduct = () => dispatch => {
    return dispatch({
        type: actionTypes.RESET_CURRENT_PRODUCT,
        payload: null
    })
}
