import * as actionTypes from 'constants/actionTypes/flashMessages'

export const addFlashMessage = message => dispatch => {
    return dispatch({
        type: actionTypes.ADD_FLASH_MESSAGES,
        payload: message
    })
}

export const removeFlashMessageAtIndex = index => dispatch => {
    return dispatch({
        type: actionTypes.REMOVE_FLASH_MESSAGE,
        payload: index
    })
}

export const resetFlashMessages = message => dispatch => {
    return dispatch({
        type: actionTypes.RESET_FLASH_MESSAGES,
        payload: message
    })
}
