import React, { Component } from 'react'

import { withStyles } from '@mindz/react-hoc'
import styles from './index.module.css'

class Sidebar extends Component {

    render() {
        const { cx, children } = this.props

        return (
            <aside className={cx("sidebar")}>
                <div className={cx("inner")}>
                    {children}
                </div>
            </aside>
        )
    }

}

export default withStyles(styles)(Sidebar)