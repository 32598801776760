export const READ_USER_LIST_REQUEST = 'READ_USER_LIST_REQUEST'
export const READ_USER_LIST_SUCCESS = 'READ_USER_LIST_SUCCESS'
export const READ_USER_LIST_FAILURE = 'READ_USER_LIST_FAILURE'

export const UPDATE_USER_LIST_FILTERS = 'UPDATE_USER_LIST_FILTERS'

export const READ_USER_REQUEST = 'READ_USER_REQUEST'
export const READ_USER_SUCCESS = 'READ_USER_SUCCESS'
export const READ_USER_FAILURE = 'READ_USER_FAILURE'

export const RESET_CURRENT_USER = 'RESET_CURRENT_USER'

export const READ_USER_COUNT_REQUEST = 'READ_USER_COUNT_REQUEST'
export const READ_USER_COUNT_SUCCESS = 'READ_USER_COUNT_SUCCESS'
export const READ_USER_COUNT_FAILURE = 'READ_USER_COUNT_FAILURE'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'
