export const isIosDevice =
    typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.platform &&
    /iP(ad|hone|od)/.test(window.navigator.platform)

export const preventDefault = rawEvent => {
    const e = rawEvent || window.event

    if (e.touches.length > 1) {
        return true
    }

    if (e.preventDefault) {
        e.preventDefault()
    }

    return false
}

export const isTargetElementTotallyScrolled = targetElement =>
    targetElement
        ? targetElement.scrollHeight - targetElement.scrollTop <=
          targetElement.clientHeight
        : false
