import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AccountPageEdit from './AccountPageEdit'
import * as actions from 'actions/account'
import { withPermissions } from 'HOC'

import validationSchema from './validationSchema'
import idx from 'idx'

class AccountPageEditContainer extends Component {
    constructor(props) {
        super(props)

        const {
            isManufacturer,
            account: {
                data: {
                    first_name = '',
                    last_name = '',
                    email = '',
                    phone = '',
                    company = { name: '', established: '', description: '' }
                }
            }
        } = props

        this.validationSchema = validationSchema(isManufacturer)
        this.initialValues = { first_name, last_name, email }

        const { name, established, description } = company

        if (isManufacturer) {
            this.initialValues = {
                ...this.initialValues,
                phone,
                company: { name, established, description: description || ''}
            }
        }
    }

    render() {
        return (
            <AccountPageEdit
                {...this.props}
                handleSubmit={this.handleSubmit}
                handleCancel={this.handleCancel}
                initialValues={this.initialValues}
                validationSchema={this.validationSchema}
            />
        )
    }

    handleSubmit = (values, formikActions) => {
        const { actions, isManufacturer, history } = this.props
        const { first_name, last_name, email, phone, company } = values
        const fields = { first_name, last_name, email }

        if (isManufacturer) {
            const { name, established, description } = company
            fields.phone = phone
            fields.company = { name, established, description }
        }

        actions
            .patchAccount(fields)
            .then(() => {
                return actions.fetchAccount().then(() => {
                    history.push('/moje-konto')
                })
            })
            .catch(responseWithError => {
                formikActions.setSubmitting(false)

                if (responseWithError.status === 422) {
                    return responseWithError.json().then(json => {
                        const errors = idx(json, _ => _.errors)

                        if (errors) {
                            formikActions.setErrors(errors)
                        }
                    })
                }

                formikActions.setErrors({ error: responseWithError.statusText })
            })
    }

    handleCancel = () => {
        this.props.history.goBack()
    }
}

const mapStateToProps = state => {
    return {
        account: state.local.account
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPermissions()(AccountPageEditContainer))
