import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as userActions from 'actions/users'
import UserCategories from './UserCategories'

class UserCategoriesContainer extends Component {
    state = {
        isChooseCategoriesModalOpen: false
    }

    render() {
        const { isChooseCategoriesModalOpen } = this.state

        return (
            <UserCategories
                {...this.props}
                handleSubmit={this.handleSubmit}
                isChooseCategoriesModalOpen={isChooseCategoriesModalOpen}
                handleRemoveCategory={this.handleRemoveCategory}
                toggleChooseCategoriesModal={this.toggleChooseCategoriesModal}
            />
        )
    }

    handleSubmit = values => {
        const { name, setFieldValue, categories } = this.props

        const categoriesIds = categories.map(item => item.id)

        const nextCategories = [
            ...categories,
            ...values.filter(item => !categoriesIds.includes(item.id))
        ]

        setFieldValue(name, nextCategories)
    }

    handleRemoveCategory = (...ids) => {
        const { name, setFieldValue, categories } = this.props

        if (!categories.length) {
            return
        }

        const values = categories.filter(item => !ids.includes(item.id))

        setFieldValue(name, values)
    }

    toggleChooseCategoriesModal = () => {
        this.setState(prevState => ({
            isChooseCategoriesModalOpen: !prevState.isChooseCategoriesModalOpen
        }))
    }
}

const mapStateToProps = state => {
    return {
        user: state.db.users.current
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                ...userActions
            },
            dispatch
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserCategoriesContainer)
