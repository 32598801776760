import React, { Component } from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from 'actions/auth'
import RemindPasswordModal from './RemindPasswordModal'
import RemindPasswordConfirmationModal from './RemindPasswordConfirmationModal'

class RemindPasswordModalContainer extends Component {
    state = {
        confirm: false
    }

    render() {

        if (this.state.confirm) {
            return (
                <RemindPasswordConfirmationModal
                    {...this.props}
                    handleSubmit={this.handleSubmit}
                />
            )
        }

        return (
            <RemindPasswordModal
                {...this.props}
                confirm={this.state.confirm}
                handleSubmit={this.handleSubmit}
            />
        )
    }

    handleSubmit = (values, { setSubmitting, setErrors }) => {
        const { actions } = this.props

        actions.remindPassword(values).then(
            () => {
                this.setState({ confirm: true })
            },
            responseWithError => {
                if (responseWithError.status === 422) {
                    responseWithError.json().then(json => {
                        setSubmitting(false)
                        setErrors(json.errors)
                    })
                }
            }
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

const enhance = compose(
    connect(
        null,
        mapDispatchToProps
    )
)

export default enhance(RemindPasswordModalContainer)
