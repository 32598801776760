import React from 'react'
import { InputField as InputFieldBase } from '@mindz/react-admin-ui'

import { withStyles }from '@mindz/react-hoc'

import styles from './index.module.css'

export default withStyles(styles)(({ children, ...props }) => (
    <InputFieldBase.Kaufland {...props}>{children}</InputFieldBase.Kaufland>
))
