import { combineReducers } from 'redux'

import * as actionTypes from 'constants/actionTypes'

const user = JSON.parse(localStorage.getItem('user'))
const loggedInState = user && user.token ? true : false

export const loggedInReducer = (state = loggedInState, action) => {
    if (actionTypes.USER_LOGIN_SUCCESS === action.type) {
        return true
    }

    if (actionTypes.USER_LOGOUT === action.type) {
        return false
    }

    return state
}

export const currentUserReducer = (state = user || {}, action) => {
    if (actionTypes.USER_LOGIN_SUCCESS === action.type) {
        return {
            email: action.payload.email,
            token: action.payload.jwt_token
        }
    }

    if (actionTypes.USER_LOGOUT === action.type) {
        return null
    }

    return state
}

export default combineReducers({
    loggedIn: loggedInReducer,
    currentUser: currentUserReducer,
})