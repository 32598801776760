import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'actions/notifications'

import Header from './Header'
import { shortcuts } from './config'

class HeaderContainer extends Component {
    node = null
    refRoot = node => (this.node = node)

    constructor(props) {
        super(props)

        this.state = {
            isShownDropdown: false,
            visibleNotifications: false,
            active: ''
        }

        this.shortcuts = shortcuts(props.isManufacturer, props.isSalesman)
    }

    componentDidMount() {
        this.setState({
            active: `/${window.location.pathname.split('/')[1]}`
        })

        window.addEventListener('click', this.handleOutsideClick, false)
    }

    static getDerivedStateFromProps() {
        return { active: `/${window.location.pathname.split('/')[1]}` }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleOutsideClick, false)

        document.body.classList.remove('bodyOverflowHiddenForMobile')
    }

    render() {
        return (
            <Header
                {...this.props}
                {...this.state}
                shortcuts={this.shortcuts}
                toggleNotifications={this.toggleNotifications}
                toggleDropdown={this.toggleDropdown}
                setActiveShortcut={this.setActiveShortcut}
                refRoot={this.refRoot}
            />
        )
    }

    toggleNotifications = () => {
        this.setState(
            prevState => ({
                isShownDropdown: false,
                visibleNotifications: !prevState.visibleNotifications
            }),
            () => {
                const { visibleNotifications } = this.state
                const bodyClassList = document.body.classList

                if (visibleNotifications) {
                    return bodyClassList.add('bodyOverflowHiddenForMobile')
                }

                bodyClassList.remove('bodyOverflowHiddenForMobile')
            }
        )
    }

    toggleDropdown = () => {
        this.setState(prevState => ({
            isShownDropdown: !prevState.isShownDropdown,
            visibleNotifications: false
        }))
    }

    setActiveShortcut = active => {
        this.setState({ active })
    }

    handleOutsideClick = event => {
        if (this.node && !this.node.contains(event.target)) {
            this.setState({
                isShownDropdown: false,
                visibleNotifications: false
            })
        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.local.account
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderContainer)
