import { CALL_API } from 'middlewares/api'
import { urlHelpers } from '@mindz/react-helpers'

import * as actionTypes from 'constants/actionTypes'

export const fetchDashboard = (params = {}) => dispatch => {
    params = Object.keys(params).reduce((acc, key) => {
        if (params[key]) {
            acc[key] = params[key]
        }

        return acc
    }, {})

    const queryString = urlHelpers.objToQueryString(params)

    return dispatch({
        [CALL_API]: {
            endpoint: '/dashboard' + (queryString ? '?' + queryString : ''),
            types: [
                actionTypes.FETCH_DASHBOARD_REQUEST,
                actionTypes.FETCH_DASHBOARD_SUCCESS,
                actionTypes.FETCH_DASHBOARD_FAILURE
            ]
        }
    })
}

export const updateDashboardFilters = filters => dispatch => {
    return dispatch({
        type: actionTypes.UPDATE_DASHBOARD_FILTERS,
        payload: filters
    })
}
