import React, { Component } from 'react'

import { withPermissions } from 'HOC'

const withAdminDispatcher = () => {
    return ({ AdminComponent, NormalComponent }) => {
        class Enhance extends Component {
            render() {
                const { isBusiness, isSalesman, isAdmin } = this.props

                let WrappedComponent = NormalComponent

                if (isSalesman || isBusiness || isAdmin) {
                    WrappedComponent = AdminComponent
                }

                return <WrappedComponent {...this.props} />
            }
        }

        return withPermissions()(Enhance)
    }
}

export default withAdminDispatcher
