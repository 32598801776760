import React from 'react'

import { withStyles } from '@mindz/react-hoc'

import styles from './Section.module.css'

const Section = ({ cx, title, description, children }) => {
    return (
        <section className={cx('root')}>
            {title && <h3>{title}</h3>}
            {description && <h4>{description}</h4>}
            {children}
        </section>
    )
}

export default withStyles(styles)(Section)
