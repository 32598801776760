import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'
import { Link } from 'react-router-dom'

import { Button } from 'components/forms'
import { STATUSES, STATUSES_META } from 'constants/product'

import styles from './index.module.css'
import buttonStyles from './overrides/Button.module.css'

import IconInside from 'assets/icons/arrow-inside.module.svg'
import IconArrow from 'assets/arrow_down.module.svg'

import { LANDING_PAGE_HOST } from 'env'

class ProductStatus extends Component {
    render() {
        const {
            cx,
            isManufacturer,
            isCollapsed,
            status,
            handleToggle
        } = this.props

        const statusMeta = STATUSES_META[this.props.status]

        return (
            <div className={cx('summary')}>
                <section
                    className={cx('root', {
                        isManufacturer,
                        isSalesman: !isManufacturer,
                        isDraft: STATUSES.DRAFT === status,
                        isNew: STATUSES.NEW === status,
                        isAccepted: STATUSES.ACCEPTED === status,
                        isRejected: STATUSES.REJECTED === status,
                        isCollapsed
                    })}
                >
                    <header
                        className={cx('header')}
                        style={{ backgroundColor: statusMeta.backgroundColor }}
                    >
                        Status zgłoszenia:
                        <h2 style={{ color: statusMeta.color }}>
                            {statusMeta.label}
                        </h2>
                    </header>
                    <section className={cx('content')}>
                        <div className={cx('mobile')}>
                            <strong>Status zgłoszenia:</strong>
                            <p
                                style={{
                                    color: statusMeta.color,
                                    backgroundColor: statusMeta.backgroundColor
                                }}
                            >
                                {statusMeta.label}
                            </p>
                            <span
                                onClick={handleToggle}
                                className={cx('toggle')}
                            >
                                <span>{isCollapsed ? 'Rozwiń' : 'Zwiń'}</span>
                                <IconArrow />
                            </span>
                        </div>
                        <div className={cx('holder')}>
                            {this.renderContent()}
                        </div>
                    </section>
                </section>
            </div>
        )
    }

    renderContent() {
        const {
            cx,
            product,
            status,
            isManufacturer,
            isSalesman,
            toggleChangeStatusModal
        } = this.props

        if (isManufacturer) {
            if (STATUSES.DRAFT === status) {
                return (
                    <div>
                        <h3>Uwaga!</h3>
                        <p>
                            Sprawdź poprawność danych, zanim wyślesz zgłoszenie
                            – jego edycja po wysłaniu nie będzie możliwa.
                        </p>
                        <Link to={'/zgloszenia/' + product.id + '/edytuj'}>
                            <IconInside />
                            Edytuj zgłoszenie
                        </Link>
                        <hr />
                        <Button
                            onClick={() => toggleChangeStatusModal('new')}
                            customStyles={buttonStyles}
                            className="upper"
                            size="m-size"
                        >
                            Wyślij
                        </Button>
                    </div>
                )
            }

            if (STATUSES.NEW === status) {
                return (
                    <div>
                        <div className={cx('icon')}>
                            <img
                                src="/assets/icons/papers-wait.png"
                                alt="Dziękujemy"
                            />
                        </div>
                        <h3>Dziękujemy</h3>
                        <p>
                            Zgłoszenie zostało wysłane i oczekuje na weryfikację
                            w dziale zakupów.
                        </p>
                        <div className={cx('actions')}>
                            <Link to="/">
                                <Button
                                    customStyles={buttonStyles}
                                    theme="white"
                                    className="wide"
                                    size="s-size"
                                >
                                    Dowiedz się więcej
                                </Button>
                            </Link>
                        </div>
                    </div>
                )
            }

            if (STATUSES.ACCEPTED === status) {
                return (
                    <div>
                        <div className={cx('icon')}>
                            <img
                                src="/assets/icons/papers-accepted.png"
                                alt="Zgłoszony produkt przeszedł wstępną weryfikację"
                            />
                        </div>
                        <h3>Zgłoszony produkt przeszedł wstępną weryfikację</h3>
                        <p>
                            Wkrótce skontaktujemy się mailowo lub telefonicznie,
                            aby uzgodnić dalsze kroki.
                        </p>
                    </div>
                )
            }

            if (STATUSES.REJECTED === status) {
                return (
                    <div>
                        <div className={cx('icon')}>
                            <img
                                src="/assets/icons/papers-remove.png"
                                alt="Produkt nie spełnia wymogów"
                            />
                        </div>
                        <h3>Produkt nie spełnia wymogów</h3>
                        <p>
                            Zgłoszony produkt nie spełnił wymagań określonych w{' '}
                            <a
                                href={
                                    LANDING_PAGE_HOST +
                                    '/Startup_by_Kaufland_Regulamin.pdf'
                                }
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Regulaminie
                            </a>
                            {' '}serwisu Startup by Kaufland.
                        </p>
                    </div>
                )
            }

            return null
        }

        if (STATUSES.NEW === status) {
            return (
                <div>
                    <h3>Jaka decyzja?</h3>
                    <p>
                        Jeśli zgłaszany produkt spełnia wymogi, to przyjmij
                        zgłoszenie do weryfikacji. W przeciwnym razie – odrzuć
                        je.
                    </p>
                    <div className={cx('actions')}>
                        <Button
                            onClick={() => toggleChangeStatusModal('rejected')}
                            customStyles={buttonStyles}
                            className={[cx('button'), 'wide', 'upper'].join(
                                ' '
                            )}
                            disabled={!isSalesman}
                            theme="white"
                            size="l-size"
                        >
                            Odrzuć
                        </Button>

                        <Button
                            onClick={() => toggleChangeStatusModal('accepted')}
                            customStyles={buttonStyles}
                            className={[cx('button'), 'wide', 'upper'].join(
                                ' '
                            )}
                            disabled={!isSalesman}
                            size="l-size"
                        >
                            Przyjmij
                        </Button>
                    </div>
                </div>
            )
        }

        if (STATUSES.ACCEPTED === status) {
            return (
                <div>
                    <div className={cx('icon')}>
                        <img
                            src="/assets/icons/papers-accepted.png"
                            alt="Zgłoszenie zweryfikowane"
                        />
                    </div>
                    <h3>Zgłoszenie zweryfikowane</h3>
                    <p>
                        Skontaktuj się z osobą zgłaszającą produkt w celu
                        ustalenia dalszych kroków.
                    </p>
                    <div className={cx('actions')}>
                        <Link to={'/firmy/' + product.manufacturer.company.id}>
                            <Button
                                customStyles={buttonStyles}
                                theme="white"
                                className="wide"
                                size="s-size"
                            >
                                Kontakt do producenta
                            </Button>
                        </Link>
                    </div>
                </div>
            )
        }

        if (STATUSES.REJECTED === status) {
            return (
                <div>
                    <div className={cx('icon')}>
                        <img
                            src="/assets/icons/papers-remove.png"
                            alt="Produkt nie spełnia wymogów"
                        />
                    </div>
                    <h3>Produkt nie spełnia wymogów</h3>
                    <p>
                        Zgłoszony produkt nie spełnił wymagań określonych w{' '}
                        <a
                            href={LANDING_PAGE_HOST + "/Startup_by_Kaufland_Regulamin.pdf"}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Regulaminie
                        </a>{' '}
                        serwisu Startup by Kaufland.
                    </p>
                </div>
            )
        }
    }
}

export default withStyles(styles)(ProductStatus)
