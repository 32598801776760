import { CALL_API } from 'middlewares/api'
import { urlHelpers } from '@mindz/react-helpers'

import * as actionTypes from 'constants/actionTypes'

export const readUserList = (params = {}) => dispatch => {
    params = {
        sort_by: 'last_name',
        sort_direction: 'asc',
        page: 1,
        ...params
    }

    const queryString = urlHelpers.objToQueryString(params)

    return dispatch({
        [CALL_API]: {
            endpoint: '/users' + (queryString ? '?' + queryString : ''),
            types: [
                actionTypes.READ_USER_LIST_REQUEST,
                actionTypes.READ_USER_LIST_SUCCESS,
                actionTypes.READ_USER_LIST_FAILURE
            ]
        }
    })
}

export const readUserCount = (params = {}) => dispatch => {
    params = {
        sort_by: 'id',
        sort_direction: 'desc',
        page: 1,
        length: 1,
        ...params
    }

    const queryString = urlHelpers.objToQueryString(params)

    return dispatch({
        [CALL_API]: {
            endpoint: '/users' + (queryString ? '?' + queryString : ''),
            types: [
                actionTypes.READ_USER_COUNT_REQUEST,
                actionTypes.READ_USER_COUNT_SUCCESS,
                actionTypes.READ_USER_COUNT_FAILURE
            ]
        }
    })
}

export const updateUserListFilters = filters => dispatch => {
    return dispatch({
        type: actionTypes.UPDATE_USER_LIST_FILTERS,
        payload: filters
    })
}

export const readUser = id => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/users/' + id,
            types: [
                actionTypes.READ_USER_REQUEST,
                actionTypes.READ_USER_SUCCESS,
                actionTypes.READ_USER_FAILURE
            ]
        }
    })
}

export const resetCurrentUser = () => dispatch => {
    return dispatch({
        type: actionTypes.RESET_CURRENT_USER,
        payload: null
    })
}

export const createUser = (values = {}) => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/users',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    ...values
                })
            },
            types: [
                actionTypes.CREATE_USER_REQUEST,
                actionTypes.CREATE_USER_SUCCESS,
                actionTypes.CREATE_USER_FAILURE
            ]
        }
    })
}

export const updateUser = (id, values) => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/users/' + id,
            options: {
                method: 'PATCH',
                body: JSON.stringify({
                    ...values
                })
            },
            types: [
                actionTypes.UPDATE_USER_REQUEST,
                actionTypes.UPDATE_USER_SUCCESS,
                actionTypes.UPDATE_USER_FAILURE
            ]
        }
    })
}

export const deleteUser = id => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/users/' + id,
            options: {
                method: 'DELETE'
            },
            types: [
                actionTypes.DELETE_USER_REQUEST,
                actionTypes.DELETE_USER_SUCCESS,
                actionTypes.DELETE_USER_FAILURE
            ]
        }
    })
}
