import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as actions from 'actions/users'
import { withPermissions } from 'HOC'
import UserListPage from './UserListPage'

class UserListPageContainer extends Component {
    state = {
        isOpenModal: false,
        deletingUserId: null,
        isSubmittingDelete: false
    }

    componentDidMount() {
        this.fetchUsers()

        window.scrollTo(0, 0)
    }

    fetchUsers() {
        const { actions, users } = this.props

        const params = {
            role: 'salesman',
            ...users.filters
        }

        actions.readUserList(params)
    }

    fetchMoreUsers = () => {}

    render() {
        const { deletingUserId, isOpenModal, isSubmittingDelete } = this.state
        return (
            <UserListPage
                {...this.props}
                handleLoadMore={() => this.fetchMoreUsers()}
                handleChangeQuery={this.handleChangeQuery}
                handleSubmitQuery={() => this.fetchUsers()}
                handleDelete={this.handleDelete}
                isOpenModal={isOpenModal}
                toggleOpenModal={this.toggleOpenModal}
                deletingUserId={deletingUserId}
                isSubmittingDelete={isSubmittingDelete}
            />
        )
    }

    handleChangeQuery = value => {
        const { actions } = this.props

        actions.updateUserListFilters({ query: value })
    }

    toggleOpenModal = (deletingUserId = null) => {
        this.setState(prevState => ({
            isOpenModal: !prevState.isOpenModal,
            deletingUserId,
            isSubmittingDelete: false
        }))
    }

    handleDelete = () => {
        const { actions } = this.props
        const { deletingUserId: userId } = this.state

        this.setState({ isSubmittingDelete: true })

        actions
            .deleteUser(userId)
            .then(() => {
                this.fetchUsers()
            })
            .finally(() => {
                this.toggleOpenModal()
            })
    }
}

const mapStateToProps = state => {
    return {
        users: state.db.users.list
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withPermissions()
)

export default enhance(UserListPageContainer)
