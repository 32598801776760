import { CALL_API } from 'middlewares/api'

import * as actionTypes from 'constants/actionTypes'

export const register = ({ values, validate } = { values: {}, validate: false }) => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/register?validate=' + validate,
            options: {
                method: 'POST',
                body: JSON.stringify({
                    ...values
                })
            },
            types: [
                actionTypes.USER_REGISTER_REQUEST,
                actionTypes.USER_REGISTER_SUCCESS,
                actionTypes.USER_REGISTER_FAILURE
            ]
        }
    })
}

export const login = values => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/login',
            options: {
                method: 'POST',
                body: JSON.stringify(values)
            },
            payload: {
                email: values.email
            },
            types: [
                actionTypes.USER_LOGIN_REQUEST,
                actionTypes.USER_LOGIN_SUCCESS,
                actionTypes.USER_LOGIN_FAILURE
            ]
        }
    })
}

export const remindPassword = values => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/remind-password',
            options: {
                method: 'POST',
                body: JSON.stringify(values)
            },
            types: [
                actionTypes.USER_REMIND_PASSWORD_REQUEST,
                actionTypes.USER_REMIND_PASSWORD_SUCCESS,
                actionTypes.USER_REMIND_PASSWORD_FAILURE
            ]
        }
    })
}

export const resetPassword = values => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/reset-password',
            options: {
                method: 'POST',
                body: JSON.stringify(values)
            },
            types: [
                actionTypes.USER_RESET_PASSWORD_REQUEST,
                actionTypes.USER_RESET_PASSWORD_SUCCESS,
                actionTypes.USER_RESET_PASSWORD_FAILURE
            ]
        }
    })
}

export const registerToken = token => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/register/' + token,
            options: {
                method: 'POST'
            },
            types: [
                actionTypes.USER_ACTIVATE_REQUEST,
                actionTypes.USER_ACTIVATE_SUCCESS,
                actionTypes.USER_ACTIVATE_FAILURE
            ]
        }
    })
}

export const logout = () => dispatch => {
    localStorage.removeItem('user')
    return dispatch({
        type: actionTypes.USER_LOGOUT
    })
}

export const fetchPrivileges = () => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/permissions',
            types: [
                actionTypes.FETCH_PERMISSIONS_REQUEST,
                actionTypes.FETCH_PERMISSIONS_SUCCESS,
                actionTypes.FETCH_PERMISSIONS_FAILURE
            ]
        }
    })
}
