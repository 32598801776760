import React, { Component } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as categoryActions from 'actions/categories'
import { Field, Dropdown } from 'components/forms'
import SelectCategoryModal from './SelectCategoryModal'

class SelectCategoryModalContainer extends Component {
    componentDidMount() {
        const { actions } = this.props

        actions.fetchCategories()
    }

    render() {
        const {
            isOpen,
            handleSubmit,
            handleClose,
            categories,
            ...props
        } = this.props

        return (
            <SelectCategoryModal
                {...props}
                isOpen={isOpen}
                categories={categories}
                handleClose={handleClose}
                handleSubmit={values => {
                    let nextCategories = []

                    nextCategories.push({
                        id: categories.data[values.category].id,
                        parent_id: null,
                        name: categories.data[values.category].name
                    })

                    if (values.subcategory || values.subcategory === 0) {
                        nextCategories.push({
                            id:
                                categories.data[values.category].children[
                                    values.subcategory
                                ].id,
                            parent_id: categories.data[values.category].id,
                            name:
                                categories.data[values.category].children[
                                    values.subcategory
                                ].name
                        })
                    }

                    handleSubmit(nextCategories)
                }}
                renderDropdown={this.renderDropdown}
            />
        )
    }

    renderDropdown({
        name,
        values,
        handleChange,
        items = [],
        dropdownProps = {}
    }) {
        return (
            <Field
                name={name}
                render={({ name, error }) => (
                    <Dropdown
                        handleChange={handleChange}
                        error={error}
                        name={name}
                        placeholder=" - Wybierz - "
                        numberVisibleElements={6}
                        {...dropdownProps}
                    >
                        {items &&
                            items.map((category, index) => (
                                <Dropdown.Item
                                    selected={index === values[name]}
                                    key={index}
                                    value={index}
                                >
                                    {category.name}
                                </Dropdown.Item>
                            ))}
                    </Dropdown>
                )}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        categories: state.db.categories
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                ...categoryActions
            },
            dispatch
        )
    }
}

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)

export default enhance(SelectCategoryModalContainer)
