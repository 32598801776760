import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from 'actions/auth'

import FlashMessage from 'components/FlashMessage'
import SignInPage from 'pages/SignInPage'
import ResetPasswordModal from './ResetPasswordModal'

class ResetPasswordPageContainer extends Component {
    state = {
        success: false,
        showResetPasswordModal: true
    }

    getToken() {
        return this.props.match.params.token
    }

    componentDidMount() {
        if (!this.getToken()) {
            this.props.history.replace('/logowanie')
        }
    }

    render() {
        if (!this.getToken()) {
            return null
        }

        return (
            <Fragment>
                <SignInPage
                    {...this.props}
                    message={() => {
                        return (
                            !!this.state.success && (
                                <FlashMessage
                                    type="success"
                                    title="Twoje hasło zostało zmienione"
                                />
                            )
                        )
                    }}
                />
                <ResetPasswordModal
                    isOpen={this.state.showResetPasswordModal}
                    handleClose={this.toggleResetPasswordModal}
                    handleSubmit={this.handleSubmit}
                />
            </Fragment>
        )
    }

    toggleResetPasswordModal = () => {
        this.setState(prevState => ({
            showResetPasswordModal: !prevState.showResetPasswordModal
        }))
    }

    handleSubmit = (values, formikActions) => {
        const { actions } = this.props

        formikActions.setSubmitting(true)

        actions
            .resetPassword({
                password: values.password,
                token: this.getToken()
            })
            .then(
                response => {
                    formikActions.setSubmitting(false)

                    this.setState({
                        success: true,
                        showResetPasswordModal: false
                    })
                },
                responseWithError => {
                    formikActions.setSubmitting(false)

                    return responseWithError.json().then(json => {
                        if (json.errors) {
                            return formikActions.setErrors(json.errors)
                        }

                        if (json.message) {
                            return formikActions.setErrors({
                                error: json.message
                            })
                        }

                        formikActions.setErrors({
                            error: responseWithError.statusText
                        })
                    })
                }
            )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default connect(
    null,
    mapDispatchToProps
)(ResetPasswordPageContainer)
