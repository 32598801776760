import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { fetchStatusHelpers } from '@mindz/react-helpers'

import { withPermissions } from 'HOC'
import * as userActions from 'actions/users'
import UserPage from './UserPage'
import Skeleton from 'components/Skeleton'
import SectionLoader from 'components/SectionLoader'

const { fetchStatusFromResources, successState } = fetchStatusHelpers

class UserPageContainer extends Component {
    componentDidMount() {
        const { actions, isAdmin, history } = this.props

        if (!isAdmin) {
            history.replace('/')
        }

        if (this.isEdit()) {
            actions.readUser(this.id)
        }
    }

    componentWillUnmount() {
        const { actions } = this.props

        actions.resetCurrentUser()
    }

    get action() {
        const { match } = this.props

        if (match.params.id) {
            return 'edit'
        }

        return 'create'
    }

    get id() {
        const { match } = this.props

        return match.params.id
    }

    isEdit() {
        return 'edit' === this.action
    }

    handleSubmit = (values, formikActions) => {
        const { actions, history } = this.props

        formikActions.setSubmitting(true)
        formikActions.setStatus({ success: false })

        const promise = this.isEdit()
            ? actions.updateUser(this.id, values)
            : actions.createUser(values)

        promise
            .then(() => {
                if (!this.isEdit()) {
                    return history.replace('/uzytkownicy')
                }

                formikActions.setSubmitting(false)
                formikActions.setStatus({ success: true })
            })
            .catch(response => {
                formikActions.setSubmitting(false)

                if (response.status === 422) {
                    return response.json().then(json => {
                        if (json.errors) {
                            return formikActions.setErrors(json.errors)
                        }

                        if (json.message) {
                            return formikActions.setErrors({
                                error: json.message
                            })
                        }
                    })
                }

                formikActions.setErrors({
                    error: response.statusText
                })
            })
    }

    handleCancel = () => {
        const { history } = this.props

        history.push('/uzytkownicy')
    }

    render() {
        const { user } = this.props

        let fetchStatus = successState()

        if (this.isEdit()) {
            fetchStatus = fetchStatusFromResources(user)
        }

        return (
            <Skeleton fetchStatus={fetchStatus} component={SectionLoader}>
                <UserPage
                    {...this.props}
                    id={this.id}
                    isEdit={this.isEdit()}
                    user={this.isEdit() ? user.data : {}}
                    handleCancel={this.handleCancel}
                    handleSubmit={this.handleSubmit}
                    toggleChooseCategoriesModal={
                        this.toggleChooseCategoriesModal
                    }
                />
            </Skeleton>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.db.users.current
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                ...userActions
            },
            dispatch
        )
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withPermissions()
)

export default enhance(UserPageContainer)
