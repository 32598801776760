import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@mindz/react-hoc'

import InfiniteScroll from 'components/InfiniteScroll'

import styles from './index.module.css'
import IconLoading from 'assets/icons/loading.module.svg'
import IconMail from 'assets/icons/mail.module.svg'
import IconEdit from 'assets/icons/edit.module.svg'
import IconTrash from 'assets/icons/trash.module.svg'

class UserList extends Component {
    render() {
        const { cx, items, meta, handleLoadMore } = this.props
        return (
            <div className={cx('root')}>
                {meta.total > 0 ? <InfiniteScroll
                    loadMore={handleLoadMore}
                    hasMore={meta.current_page < meta.pages}
                    loader={
                        <div className={cx('loader')} key={0}>
                            <IconLoading />
                            <span>Ładowanie...</span>
                        </div>
                    }
                >
                    <ul>{items.map(user => this.renderUser(user))}</ul>
                </InfiniteScroll> : <div className={cx('emptyList')}>Brak użytkowników</div>}
            </div>
        )
    }

    renderUser(user) {
        const { cx, toggleOpenModal } = this.props
        const id = user.id

        return (
            <li key={id} className={cx('user')}>
                <span className={cx('signature')}>{user.first_name[0]}{user.last_name[0]}</span>
                <div className={cx('sideWrapper')}>
                    <div className={cx('leftSide')}>
                        <span className={cx('name')}>{user.first_name} {user.last_name}</span>
                        <span className={cx('email_mobile')}>{user.email}</span>
                    </div>
                    <div className={cx('rightSide')}>
                        <span className={cx('email')}><IconMail /> {user.email}</span>
                        <span className={cx('edit')}><Link to={'/uzytkownicy/' + id}>
                            <IconEdit/> <span>Edytuj</span>
                        </Link></span>
                        <span className={cx('delete')} onClick={() => toggleOpenModal(id)}><IconTrash/> <span>Usuń</span></span>
                    </div>
                </div>
            </li>
        )
    }
}

export default withStyles(styles)(UserList)
