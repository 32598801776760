import React from 'react'

import { withStyles } from '@mindz/react-hoc'

import styles from './index.module.css'

const Header = ({ cx, category = 'glowna kategoria', subcategory = null }) => {   
    return (
        <div className={cx('root')}>
            <h4>{category}</h4>
            {
                subcategory &&
                <span>
                    / <h4>{subcategory}</h4>
                </span>
            }
        </div>
    )
}

export default withStyles(styles)(Header)
