import React, { Component } from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { withPermissions } from 'HOC'

class ManufacturerRestrictedRoute extends Component {
    render() {
        const {
            component: Component,
            isAdmin,
            isManufacturer,
            isSalesman,
            isBusiness,
            children,
            ...rest
        } = this.props

        return (
            <Route
                {...rest}
                render={props =>
                    isAdmin || isSalesman || isBusiness ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to="/" />
                    )
                }
            />
        )
    }
}

export default withRouter(withPermissions()(ManufacturerRestrictedRoute))
