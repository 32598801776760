export const READ_PRODUCT_REQUEST = 'READ_PRODUCT_REQUEST'
export const READ_PRODUCT_SUCCESS = 'READ_PRODUCT_SUCCESS'
export const READ_PRODUCT_FAILURE = 'READ_PRODUCT_FAILURE'

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE'

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE'

export const CREATE_PRODUCT_STATUS_REQUEST = 'CREATE_PRODUCT_STATUS_REQUEST'
export const CREATE_PRODUCT_STATUS_SUCCESS = 'CREATE_PRODUCT_STATUS_SUCCESS'
export const CREATE_PRODUCT_STATUS_FAILURE = 'CREATE_PRODUCT_STATUS_FAILURE'

export const FETCH_PRODUCT_COUNT_REQUEST = 'FETCH_PRODUCT_COUNT_REQUEST'
export const FETCH_PRODUCT_COUNT_SUCCESS = 'FETCH_PRODUCT_COUNT_SUCCESS'
export const FETCH_PRODUCT_COUNT_FAILURE = 'FETCH_PRODUCT_COUNT_FAILURE'

export const FETCH_PRODUCT_LIST_REQUEST = 'FETCH_PRODUCT_LIST_REQUEST'
export const FETCH_PRODUCT_LIST_SUCCESS = 'FETCH_PRODUCT_LIST_SUCCESS'
export const FETCH_PRODUCT_LIST_FAILURE = 'FETCH_PRODUCT_LIST_FAILURE'

export const FETCH_PRODUCT_LIST_NEXT_REQUEST = 'FETCH_PRODUCT_LIST_NEXT_REQUEST'
export const FETCH_PRODUCT_LIST_NEXT_SUCCESS = 'FETCH_PRODUCT_LIST_NEXT_SUCCESS'
export const FETCH_PRODUCT_LIST_NEXT_FAILURE = 'FETCH_PRODUCT_LIST_NEXT_FAILURE'

export const DELETE_PRODUCT_IMAGE_REQUEST = 'DELETE_PRODUCT_IMAGE_REQUEST'
export const DELETE_PRODUCT_IMAGE_SUCCESS = 'DELETE_PRODUCT_IMAGE_SUCCESS'
export const DELETE_PRODUCT_IMAGE_FAILURE = 'DELETE_PRODUCT_IMAGE_FAILURE'

export const SET_MAIN_PRODUCT_IMAGE_REQUEST = 'SET_MAIN_PRODUCT_IMAGE_REQUEST'
export const SET_MAIN_PRODUCT_IMAGE_SUCCESS = 'SET_MAIN_PRODUCT_IMAGE_SUCCESS'
export const SET_MAIN_PRODUCT_IMAGE_FAILURE = 'SET_MAIN_PRODUCT_IMAGE_FAILURE'

export const UPDATE_PRODUCT_STATUS_REQUEST = 'UPDATE_PRODUCT_STATUS_REQUEST'
export const UPDATE_PRODUCT_STATUS_SUCCESS = 'UPDATE_PRODUCT_STATUS_SUCCESS'
export const UPDATE_PRODUCT_STATUS_FAILURE = 'UPDATE_PRODUCT_STATUS_FAILURE'

export const UPDATE_PRODUCT_LIST_FILTERS = 'UPDATE_PRODUCT_LIST_FILTERS'

export const RESET_CURRENT_PRODUCT = 'RESET_CURRENT_PRODUCT'
