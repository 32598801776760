import React, { Component } from 'react'
import { Button } from 'components/forms'
import { Formik } from 'formik'
import SnakeLoader from 'components/SnakeLoader'

import { selectCategoryScheme } from 'scheme'

import { withStyles } from '@mindz/react-hoc'
import styles from './index.module.css'
import buttonStyles from './overrides/Button.module.css'
import dropdown1Styles from './overrides/Dropdown1.module.css'
import dropdown2Styles from './overrides/Dropdown2.module.css'
import IconAdd from 'assets/icons/add-product.module.svg'


class CreateFirstProduct extends Component {
    render() {
        const { cx, categories } = this.props

        return (
            <div className={cx('root')}>
                <div className={cx('header')} >
                    <IconAdd />
                    <h2>Dodaj swoje pierwsze zgłoszenie</h2>
                </div>
                <div className={cx('section-container')}>
                    <h3>Wybierz kategorię produktu</h3>

                    {!categories.fetchStatus.isLoaded ? (
                        <span>
                            <SnakeLoader />
                        </span>
                    ) : (
                        this.renderForm()
                    )}
                </div>
            </div>
        )
    }

    renderForm() {
        const { cx, handleSubmit, renderDropdown, categories } = this.props

        return (
            <Formik
                initialValues={{
                    category: '',
                    subcategory: ''
                }}
                onSubmit={handleSubmit}
                validationSchema={selectCategoryScheme}
                validateOnBlur={false}
                render={({
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setValues,
                    values
                }) => {
                    return (
                        <form className={cx('form')} onSubmit={handleSubmit}>
                            {renderDropdown({
                                name: 'category',
                                values,
                                handleChange: value => {
                                    setValues({
                                        category: value,
                                        subcategory: ''
                                    })
                                },
                                items: categories.data,
                                dropdownProps: {
                                    headerLabel: 'Kategoria',
                                    numberVisibleElements: 6,
                                    customStyles: dropdown1Styles
                                }
                            })}

                            {renderDropdown({
                                name: 'subcategory',
                                values,
                                handleChange: value =>
                                    setFieldValue('subcategory', value),
                                items:
                                    values.category !== ''
                                        ? categories.data[values.category]
                                              .children
                                        : [],
                                dropdownProps: {
                                    headerLabel: 'Podkategoria',
                                    headerSecondLabel: 'Niewymagane',
                                    numberVisibleElements: 6,
                                    customStyles: dropdown2Styles,
                                    isDisabled: !(values.category || values.category === 0)
                                }
                            })}

                            <Button
                                type="submit"
                                size="m-size"
                                customStyles={buttonStyles}
                                isLoading={isSubmitting}
                            >
                                Dalej
                            </Button>
                        </form>
                    )
                }}
            />
        )
    }
}

export default withStyles(styles)(CreateFirstProduct)
