import { combineReducers } from 'redux'

import * as actionTypes from 'constants/actionTypes/companies'
import { fetchStatusHelpers } from '@mindz/react-helpers'

const {
    initialState,
    requestState,
    successState,
    failureState
} = fetchStatusHelpers


const fetchCurrentCompanyStatusReducer = (state = initialState(), action) => {
    if (actionTypes.FETCH_COMPANY_REQUEST === action.type) {
        return requestState()
    }

    if (actionTypes.FETCH_COMPANY_SUCCESS === action.type) {
        return successState()
    }

    if (actionTypes.FETCH_COMPANY_FAILURE === action.type) {
        return failureState()
    }

    return state
}

const currentCompanyDataReducer = (state = {}, action) => {
    if (actionTypes.FETCH_COMPANY_REQUEST === action.type) {
        return {}
    }

    if (actionTypes.FETCH_COMPANY_SUCCESS === action.type) {
        return { ...action.payload.data }
    }

    return state
}

export default combineReducers({
    fetchStatus: fetchCurrentCompanyStatusReducer,
    data: currentCompanyDataReducer
})
