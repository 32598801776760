import { CALL_API } from 'middlewares/api'

import * as actionTypes from 'constants/actionTypes/categories'
import { urlHelpers } from '@mindz/react-helpers'

const { objToQueryString } = urlHelpers

const defaultParams = {
    query: ''
}

export const fetchCategories = (params = defaultParams) => dispatch => {
    const queryStr = objToQueryString({ ...defaultParams, ...params }, true)

    return dispatch({
        [CALL_API]: {
            endpoint: `/categories?${queryStr}`,
            types: [
                actionTypes.FETCH_CATEGORIES_REQUEST,
                actionTypes.FETCH_CATEGORIES_SUCCESS,
                actionTypes.FETCH_CATEGORIES_FAILURE
            ]
        }
    })
}

export const setChosenCategory = ({ category, subcategory }) => dispatch => {
    return dispatch({
        type: actionTypes.SET_CHOSEN_CATEGORY,
        payload: {
            category,
            subcategory
        }
    })
}
