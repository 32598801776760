import React from 'react'
import { DatePicker as DatePickerBase } from '@mindz/react-admin-ui'

import { withStyles }from '@mindz/react-hoc'

import styles from './index.module.css'

export default withStyles(styles)(({ children, ...props }) => (
    <DatePickerBase.Kaufland {...props}>{children}</DatePickerBase.Kaufland>
))
