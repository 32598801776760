import React, { Component, Fragment } from 'react'

import { withStyles } from '@mindz/react-hoc'
import styles from './Gallery.module.css'

import { Modal } from 'components/modal'
import SnakeLoader from 'components/SnakeLoader'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css?global'
import 'slick-carousel/slick/slick-theme.css?global'

import dialogStyles from './overrides/Dialog.module.css'
import modalStyles from './overrides/Modal.module.css'
import bodyStyles from './overrides/Body.module.css'

const IMAGE_DIMENSIONS = '?width=1348&height=874'

class Gallery extends Component {
    handleNext = () => {
        const { handleChangeSlide } = this.props
        this.slick && this.slick.slickNext()
        handleChangeSlide('next')
    }

    handlePrevious = () => {
        const { handleChangeSlide } = this.props
        this.slick && this.slick.slickPrev()
        handleChangeSlide('previous')
    }

    handleClose = () => {
        const { handleClose } = this.props
        document.removeEventListener(
            'keydown',
            this.handleKeyboardArrows,
            false
        )
        handleClose()
    }

    handleKeyboardArrows = event => {
        switch (event.keyCode) {
            case 37:
                this.handlePrevious()
                event.preventDefault()
                break
            case 39:
                this.handleNext()
                event.preventDefault()
                break
            default:
                break
        }
    }

    componentDidMount() {
        this.addListeners()
    }

    componentDidUpdate() {
        this.addListeners()
    }

    addListeners() {
        const { isOpen, isListenerAdded, handleListen } = this.props
        if (isOpen && !isListenerAdded) {
            document.addEventListener(
                'keydown',
                this.handleKeyboardArrows,
                false
            )
            handleListen()
        }
    }

    render() {
        const {
            cx,
            images,
            isOpen,
            current,
            title,
            isDisabledGalleryButton,
            isLoadingImage
        } = this.props
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        }

        return (
            <Modal
                isOpen={isOpen}
                handleClose={this.handleClose}
                customStyles={modalStyles}
            >
                <Modal.Dialog customStyles={dialogStyles}>
                    <Modal.Header handleClose={this.handleClose}>
                        <Modal.Title>{title}</Modal.Title>
                        <Modal.Close
                            size={'mediumSmall'}
                            handleClose={this.handleClose}
                        />
                    </Modal.Header>
                    <Modal.Body customStyles={bodyStyles}>
                        <div className={cx('root')}>
                            {
                                images.length > 1 &&
                                <Fragment>
                                    <span
                                        className={cx('left', { isDisabledGalleryButton })}
                                        onClick={this.handlePrevious}
                                    />
                                    <span
                                        className={cx('right', { isDisabledGalleryButton })}
                                        onClick={this.handleNext}
                                    />
                                </Fragment>
                            }

                            <Slider
                                {...settings}
                                className={cx('slick', { isDisabledGalleryButton })}
                                ref={ref => (this.slick = ref)}
                                initialSlide={current}
                            >
                                {
                                    !isLoadingImage && 
                                    images &&
                                    <div className={cx('slide')}>
                                        {images[current].path && (
                                            <span
                                                className={cx('image')}
                                                style={{
                                                    backgroundImage: `url(${
                                                        images[current].path
                                                    }${IMAGE_DIMENSIONS})`
                                                }}
                                            />
                                        )}
                                        <div className={cx('image-count')}>
                                            {current + 1}/{images.length}
                                        </div>
                                    </div>
                                }
                            </Slider>
                        </div>
                        {isLoadingImage && (
                            <div className={cx('loader')}>
                                <SnakeLoader />
                            </div>
                        )}
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        )
    }
}

export default withStyles(styles)(Gallery)
