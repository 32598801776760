export const displayNumber = (value, unit) => {
    if (!value) {
        return null
    }

    value = Number(value)
        .toString()
        .replace('.', ',')

    if (unit) {
        return value + ' ' + unit
    }

    return value
}