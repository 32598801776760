import React, { Component, Fragment } from 'react'
import { withStyles } from '@mindz/react-hoc'

import { Button } from 'components/forms'
import ChooseCategoriesModal from './SelectCategoryModal'
import Table from 'components/Table'

import IconCirclePlus from 'assets/icons/circle-plus.module.svg'
import IconArrow from 'assets/arrow_down.module.svg'
import IconTrash from 'assets/icons/trash.module.svg'
import IconAlert from 'assets/icons/alert.module.svg'
import styles from './index.module.css'
import buttonStyles from './overrides/Button.module.css'

class UserCategories extends Component {
    state = {
        shownIdSubcategories: null
    }

    render() {
        const {
            cx,
            isChooseCategoriesModalOpen,
            toggleChooseCategoriesModal,
            handleSubmit
        } = this.props

        return (
            <section className={cx('categories')}>
                <h3>Przydzielone kategorie</h3>
                {this.renderCategories()}
                <ChooseCategoriesModal
                    isOpen={isChooseCategoriesModalOpen}
                    handleClose={toggleChooseCategoriesModal}
                    handleSubmit={nextCategories => {
                        toggleChooseCategoriesModal()
                        handleSubmit(nextCategories)
                    }}
                />
            </section>
        )
    }

    renderCategories() {
        const { categories, cx } = this.props
        const { shownIdSubcategories } = this.state

        const mainCategories = categories.filter(
            category => !category.parent_id
        )

        if (!mainCategories.length) {
            return (
                <div className={cx('emptyList')}>
                    <p className={cx('message')}><IconAlert/> Nie masz jeszcze żadnej kategorii</p>
                    <div className={cx('add')}>{this.renderAddButton()}</div>
                </div>
            )
        }

        return (
            <div>
                <ul className={cx('categoriesList')}>
                    {mainCategories.map(category => (
                        <li
                            key={category.id}
                            className={cx({
                                shownSubcategory:
                                    category.id === shownIdSubcategories
                            })}
                        >
                            {this.renderCategory(category)}
                        </li>
                    ))}
                </ul>
                <div className={cx('add')}>{this.renderAddButton()}</div>
            </div>
        )
    }

    renderAddButton() {
        const { toggleChooseCategoriesModal } = this.props

        return (
            <Button
                type="button"
                icon={IconCirclePlus}
                size="s-size"
                theme="white"
                onClick={() => toggleChooseCategoriesModal()}
                customStyles={buttonStyles}
            >
                Dodaj kategorie
            </Button>
        )
    }

    toggleShowSubcategories = categoryId => {
        this.setState(prevState => ({
            shownIdSubcategories:
                categoryId === prevState.shownIdSubcategories
                    ? null
                    : categoryId
        }))
    }

    renderCategory(category) {
        const { categories, cx, handleRemoveCategory } = this.props

        const subCategories = categories.filter(
            item => item.parent_id === category.id
        )

        return (
            <Fragment>
                <div className={cx('category')}>
                    <span
                        className={cx('name')}
                        onClick={() =>
                            this.toggleShowSubcategories(category.id)
                        }
                    >
                        <IconArrow />
                        {category.name}
                    </span>
                    <span
                        className={cx('delete')}
                        onClick={() =>
                            handleRemoveCategory(
                                ...subCategories.map(item => item.id),
                                category.id
                            )
                        }
                    >
                        <IconTrash />{' '}
                        {!!subCategories.length
                            ? `Usuń wszystkie (${subCategories.length})`
                            : 'Usuń'}
                    </span>
                </div>
                <div className={cx('subcategories')}>
                    <Table>
                        <Table.Content>
                            {this.renderSubCategories(subCategories)}
                        </Table.Content>
                    </Table>
                </div>
            </Fragment>
        )
    }

    renderSubCategories(subCategories) {
        const { cx, handleRemoveCategory } = this.props

        if (!subCategories.length) {
            return null
        }

        return subCategories.map(category => (
            <tr key={category.id} className={cx('subcategory')}>
                <td>
                    {category.name}
                    <span
                        className={cx('delete')}
                        onClick={() => {
                            if (subCategories.length > 1) {
                                return handleRemoveCategory(category.id)
                            }

                            handleRemoveCategory(
                                category.id,
                                category.parent_id
                            )
                        }}
                    >
                        <IconTrash /> Usuń
                    </span>
                </td>
            </tr>
        ))
    }
}

export default withStyles(styles)(UserCategories)
