import { combineReducers } from 'redux'

import dbReducer from './db'
import localReducer from './local'

const appReducer = combineReducers({
    db: dbReducer,
    local: localReducer
})

const rootReducer = (state, action) => {
    return appReducer(state, action)
}

export default rootReducer
