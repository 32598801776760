import React, { Component } from 'react'
import idx from 'idx'

import { withStyles } from '@mindz/react-hoc'
import styles from './index.module.css'

import { DebounceInputField, Button, Dropdown } from 'components/forms'
import InfiniteScroll from 'components/InfiniteScroll'
import SubHeader from 'components/SubHeader'
import Skeleton from 'components/Skeleton'
import SectionLoader from 'components/SectionLoader'
import ProductIcon from 'assets/product.module.svg'
import IconLoading from 'assets/icons/loading.module.svg'

class CompanyListPage extends Component {
    render() {
        const { cx, companies } = this.props

        return (
            <div className={cx('root')}>
                {this.renderHeader()}
                <Skeleton
                    fetchStatus={companies.fetchStatus}
                    component={SectionLoader}
                    render={this.renderContent}
                />
            </div>
        )
    }

    renderHeader() {
        const {
            cx,
            companies,
            filters,
            handleChangeQuery,
            handleSubmitQuery,
            handleChangeSortBy
        } = this.props

        return (
            <SubHeader title="Firmy" count={companies.meta.count || 0}>
                <div className={cx('searchHolder')}>
                    <DebounceInputField
                        name="search"
                        placeholder="Szukaj firmy"
                        search
                        value={filters.query}
                        handleChange={handleChangeQuery}
                        handleDebounceChange={handleSubmitQuery}
                    />
                </div>
                <div className={cx('dropdownHolder')}>
                    <Dropdown
                        handleChange={sort_by => handleChangeSortBy(sort_by)}
                        placeholder="Wszystkie kategorie"
                        numberVisibleElements={6}
                    >
                        <Dropdown.Item
                            value="company.name"
                            selected={filters.sort_by === 'company.name'}
                        >
                            Alfabetycznie A-Z
                        </Dropdown.Item>
                        <Dropdown.Item
                            value="company.created_at"
                            selected={filters.sort_by === 'company.created_at'}
                        >
                            Data dodania
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            </SubHeader>
        )
    }

    renderContent = () => {
        const {
            cx,
            companies,
            onDetailsClick,
            handleLoadMore,
            areAnyCompanies
        } = this.props

        if (!areAnyCompanies && !idx(companies, _ => _.data.length)) {
            return (
                <section className={cx('emptyList')}>
                    <img src="/assets/icons/companies-empty.png" alt="" />
                    <h2>Jeszcze nie ma dodanych firm</h2>
                </section>
            )
        }

        return (
            <InfiniteScroll
                loadMore={handleLoadMore}
                hasMore={companies.meta.current < companies.meta.last}
                loader={
                    <div className={cx('loader')} key={0}>
                        <IconLoading />
                        <span>Ładowanie...</span>
                    </div>
                }
            >
                <ul className={cx('list')}>
                    {companies.data.map(company => {
                        const { id, name, requests } = company
                        return (
                            <li key={id} className={cx('company')}>
                                <span className={cx('name')}>{name}</span>
                                <div className={cx('requestsCount')}>
                                    <ProductIcon />
                                    <span>{requests.all} zgłoszeń</span>
                                </div>

                                <div className={cx('requests')}>
                                    <div>
                                        <div className={cx('circle')}>
                                            {requests.new}
                                        </div>
                                        <div className={cx('status')}>
                                            Oczekujące
                                        </div>
                                    </div>
                                    <div>
                                        <div className={cx('circle')}>
                                            {requests.accepted}
                                        </div>
                                        <div className={cx('status')}>
                                            Zweryfikowane
                                        </div>
                                    </div>
                                    <div>
                                        <div className={cx('circle')}>
                                            {requests.rejected}
                                        </div>
                                        <div className={cx('status')}>
                                            Brak akceptacji
                                        </div>
                                    </div>
                                </div>

                                <Button
                                    theme="white"
                                    size="s-size"
                                    onClick={() => onDetailsClick(id)}
                                >
                                    Szczegóły
                                </Button>
                            </li>
                        )
                    })}
                </ul>
            </InfiniteScroll>
        )
    }
}

export default withStyles(styles)(CompanyListPage)
