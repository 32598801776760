import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from 'actions/auth'
import SectionLoader from 'components/SectionLoader'

class LogoutPage extends Component {
    componentDidMount() {
        const { actions } = this.props

        actions.logout()

        setTimeout(() => {
            window.location.href = '/logowanie'
        }, 500)
    }

    render() {
        return <SectionLoader />
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default connect(
    null,
    mapDispatchToProps
)(LogoutPage)
