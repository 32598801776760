import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'

import SnakeLoader from 'components/SnakeLoader'

import styles from './index.module.css'

class AppLoader extends Component {
    render() {
        const { cx } = this.props
        return (
            <div className={cx('root')}>
                <SnakeLoader />
            </div>
        )
    }
}

export default withStyles(styles)(AppLoader)
