import { combineReducers } from 'redux'

import * as actionTypes from 'constants/actionTypes'

export const messagesReducer = (state = [], action) => {
    if (actionTypes.ADD_FLASH_MESSAGES === action.type) {
        return [...state, action.payload]
    }

    if (actionTypes.REMOVE_FLASH_MESSAGE === action.type) {
        return state.filter((message, index) => index !== action.payload)
    }

    if (actionTypes.RESET_FLASH_MESSAGES === action.type) {
        return []
    }

    return state
}

export default combineReducers({
    messages: messagesReducer
})
