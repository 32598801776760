import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@mindz/react-hoc'

import * as actions from 'actions/flashMessages'

import FlashMessage from 'components/FlashMessage'

import styles from './index.module.css'
import flashMessageStyles from './overrides/FlashMessage.module.css'

class FlashMessageContainer extends Component {
    componentWillUnmount() {
        const { actions } = this.props

        actions.resetFlashMessages()
    }

    render() {
        const { cx, flashMessages } = this.props

        if (!flashMessages.length) {
            return null
        }

        return (
            <div className={cx('root')}>
                {flashMessages.map((message, index) => {
                    return (
                        <FlashMessage
                            key={index}
                            id={index}
                            customStyles={flashMessageStyles}
                            type={message.type}
                            title={message.title}
                            handleClose={this.handleClose}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: message.content
                                }}
                            />
                        </FlashMessage>
                    )
                })}
            </div>
        )
    }

    handleClose = index => {
        const { actions } = this.props

        actions.removeFlashMessageAtIndex(index)
    }
}

const mapStateToProps = state => {
    return { flashMessages: state.local.flashMessages.messages }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FlashMessageContainer)
)
