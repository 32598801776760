import { CALL_API } from './api'

export default store => next => action => {
    const { [CALL_API]: callAPI } = action

    if (typeof callAPI === 'undefined') {
        return next(action)
    }

    const user = store.getState().local.user

    if (!user.loggedIn) {
        return next(action)
    }

    const options = callAPI.options || {}

    callAPI.options = {
        ...options,
        headers: {
            ...options.headers,
            'X-Authorization': user.currentUser.token
        }
    }

    return next(action)
}
