import React, { Component } from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from 'actions/auth'
import RemindPasswordModal from './ResetPasswordModal'

class ResetPasswordModalContainer extends Component {
    render() {

        return (
            <RemindPasswordModal
                {...this.props}
                handleSubmit={this.props.handleSubmit}
            />
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

const enhance = compose(
    connect(
        null,
        mapDispatchToProps
    )
)

export default enhance(ResetPasswordModalContainer)
