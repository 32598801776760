import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withPermissions } from 'HOC'

import * as actions from 'actions/account'

import Layout from 'layouts/Layout'
import ProductRoutes from 'routes/ProductRoutes'
import CategoryRoutes from 'routes/CategoryRoutes'
import CompanyRoutes from 'routes/CompanyRoutes'
import UserRoutes from 'routes/UserRoutes'
import AccountRoutes from 'routes/AccountRoutes'
import DashboardPage from 'pages/DashboardPage'
import Skeleton from 'components/Skeleton'
import AppLoader from 'components/AppLoader'
import ManufacturerRestrictedRoute from './ManufacturerRestrictedRoute'

import { fetchStatusHelpers } from '@mindz/react-helpers'

const {
    initialState,
    requestState,
    successState,
    failureState
} = fetchStatusHelpers

const Routes = withPermissions()(
    ({ isManufacturer, isSalesman, isAdmin, isBusiness, match }) => {
        return (
            <Layout>
                <Switch>
                    {!(isAdmin || isBusiness) && (
                        <Route
                            path={match.path}
                            exact
                            component={ProductRoutes}
                        />
                    )}
                    {(isAdmin || isBusiness) && (
                        <Route
                            path={match.path}
                            exact
                            component={DashboardPage}
                        />
                    )}
                    <Route
                        path={match.path + 'zgloszenia'}
                        component={ProductRoutes}
                    />
                    <Route
                        path={match.path + 'kategorie'}
                        component={CategoryRoutes}
                    />
                    <ManufacturerRestrictedRoute
                        path={match.path + 'uzytkownicy'}
                        component={UserRoutes}
                    />
                    <Route
                        path={match.path + 'firmy'}
                        component={CompanyRoutes}
                    />
                    <Route
                        path={match.path + 'moje-konto'}
                        component={AccountRoutes}
                    />
                    <Route
                        path="*"
                        render={({ match }) => (
                            <p className="pageNotFound">
                                Nie znaleziono strony
                            </p>
                        )}
                    />
                </Switch>
            </Layout>
        )
    }
)

class AppRoutes extends Component {
    state = {
        fetchStatus: initialState()
    }

    componentDidMount() {
        const { actions } = this.props

        this.setState({ fetchStatus: requestState() })

        actions
            .fetchAccountIfNeeded()
            .then(() => {
                this.setState({ fetchStatus: successState() })
            })
            .catch(() => {
                this.setState({ fetchStatus: failureState() })
            })
    }

    render() {
        return (
            <Skeleton
                fetchStatus={this.state.fetchStatus}
                component={AppLoader}
            >
                <Routes {...this.props} />
            </Skeleton>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(
    null,
    mapDispatchToProps
)(AppRoutes)
