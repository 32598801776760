import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import * as categoryActions from 'actions/categories'
import * as productActions from 'actions/products'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Dropdown, Field } from 'components/forms'

const withLogic = () => {
    return WrappedComponent => {
        class Enhance extends Component {
            componentDidMount() {
                const { actions } = this.props
                actions.fetchCategories()
            }

            render() {
                return (
                    <WrappedComponent
                        {...this.props}
                        handleSubmit={this.handleSubmit}
                        renderDropdown={this.renderDropdown}
                    />
                )
            }

            handleSubmit = (values, formikActions) => {
                const { categories, actions, history } = this.props
                const data = {
                    category_id: categories.data[values.category].id
                }

                if (values.subcategory !== '')
                    data.subcategory_id =
                        categories.data[values.category].children[
                            values.subcategory
                        ].id

                actions
                    .createProduct(data)
                    .then(result =>
                        history.push(
                            '/zgloszenia/' + result.data.id + '/edytuj'
                        )
                    )
                    .catch(() => formikActions.setSubmitting(false))
            }

            renderDropdown({
                name,
                values,
                handleChange,
                items = [],
                dropdownProps = {}
            }) {
                return (
                    <Field
                        name={name}
                        render={({ name, error }) => (
                            <Dropdown
                                handleChange={handleChange}
                                error={error}
                                name={name}
                                placeholder=" - Wybierz - "
                                numberVisibleElements={6}
                                {...dropdownProps}
                            >
                                {items &&
                                    items.map((category, index) => (
                                        <Dropdown.Item
                                            selected={index === values[name]}
                                            key={index}
                                            value={index}
                                        >
                                            {category.name}
                                        </Dropdown.Item>
                                    ))}
                            </Dropdown>
                        )}
                    />
                )
            }
        }

        const mapDispatchToProps = dispatch => {
            return {
                actions: bindActionCreators(
                    {
                        ...categoryActions,
                        ...productActions
                    },
                    dispatch
                )
            }
        }

        const mapStateToProps = state => {
            return {
                categories: state.db.categories
            }
        }

        const enhance = compose(
            withRouter,
            connect(
                mapStateToProps,
                mapDispatchToProps
            )
        )

        return enhance(Enhance)
    }
}

export default withLogic
