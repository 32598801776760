import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import idx from 'idx'
import { withPermissions } from 'HOC'

import AccountPage from './AccountPage'
import * as actions from 'actions/account'

class AccountPageContainer extends Component {
    state = {
        isModalOpen: false
    }

    render() {
        const { isModalOpen } = this.state
        return (
            <AccountPage
                {...this.props}
                isModalOpen={isModalOpen}
                closeModal={this.closeModal}
                showModal={this.showModal}
                handleSubmitChangePassword={this.handleSubmitChangePassword}
            />
        )
    }

    closeModal = () => {
        this.setState({
            isModalOpen: false
        })
    }

    showModal = () => {
        this.setState({
            isModalOpen: true
        })
    }

    handleSubmitChangePassword = (values, formikActions) => {
        const { actions } = this.props
        const { oldPassword, newPassword } = values

        actions
            .changePassword({ oldPassword, newPassword })
            .then(() => {
                this.closeModal()
            })
            .catch(responseWithError => {
                formikActions.setSubmitting(false)

                if (responseWithError.status === 422) {
                    return responseWithError.json().then(json => {
                        if (
                            idx(json, _ => _.message) ===
                            'Provided old password does not match with actual old password '
                        ) {
                            formikActions.setErrors({
                                oldPassword: 'Nieprawidłowe hasło'
                            })
                        }
                    })
                }
            })
    }
}

const mapStateToProps = state => {
    return {
        account: state.local.account
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPermissions()(AccountPageContainer))
