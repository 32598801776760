import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import { withStyles } from '@mindz/react-hoc'
import FlashMessage from 'components/FlashMessage'
import { Button, InputField, Field, TextArea } from 'components/forms'

import IconArrow from 'assets/arrow_left.module.svg'
import styles from './index.module.css'
import buttonStyles from './overrides/Button.module.css'

class AccountPageEdit extends Component {
    render() {
        const { cx } = this.props

        return (
            <div className={cx('root')}>
                <Link className={cx('return')} to="/moje-konto">
                    <IconArrow /> Wróć do mojego konta
                </Link>

                <div className={cx('content')}>
                    <h2>Edytuj dane</h2>
                    {this.renderForm()}
                </div>
            </div>
        )
    }

    renderForm() {
        const {
            cx,
            handleSubmit,
            handleCancel,
            isManufacturer,
            initialValues,
            validationSchema
        } = this.props

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                render={({ handleSubmit, isSubmitting, values, errors }) => {
                    return (
                        <form className={cx('form', {salesman: !isManufacturer})} onSubmit={handleSubmit}>
                            {errors.error && (
                                <FlashMessage
                                    type="failure"
                                    title={errors.error}
                                />
                            )}
                            <section className={cx('user')}>
                                <div className={cx('inputWrapper')}>
                                    <Field
                                        name="email"
                                        headerLabel="E-mail"
                                        headerSecondLabel="Nie można edytować"
                                        type="text"
                                        placeholder=""
                                        component={InputField}
                                        isReadOnly={true}
                                    />
                                </div>

                                <div className={cx('inputWrapper')}>
                                    <Field
                                        name="first_name"
                                        headerLabel="Imię"
                                        type="text"
                                        placeholder=""
                                        component={InputField}
                                    />
                                </div>
                                <div className={cx('inputWrapper')}>
                                    <Field
                                        name="last_name"
                                        headerLabel="Nazwisko"
                                        type="text"
                                        placeholder=""
                                        component={InputField}
                                    />
                                </div>
                                {isManufacturer && (
                                    <div className={cx('inputWrapper')}>
                                        <Field
                                            name="phone"
                                            headerLabel="Telefon"
                                            type="text"
                                            placeholder=""
                                            component={InputField}
                                        />
                                    </div>
                                )}
                            </section>

                            {isManufacturer && this.renderCompanySection()}

                            <div className={cx('actions')}>
                                <Button
                                    type="button"
                                    size="m-size"
                                    theme="white"
                                    className="cancel"
                                    onClick={handleCancel}
                                    customStyles={buttonStyles}
                                >
                                    Anuluj
                                </Button>
                                <Button
                                    type="submit"
                                    size="m-size"
                                    className="submit"
                                    isLoading={isSubmitting}
                                    customStyles={buttonStyles}
                                >
                                    Zapisz
                                </Button>
                            </div>
                        </form>
                    )
                }}
            />
        )
    }

    renderCompanySection() {
        const { cx } = this.props

        return (
            <section className={cx('company')}>
                <h3>Dane mojej firmy</h3>

                <div className={cx('inputWrapper')}>
                    <Field
                        name="company.name"
                        headerLabel="Nazwa firmy"
                        type="text"
                        placeholder=""
                        component={InputField}
                    />
                </div>
                <div className={cx('inputWrapper')}>
                    <Field
                        name="company.established"
                        headerLabel="Rok założenia firmy"
                        type="text"
                        placeholder=""
                        component={InputField}
                    />
                </div>
                <div className={cx('inputWrapper')}>
                    <Field
                        name="company.description"
                        headerLabel="Profil / historia działalności "
                        type="text"
                        component={TextArea}
                    />
                </div>
            </section>
        )
    }
}

export default withStyles(styles)(AccountPageEdit)
