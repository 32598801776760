import React, { Component } from 'react'
import { Formik } from 'formik'

import { withStyles } from '@mindz/react-hoc'
import { Field } from '@mindz/react-admin-ui'

import RemindPasswordModal from '../SignInPage/RemindPasswordModal'
import { InputField, Button } from 'components/forms'

import styles from './index.module.css'

class SignInPage extends Component {
    render() {
        const {
            cx,
            handleSubmit,
            isRemindPasswordModalOpen,
            toggleRemindPasswordModal
        } = this.props

        return (
            <div className={cx('root')}>
                <div className={cx('holder')}>
                    <div className={cx('logo')}>
                        <img src="/assets/logo.png" alt="K-Starter" />
                    </div>
                    <div className={cx('header')}>
                        <h2>Panel zarządzania</h2>
                        <h1>Zaloguj się do platformy</h1>
                    </div>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                            rememberMe: false
                        }}
                        onSubmit={handleSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                        render={({
                            handleSubmit,
                            isSubmitting,
                            errors
                        }) => {
                            return (
                                <form
                                    className={cx('form')}
                                    onSubmit={handleSubmit}
                                >
                                    {errors.error && (
                                        <div className={cx('error')}>
                                            {errors.error}
                                        </div>
                                    )}

                                    <div className={cx('inputWrapper')}>
                                        <Field
                                            name="email"
                                            headerLabel="E-mail"
                                            type="text"
                                            component={InputField}
                                        />
                                    </div>
                                    <div className={cx('inputWrapper')}>
                                        <Field
                                            headerLabel='Hasło'
                                            name="password"
                                            type="password"
                                            component={InputField}
                                        />
                                    </div>

                                    <div className={cx('actions')}>
                                        <Button
                                            type="submit"
                                            isLoading={isSubmitting}
                                            className={cx('submitButton')}
                                        >
                                            Zaloguj się
                                        </Button>
                                    </div>

                                    <div className={cx('forgotPassword')}>
                                        Nie pamiętasz hasła?{' '}
                                        <span
                                            className={cx('link')}
                                            onClick={toggleRemindPasswordModal}
                                        >
                                            Odzyskaj hasło
                                        </span>
                                    </div>
                                </form>
                            )
                        }}
                    />
                </div>
                <RemindPasswordModal
                    isOpen={isRemindPasswordModalOpen}
                    handleClose={toggleRemindPasswordModal}
                />
            </div>
        )
    }
}

export default withStyles(styles)(SignInPage)
