import { combineReducers } from 'redux'

import * as actionTypes from 'constants/actionTypes/categories'
import { fetchStatusHelpers } from '@mindz/react-helpers'

const {
    initialState,
    requestState,
    successState,
    failureState
} = fetchStatusHelpers

const fetchStatusReducer = (state = initialState(), action) => {
    if (actionTypes.FETCH_CATEGORIES_REQUEST === action.type) {
        return requestState()
    }

    if (actionTypes.FETCH_CATEGORIES_SUCCESS === action.type) {
        return successState()
    }

    if (actionTypes.FETCH_CATEGORIES_FAILURE === action.type) {
        return failureState()
    }

    return state
}

const dataReducer = (state = [], action) => {
    if (actionTypes.FETCH_CATEGORIES_SUCCESS === action.type) {
        return [...action.payload.data]
    }

    return state
}

const chosenReducer = (
    state = { category: null, subcategory: null },
    action
) => {
    if (actionTypes.SET_CHOSEN_CATEGORY === action.type) {
        const { category, subcategory } = action.payload
        return {
            category: category || null,
            subcategory: subcategory || null
        }
    }

    return state
}

export default combineReducers({
    fetchStatus: fetchStatusReducer,
    data: dataReducer,
    chosen: chosenReducer
})
