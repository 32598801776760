import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import CategoryListPage from 'pages/CategoryListPage'

class CategoryRoutes extends Component {
    render() {
        const { match } = this.props

        return (
            <Switch>
                <Route path={match.path} exact component={CategoryListPage} />
            </Switch>
        )
    }
}

export default CategoryRoutes
