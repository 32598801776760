import { CALL_API } from 'middlewares/api'
import * as actionTypes from 'constants/actionTypes'

const defaultParams = {
    page: 1,
    length: 10
}

export const fetchNotifications = ({
    page = defaultParams.page,
    length = defaultParams.length
} = defaultParams) => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: `/notifications?page=${page}&length=${length}`,
            types: [
                actionTypes.FETCH_NOTIFICATIONS_REQUEST,
                actionTypes.FETCH_NOTIFICATIONS_SUCCESS,
                actionTypes.FETCH_NOTIFICATIONS_FAILURE
            ]
        }
    })
}

export const tagNotificationAsRead = id => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: `/notifications/${id}`,
            options: {
                method: 'POST'
            },
            types: [
                actionTypes.TAG_NOTIFICATION_AS_READ_REQUEST,
                actionTypes.TAG_NOTIFICATION_AS_READ_SUCCESS,
                actionTypes.TAG_NOTIFICATION_AS_READ_FAILURE
            ]
        },
        notificationId: id
    })
}
