import React, { Component } from 'react'

import { Modal } from 'components/modal'
import SelectCategoryModalContainer from './SelectCategoryModalContainer'

class SelectCategoryModalRootContainer extends Component {
    render() {
        const { isOpen, handleClose } = this.props

        return (
            <Modal isOpen={isOpen} handleClose={handleClose}>
                <SelectCategoryModalContainer {...this.props} />
            </Modal>
        )
    }
}

export default SelectCategoryModalRootContainer
