import React from 'react'

import { withStyles } from '@mindz/react-hoc'

import styles from './Divider.module.css'

const Divider = ({ cx }) => {
    return <div className={cx('root')} />
}

export default withStyles(styles)(Divider)
