import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchStatusHelpers } from '@mindz/react-helpers'

const { fetchStatusFromResources } = fetchStatusHelpers

const withPermissions = (...permissionArgs) => {
    return WrappedComponent => {
        class Enhance extends Component {
            render() {
                const { account } = this.props

                if (fetchStatusFromResources(account).isLoaded) {
                    return (
                        <WrappedComponent
                            {...this.props}
                            isAdmin={this.isAdmin()}
                            isManufacturer={this.isManufacturer()}
                            isSalesman={this.isSalesman()}
                            isBusiness={this.isBusiness()}
                        />
                    )
                }

                return <Redirect to="/" />
            }

            roles() {
                const { account } = this.props

                return account.data.roles || []
            }

            isAdmin() {
                const { account } = this.props

                return !!account.data.admin
            }

            isManufacturer() {
                const roles = this.roles()

                return !!roles.find(item => item.name === 'manufacturer')
            }

            isSalesman() {
                const roles = this.roles()

                return !!roles.find(item => item.name === 'salesman')
            }

            isBusiness() {
                const roles = this.roles()

                return !!roles.find(item => item.name === 'business')
            }
        }

        const mapDispatchToProps = state => {
            return {
                account: state.local.account
            }
        }

        return connect(mapDispatchToProps)(Enhance)
    }
}

export default withPermissions
