import { Component } from 'react'
import ReactDOM from 'react-dom'

class ScrollToError extends Component {
    componentDidUpdate(prevProps) {
        if (!this.props.isValid && prevProps.isSubmitting) {
            if (Object.keys(this.props.errors)[0] === this.props.name) {
                const node = ReactDOM.findDOMNode(this.props.inputRef.current)
                node && node.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }

    render() {
        return null
    }
}

export default ScrollToError
