import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'

import { Modal } from 'components/modal'
import { Button } from 'components/forms'
import { STATUSES } from 'constants/product'

import styles from './index.module.css'
import dialogStyles from './overrides/Dialog.module.css'
import headerStyles from './overrides/Header.module.css'
import bodyStyles from './overrides/Body.module.css'
import buttonStyles from './overrides/Button.module.css'

const DialogFailure = ({ cx, children, handleClose }) => (
    <Modal.Dialog customStyles={dialogStyles}>
        <Modal.Header customStyles={headerStyles}>
            <div className={cx('icon')}>
                <img
                    src="/assets/icons/papers-remove.png"
                    alt="Wystąpił błąd"
                />
            </div>
        </Modal.Header>
        <Modal.Body customStyles={bodyStyles}>
            <div className={cx('message')}>{children}</div>
            <div className={cx('actions', 'center')}>
                <Button
                    type="button"
                    size="m-size"
                    theme="white"
                    customStyles={buttonStyles}
                    className="cancel upper"
                    onClick={handleClose}
                >
                    Zamknij
                </Button>
            </div>
        </Modal.Body>
    </Modal.Dialog>
)

class ChangeStatusModal extends Component {
    hasError() {
        const { responseStatus } = this.props

        return responseStatus && (responseStatus < 200 || responseStatus > 299)
    }

    isError(status) {
        const { responseStatus } = this.props

        return status === responseStatus
    }

    render() {
        const {
            cx,
            isManufacturer,
            isSubmitting,
            nextStatus,
            handleClose,
            handleSubmit
        } = this.props

        if (isManufacturer) {
            if (this.hasError()) {
                if (this.isError(422)) {
                    return (
                        <DialogFailure cx={cx} handleClose={handleClose}>
                            Nie możesz jeszcze wysłać zgłoszenia. Przejdź do
                            edycji produktu, aby uzupełnić brakujące dane.
                        </DialogFailure>
                    )
                }

                return (
                    <DialogFailure cx={cx} handleClose={handleClose}>
                        Wystąpił błąd podczas wysyłania zgłoszenia.
                    </DialogFailure>
                )
            }

            return (
                <Modal.Dialog customStyles={dialogStyles}>
                    <Modal.Header customStyles={headerStyles}>
                        <div className={cx('icon')}>
                            <img
                                src="/assets/icons/papers-send.png"
                                alt="Wysyłasz zgłoszenie"
                            />
                        </div>
                        <Modal.Title>WYŚLIJ ZGŁOSZENIE</Modal.Title>
                    </Modal.Header>
                    <Modal.Body customStyles={bodyStyles}>
                        <div className={cx('message')}>
                            Pamiętaj, że po wysłaniu zgłoszenia nie będzie można
                            go już edytować.
                        </div>
                        <div className={cx('actions')}>
                            <Button
                                type="button"
                                size="m-size"
                                theme="white"
                                customStyles={buttonStyles}
                                className="cancel upper"
                                onClick={handleClose}
                            >
                                Anuluj
                            </Button>
                            <Button
                                type="button"
                                size="m-size"
                                customStyles={buttonStyles}
                                onClick={() => handleSubmit()}
                                className="submit upper"
                                isLoading={isSubmitting}
                            >
                                Tak, wysyłam
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>
            )
        }

        if (STATUSES.ACCEPTED === nextStatus) {
            if (this.hasError()) {
                return (
                    <DialogFailure cx={cx} handleClose={handleClose}>
                        Wystąpił błąd podczas zmiany statusu.
                    </DialogFailure>
                )
            }

            return (
                <Modal.Dialog customStyles={dialogStyles}>
                    <Modal.Header customStyles={headerStyles}>
                        <div className={cx('icon')}>
                            <img
                                src="/assets/icons/papers-accepted.png"
                                alt="Akceptujesz zgłoszenie"
                            />
                        </div>
                        <Modal.Title>AKCEPTUJ ZGŁOSZENIE</Modal.Title>
                    </Modal.Header>
                    <Modal.Body customStyles={bodyStyles}>
                        <div className={cx('message')}>
                            Prosimy o potwierdzenie przyjęcia zgłoszenia do
                            weryfikacji.
                        </div>
                        <div className={cx('actions')}>
                            <Button
                                type="button"
                                size="m-size"
                                theme="white"
                                customStyles={buttonStyles}
                                className="cancel upper"
                                onClick={handleClose}
                            >
                                Anuluj
                            </Button>
                            <Button
                                type="button"
                                size="m-size"
                                customStyles={buttonStyles}
                                onClick={() => handleSubmit()}
                                className="submit upper"
                                isLoading={isSubmitting}
                            >
                                Potwierdzam
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>
            )
        }

        if (STATUSES.REJECTED === nextStatus) {
            if (this.hasError()) {
                return (
                    <DialogFailure cx={cx} handleClose={handleClose}>
                        Wystąpił błąd podczas zmiany statusu.
                    </DialogFailure>
                )
            }

            return (
                <Modal.Dialog customStyles={dialogStyles}>
                    <Modal.Header customStyles={headerStyles}>
                        <div className={cx('icon')}>
                            <img
                                src="/assets/icons/papers-remove.png"
                                alt="Odrzucasz zgłoszenie"
                            />
                        </div>
                        <Modal.Title>ODRZUĆ ZGŁOSZENIE</Modal.Title>
                    </Modal.Header>
                    <Modal.Body customStyles={bodyStyles}>
                        <div className={cx('message')}>
                            Czy na pewno chcesz odrzucić to zgłoszenie?
                        </div>
                        <div className={cx('actions')}>
                            <Button
                                type="button"
                                size="m-size"
                                theme="white"
                                customStyles={buttonStyles}
                                className="cancel upper"
                                onClick={handleClose}
                            >
                                Anuluj
                            </Button>
                            <Button
                                type="button"
                                size="m-size"
                                customStyles={buttonStyles}
                                onClick={() => handleSubmit()}
                                className="submit upper"
                                isLoading={isSubmitting}
                            >
                                Potwierdzam
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>
            )
        }

        return null
    }
}

export default withStyles(styles)(ChangeStatusModal)
