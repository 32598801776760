import React, { Component } from 'react'
import SelectCategoryModal from './SelectCategoryModal'
import withLogic from '../../withLogic'

class SelectCategoryModalContainer extends Component {
    render() {
        const { isOpen, handleClose } = this.props
        
        return (
            <SelectCategoryModal
                {...this.props}
                isOpen={isOpen}
                handleClose={handleClose}
            />
        )
    }
}

export default withLogic()(SelectCategoryModalContainer)
