import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from 'actions/auth'

import SignInPage from './SignInPage'

class SignInPageContainer extends Component {
    state = {
        isRemindPasswordModalOpen: false
    }

    render() {
        const { isRemindPasswordModalOpen } = this.state

        return (
            <SignInPage
                {...this.props}
                handleSubmit={this.handleSubmit}
                isRemindPasswordModalOpen={isRemindPasswordModalOpen}
                toggleRemindPasswordModal={this.toggleRemindPasswordModal}
            />
        )
    }

    toggleRemindPasswordModal = () => {
        this.setState(prevState => ({
            isRemindPasswordModalOpen: !prevState.isRemindPasswordModalOpen
        }))
    }

    handleSubmit = (values, formikActions) => {
        const { actions } = this.props

        formikActions.setSubmitting(true)

        actions.login(values).then(
            response => {
                const token = response.data.jwt_token

                localStorage.setItem(
                    'user',
                    JSON.stringify({
                        email: values.email,
                        token
                    })
                )

                window.location.href = '/'
            },
            responseWithError => {
                formikActions.setSubmitting(false)

                if (responseWithError.status === 422) {
                    return responseWithError.json().then(json => {
                        if (json.errors) {
                            return formikActions.setErrors(json.errors)
                        }

                        if (json.message) {
                            return formikActions.setErrors({
                                error: json.message
                            })
                        }
                    })
                }

                formikActions.setErrors({
                    error: responseWithError.statusText
                })
            }
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default connect(
    null,
    mapDispatchToProps
)(SignInPageContainer)
