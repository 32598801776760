import React from 'react'
import { Dropdown as DropdownBase } from '@mindz/react-admin-ui'

import { withStyles }from '@mindz/react-hoc'

import styles from './index.module.css'

export default withStyles(styles)(({ children, ...props }) => (
    <DropdownBase.Kaufland {...props}>{children}</DropdownBase.Kaufland>
))
