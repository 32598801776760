import { combineReducers } from 'redux'

import * as actionTypes from 'constants/actionTypes/account'
import { fetchStatusHelpers } from '@mindz/react-helpers'

const { initialState, requestState, successState, failureState } = fetchStatusHelpers

const fetchStatusReducer = (state = initialState(), action) => {
    if (actionTypes.FETCH_ACCOUNT_REQUEST === action.type) {
        return requestState()
    }

    if (actionTypes.FETCH_ACCOUNT_SUCCESS === action.type) {
        return successState(action)
    }

    if (actionTypes.FETCH_ACCOUNT_FAILURE === action.type) {
        return failureState(action)
    }

    return state
}

const dataReducer = (state = {}, action) => {
    if (actionTypes.FETCH_ACCOUNT_SUCCESS === action.type) {
        return {
            ...action.payload.data
        }
    }

    return state
}

export default combineReducers({
    fetchStatus: fetchStatusReducer,
    data: dataReducer
})