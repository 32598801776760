import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { withStyles } from '@mindz/react-hoc'

import { STATUSES_META } from 'constants/product'
import SubHeader from 'components/SubHeader'
import { Dropdown, Button } from 'components/forms'
import SectionLoader from 'components/SectionLoader'
import Skeleton from 'components/Skeleton'

import styles from './index.module.css'
import subHeaderStyles from './overrides/SubHeader.module.css'
import dropdownStyles from './overrides/Dropdown.module.css'

import IconProduct from 'assets/product.module.svg'
import IconWatch from 'assets/icons/watch.module.svg'
import NoImage from 'assets/no-image.module.svg'

const IMAGE_DIMENSIONS = '?width=185&height=185'

class DashboardPage extends Component {
    render() {
        const { cx, fetchStatusDashboard } = this.props

        return (
            <div className={cx('root')}>
                {this.renderHeader()}
                <Skeleton
                    fetchStatus={fetchStatusDashboard}
                    component={SectionLoader}
                    render={this.renderContent}
                >
                    {this.renderContent()}
                </Skeleton>
            </div>
        )
    }

    renderHeader() {
        const { cx, categories, filters, handleChangeFilter } = this.props

        return (
            <SubHeader
                customStyles={subHeaderStyles}
                title="Dashboard"
                className={cx('header')}
            >
                <div className={cx('subHeaderRight')}>
                    <div className={cx('subHeaderDropdown')}>
                        <Dropdown
                            handleChange={value =>
                                handleChangeFilter('category_id', value)
                            }
                            customStyles={dropdownStyles}
                            placeholder="Wszystkie kategorie"
                            numberVisibleElements={6}
                        >
                            <Dropdown.Item
                                value={null}
                                selected={filters.category_id === null}
                            >
                                Wszystkie kategorie
                            </Dropdown.Item>
                            {categories.map(item => {
                                return (
                                    <Dropdown.Item
                                        key={item.id}
                                        value={item.id}
                                        selected={
                                            filters.category_id === item.id
                                        }
                                    >
                                        {item.name}
                                    </Dropdown.Item>
                                )
                            })}
                        </Dropdown>
                    </div>
                    <div className={cx('subHeaderDropdown')}>
                        {this.renderPeriods()}
                    </div>
                </div>
            </SubHeader>
        )
    }

    renderPeriods() {
        const { filters, periods, handleChangeFilter } = this.props

        return (
            <Dropdown
                handleChange={value => handleChangeFilter('period', value)}
                customStyles={dropdownStyles}
                placeholder="Cały czas"
                numberVisibleElements={6}
            >
                <Dropdown.Item value={null} selected={filters.period === null}>
                    Cały czas
                </Dropdown.Item>
                {periods.map(item => {
                    return (
                        <Dropdown.Item
                            key={item.id}
                            value={item.id}
                            selected={filters.period === item.id}
                        >
                            {item.name}
                        </Dropdown.Item>
                    )
                })}
            </Dropdown>
        )
    }

    renderContent = () => {
        const { cx } = this.props

        return (
            <Fragment>
                <div className={cx('info')}>
                    {this.renderRequestSection()}
                    {this.renderResponseTimeSection()}
                </div>
                {this.renderLastProducts()}
            </Fragment>
        )
    }

    renderRequestSection = () => {
        const { cx, dashboard } = this.props

        return (
            <section className={cx('requests')}>
                <div className={cx('countContainer')}>
                    <div className={cx('count')}>
                        <IconProduct />
                        <span>{dashboard.all}</span>
                    </div>
                    <div className={cx('label')}>
                        Liczba wszystkich
                        <br /> zgłoszeń
                    </div>
                </div>
                <div className={cx('typeContainer')}>
                    <div className={cx('type', 'new')}>
                        <span className={cx('circle')}>{dashboard.new}</span>
                        <div className={cx('label')}>
                            Oczekujące
                            <br /> na weryfikację
                        </div>
                    </div>
                    <div className={cx('type', 'accepted')}>
                        <span className={cx('circle')}>
                            {dashboard.accepted}
                        </span>
                        <div className={cx('label')}>
                            Zweryfikowane
                            <br /> pozytywnie
                        </div>
                    </div>
                    <div className={cx('type', 'rejected')}>
                        <span className={cx('circle')}>
                            {dashboard.rejected}
                        </span>
                        <div className={cx('label')}>
                            Brak
                            <br /> akceptacji
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    renderResponseTimeSection() {
        const { cx, dashboard } = this.props

        const responseTime =
            dashboard.responseTime || dashboard.responseTime === '0'
                ? dashboard.responseTime
                : '-'

        return (
            <section className={cx('requestResponseTimeSection')}>
                <div className={cx('countContainer')}>
                    <div className={cx('count')}>
                        <IconWatch />
                        <span>{responseTime}</span>
                    </div>
                    <div className={cx('label')}>
                        Śr. czas oczekiwania
                        <br /> na decyzję Kupca
                    </div>
                </div>
            </section>
        )
    }

    renderLastProducts = () => {
        const { cx } = this.props

        return (
            <div className={cx('products')}>
                <h3>Ostatnie zgłoszenia</h3>
                {this.renderProductList()}
            </div>
        )
    }

    renderProductList() {
        const { dashboard } = this.props
        const list = dashboard.last || []

        if (!list.length) {
            return this.renderProductListEmpty()
        }

        return (
            <Fragment>
                <ul>{list.map(product => this.renderProduct(product))}</ul>
                <div style={{ textAlign: 'center', marginBottom: 32 }}>
                    <Link to="/zgloszenia">
                        <Button theme="white" size="m-size">
                            Pokaż wszystkie
                        </Button>
                    </Link>
                </div>
            </Fragment>
        )
    }

    renderProduct = product => {
        const { cx } = this.props

        const { id, name: productName } = product
        const status = STATUSES_META[product.status.name]
        const image = product.images.filter(image => image.main === 1).length
            ? product.images.filter(image => image.main === 1)[0].path
            : null
        const categoryName = product.category ? product.category.name : ''
        const date = product.requested_at ? moment(product.requested_at).format('YYYY-MM-DD') : null

        return (
            <li key={id}>
                <Link className={cx('product')} to={'/zgloszenia/' + id}>
                    <strong
                        className={cx('status')}
                        style={{
                            backgroundColor: status.backgroundColor,
                            color: status.color
                        }}
                    >
                        {status.label}
                    </strong>
                    {this.renderProductImage(image, productName)}
                    <p className={cx('categoryName')}>{categoryName}</p>
                    <h4 className={cx('productName')}>{productName}</h4>
                    {date && (
                        <div className={cx('footer')}>
                            <p>
                                <IconWatch />
                                <span>{date}</span>
                            </p>
                        </div>
                    )}
                </Link>
            </li>
        )
    }

    renderProductImage = (image, productName) => {
        const { cx } = this.props

        if (image) {
            return (
                <div
                    className={cx('image')}
                    style={{
                        backgroundImage: `url(${image}${IMAGE_DIMENSIONS})`
                    }}
                >
                    {productName}
                </div>
            )
        }

        return (
            <div className={cx('image', 'imageNotAvailable')}>
                <NoImage />
                Brak zdjęć
            </div>
        )
    }

    renderProductListEmpty() {
        const { cx } = this.props
        return (
            <Fragment>
                <section className={cx('emptyList')}>
                    <img
                        src="/assets/icons/papers-add.png"
                        alt="Nie masz jeszcze zgłoszeń."
                    />
                    <h2>Nie masz jeszcze zgłoszeń.</h2>
                </section>
            </Fragment>
        )
    }
}

export default withStyles(styles)(DashboardPage)
