import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import CompanyListPage from 'pages/CompanyListPage'
import CompanyPage from 'pages/CompanyPage'

class CompanyRoutes extends Component {
    render() {
        const { match } = this.props

        return (
            <Switch>
                <Route path={match.path} exact component={CompanyListPage} />
                <Route path={match.path + '/:id'} component={CompanyPage} />
            </Switch>
        )
    }
}

export default CompanyRoutes
