import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { withPermissions } from 'HOC'
import * as actions from 'actions/users'
import Skeleton from 'components/Skeleton'
import SectionLoader from 'components/SectionLoader'
import UserListPageContainer from './UserListPageContainer'

class HasUsersContainer extends Component {
    componentDidMount() {
        const { actions, isAdmin, history } = this.props

        if (!isAdmin) {
            return history.replace('/')
        }

        const params = {
            role: 'salesman'
        }

        actions.readUserCount(params)
    }

    render() {
        const { countResource, ...props } = this.props

        return (
            <Skeleton
                fetchStatus={countResource.fetchStatus}
                component={SectionLoader}
                render={() => {
                    const count = countResource.count

                    return (
                        <UserListPageContainer
                            {...props}
                            hasUsers={!!count}
                            count={count}
                        />
                    )
                }}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        countResource: state.db.users.count
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withPermissions()
)

export default enhance(HasUsersContainer)
