import React from 'react'

import { Modal } from 'components/modal'
import ChangeStatusModal from './ChangeStatusModal'

export default ({ isOpen, handleClose, ...props }) => {
    return (
        <Modal isOpen={isOpen} handleClose={handleClose}>
            <ChangeStatusModal {...props} handleClose={handleClose} />
        </Modal>
    )
}
