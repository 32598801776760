import React from 'react'
import { withAdminDispatcher } from 'HOC'
import HeaderContainer from './HeaderContainer'

const AdminComponent = props => (
    <HeaderContainer {...props} isManufacturer={false} />
)
const NormalComponent = props => (
    <HeaderContainer {...props} isManufacturer={true} />
)

export default withAdminDispatcher()({
    AdminComponent: AdminComponent,
    NormalComponent: NormalComponent
})
