import { combineReducers } from 'redux'

import products from './products'
import dictionaries from './dictionaries'
import categories from './categories'
import users from './users'
import dashboard from './dashboard'

export default combineReducers({
    products,
    dictionaries,
    categories,
    users,
    dashboard
})
