import { combineReducers } from 'redux'
import { fetchStatusHelpers } from '@mindz/react-helpers'

import * as actionTypes from 'constants/actionTypes'

const {
    initialState,
    requestState,
    successState,
    failureState
} = fetchStatusHelpers

const fetchStatusReducer = (state = initialState(), action) => {
    if (actionTypes.FETCH_PRODUCT_COUNT_REQUEST === action.type) {
        return requestState()
    }

    if (actionTypes.FETCH_PRODUCT_COUNT_SUCCESS === action.type) {
        return successState(action)
    }

    if (actionTypes.FETCH_PRODUCT_COUNT_FAILURE === action.type) {
        return failureState(action)
    }

    return state
}

const countReducer = (state = 0, action) => {
    if (actionTypes.FETCH_PRODUCT_COUNT_SUCCESS === action.type) {
        return action.payload.meta.total
    }

    return state
}

export default combineReducers({
    fetchStatus: fetchStatusReducer,
    count: countReducer
})
