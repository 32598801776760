export const FETCH_ACCOUNT_REQUEST = 'FETCH_ACCOUNT_REQUEST'
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS'
export const FETCH_ACCOUNT_FAILURE = 'FETCH_ACCOUNT_FAILURE'

export const PATCH_ACCOUNT_REQUEST = 'PATCH_ACCOUNT_REQUEST'
export const PATCH_ACCOUNT_SUCCESS = 'PATCH_ACCOUNT_SUCCESS'
export const PATCH_ACCOUNT_FAILURE = 'PATCH_ACCOUNT_FAILURE'

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
