import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import idx from 'idx'
import {
    isIosDevice,
    preventDefault,
    isTargetElementTotallyScrolled
} from 'helpers/scroll'

import ArrowLeftIcon from 'assets/arrow_left.module.svg'
import WatchIcon from 'assets/icons/watch.module.svg'
import LoadingIcon from 'assets/icons/loading.module.svg'
import BellActiveIcon from 'assets/bell-active.module.svg'

class Notifications extends Component {
    notificationsRef = React.createRef()
    initialClientY = -1

    componentDidMount() {
        const node = this.notificationsRef.current

        if (isIosDevice && node) {
            node.addEventListener(
                'touchstart',
                event => {
                    if (event.targetTouches.length === 1) {
                        this.initialClientY = event.targetTouches[0].clientY
                    }
                },
                false
            )

            node.addEventListener(
                'touchmove',
                event => {
                    if (event.targetTouches.length === 1) {
                        this.handleScroll(event)
                    }
                },
                false
            )
        }
    }

    handleScroll = event => {
        const node = this.notificationsRef.current
        const clientY = event.targetTouches[0].clientY - this.initialClientY

        if (node && node.scrollTop === 0 && clientY > 0) {
            return preventDefault(event)
        }

        if (isTargetElementTotallyScrolled(node) && clientY < 0) {
            return preventDefault(event)
        }

        event.stopPropagation()
        return true
    }

    render() {
        const {
            cx,
            notifications,
            noNewNotifications,
            loadingNotifications,
            toggleNotifications,
            onScroll,
            onNotificationClick
        } = this.props
        return (
            <Fragment>
                <BellActiveIcon
                    className={cx('bellIcon', { noNewNotifications })}
                    onClick={toggleNotifications}
                />
                <section className={cx('notifications')}>
                    <header onClick={toggleNotifications}>
                        <ArrowLeftIcon className={cx('arrowBack')} />
                        Powiadomienia
                    </header>
                    <div
                        className={cx('list')}
                        onScroll={onScroll}
                        ref={this.notificationsRef}
                    >
                        {notifications.data.map(
                            ({ id, text, read, product, created_at_human }) => {
                                return (
                                    <div
                                        key={id}
                                        className={cx('notification', {
                                            unread: !read
                                        })}
                                        onClick={() =>
                                            onNotificationClick({
                                                notificationId: id,
                                                productId: product.id,
                                                read
                                            })
                                        }
                                    >
                                        <div
                                            className={cx('image')}
                                            style={{
                                                background: `url(${idx(
                                                    product,
                                                    _ => _.image.path
                                                )}?width=100&height=100) no-repeat center / contain`
                                            }}
                                        />
                                        <div className={cx('title')}>
                                            {text}
                                        </div>
                                        <div className={cx('productName')}>
                                            {product.name}
                                        </div>
                                        <div className={cx('timeInfo')}>
                                            <WatchIcon />
                                            <span>{created_at_human}</span>
                                        </div>
                                    </div>
                                )
                            }
                        )}

                        {!!!notifications.data.length && (
                            <div className={cx('noNotifications')}>
                                Brak powiadomień
                            </div>
                        )}

                        {loadingNotifications && (
                            <div className={cx('loader')}>
                                <LoadingIcon />
                                Ładowanie...
                            </div>
                        )}
                    </div>
                </section>
            </Fragment>
        )
    }
}

Notifications.propTypes = {
    cx: PropTypes.func.isRequired,
    notifications: PropTypes.object.isRequired,
    loadingNotifications: PropTypes.bool.isRequired,
    onScroll: PropTypes.func.isRequired,
    onNotificationClick: PropTypes.func.isRequired
}

export default Notifications
