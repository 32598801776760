export const READ_DICTIONARIES_WRAPPINGS_REQUEST =
    'READ_DICTIONARIES_WRAPPINGS_REQUEST'
export const READ_DICTIONARIES_WRAPPINGS_SUCCESS =
    'READ_DICTIONARIES_WRAPPINGS_SUCCESS'
export const READ_DICTIONARIES_WRAPPINGS_FAILURE =
    'READ_DICTIONARIES_WRAPPINGS_FAILURE'

export const READ_DICTIONARIES_COLLECTIVE_WRAPPINGS_REQUEST =
    'READ_DICTIONARIES_COLLECTIVE_WRAPPINGS_REQUEST'
export const READ_DICTIONARIES_COLLECTIVE_WRAPPINGS_SUCCESS =
    'READ_DICTIONARIES_COLLECTIVE_WRAPPINGS_SUCCESS'
export const READ_DICTIONARIES_COLLECTIVE_WRAPPINGS_FAILURE =
    'READ_DICTIONARIES_COLLECTIVE_WRAPPINGS_FAILURE'

export const READ_DICTIONARIES_UNITS_REQUEST = 'READ_DICTIONARIES_UNITS_REQUEST'
export const READ_DICTIONARIES_UNITS_SUCCESS = 'READ_DICTIONARIES_UNITS_SUCCESS'
export const READ_DICTIONARIES_UNITS_FAILURE = 'READ_DICTIONARIES_UNITS_FAILURE'

export const READ_DICTIONARIES_COUNTRIES_REQUEST =
    'READ_DICTIONARIES_COUNTRIES_REQUEST'
export const READ_DICTIONARIES_COUNTRIES_SUCCESS =
    'READ_DICTIONARIES_COUNTRIES_SUCCESS'
export const READ_DICTIONARIES_COUNTRIES_FAILURE =
    'READ_DICTIONARIES_COUNTRIES_FAILURE'
