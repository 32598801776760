import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import idx from 'idx'

import { withPermissions } from 'HOC'
import * as actions from 'actions/products'
import * as dictionariesActions from 'actions/dictionaries'

import SectionLoader from 'components/SectionLoader'
import Skeleton from 'components/Skeleton'
import ProductCreatePage from './ProductCreatePage'
import productModel from './product.model'

export const displayValue = value => {
    if (!value) {
        return ''
    }

    value = isNaN(Number(value)) ? value : Number(value).toString()

    return value
}

class ProductCreatePageContainer extends Component {
    state = {
        initialValues: {},
        prevValues: {},
        isLoading: true,
        isError: false,
        isValidating: false,
        isDisabledValidation: false,
        errors: {},
        isErrorNotification: false,
        images: []
    }

    debounceTimeout = null

    componentDidMount() {
        const { actions, match, history, isManufacturer } = this.props
        const productId = idx(match, _ => _.params.id)

        if (!isManufacturer) {
            return history.replace('/')
        }

        Promise.all([
            actions.readProduct(productId).then(response => {
                this.setInitialValues({ data: response.data })
                this.setState({
                    images: idx(response, _ => _.data.images)
                })
            }),
            actions.readWrappings(),
            actions.readCollectiveWrappings(),
            actions.readUnits(),
            actions.readCountries()
        ])
            .then(() => {
                this.setState({
                    isLoading: false
                })
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    isError: true
                })
            })
    }

    render() {
        const {
            initialValues,
            images,
            isLoading,
            isError,
            errors,
            isErrorNotification,
            isValidating
        } = this.state

        return (
            <Skeleton
                fetchStatus={{
                    isLoading: isLoading,
                    isLoaded: !isLoading,
                    isError: isError
                }}
                component={SectionLoader}
            >
                <ProductCreatePage
                    {...this.props}
                    isLoading={isLoading}
                    isValidating={isValidating}
                    errors={errors}
                    isErrorNotification={isErrorNotification}
                    hideErrorNotification={this.hideErrorNotification}
                    handleLastYearsList={this.handleLastYearsList}
                    handleSubmit={this.handleSubmit}
                    handleOnChange={this.handleOnChange}
                    handleDisableValidation={this.handleDisableValidation}
                    initialValues={initialValues}
                    images={images}
                    getFormikProps={this.getFormikProps}
                    getFormikFieldName={this.getFormikFieldName}
                    handleSetMain={this.handleSetMain}
                    handleRemove={this.handleRemove}
                    handleUploadSuccess={this.handleUploadSuccess}
                    handleUploadFailure={this.handleUploadFailure}
                    handleUploadReject={this.handleUploadReject}
                />
            </Skeleton>
        )
    }

    handleSubmit = (values, formikActions) => {
        const { actions, match, history } = this.props
        const productId = idx(match, _ => _.params.id)

        formikActions.setSubmitting(true)

        actions
            .updateProduct({
                productId,
                values: {
                    ...this.transformFormikStateToRequest({
                        values
                    }),
                    full_validation: 1
                }
            })
            .then(() => {
                this.setState(
                    {
                        isValidating: false,
                        prevValues: values,
                        errors: {}
                    },
                    () => {
                        actions
                            .updateProductStatus(productId, 'new')
                            .then(
                                () => {
                                    history.push('/zgloszenia/' + productId)
                                },
                                responseWithError => {
                                    window.scrollTo(0, 0)
                                    formikActions.setSubmitting(false)
                                    this.getErrors(responseWithError)
                                    this.setState({
                                        isDisabledValidation: false,
                                        isErrorNotification: true
                                    })
                                }
                            )
                            .catch(error => {
                                console.log(error)
                            })
                    }
                )
            })
            .catch(responseWithError => {
                window.scrollTo(0, 0)
                formikActions.setSubmitting(false)
                this.setState({
                    prevValues: values,
                    isDisabledValidation: false,
                    isErrorNotification: true
                })
                this.getErrors(responseWithError)
            })
    }

    handleOnChange = values => {
        const { actions, match } = this.props
        const productId = idx(match, _ => _.params.id)

        if (this.state.isDisabledValidation) {
            return
        }

        if (
            values['product|available_on_market'] === '1' &&
            values['product|available_on_market_from'] === ''
        ) {
            values[
                'product|available_on_market_from'
            ] = new Date().getFullYear()
        }

        values = this.transformFormikStateToRequest({
            values
        })

        if (!values) {
            return false
        }

        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
            clearTimeout(this.debounceTimeout)

            !this.state.isValidating &&
                this.setState(
                    {
                        isValidating: true
                    },
                    () => {
                        actions
                            .updateProduct({
                                productId,
                                values: values
                            })
                            .then(
                                () => {
                                    this.setState({
                                        isValidating: false,
                                        prevValues: values,
                                        errors: {}
                                    })
                                },
                                responseWithError =>
                                    this.getErrors(responseWithError)
                            )
                            .catch(() => {
                                this.setState({
                                    isValidating: false,
                                    prevValues: values,
                                    errors: {}
                                })
                            })
                    }
                )
        }, 600)
    }

    handleDisableValidation = () => {
        this.setState({
            isDisabledValidation: true
        })
    }

    getErrors(responseWithError) {
        if (responseWithError.status === 422) {
            responseWithError.json().then(json => {
                const errors = json.errors
                    ? Object.keys(json.errors).reduce((acc, error) => {
                          acc[
                              error.indexOf('.') !== -1
                                  ? error.replace('.', '|')
                                  : 'product|' + error
                          ] = json.errors[error]
                          return acc
                      }, {})
                    : {}

                this.setState({
                    isValidating: false,
                    errors: errors
                })
            })
        }
    }

    transformFormikStateToRequest = ({ values }) => {
        const { prevValues, errors } = this.state
        let request = {}

        Object.keys(values).forEach(fieldName => {
            const field = fieldName.split('|')

            if (field.length === 2 && !request[field[0]]) {
                request[field[0]] = {}
            }

            if (field.length === 2 && request[field[0]] && values[fieldName]) {
                if (
                    fieldName === 'product|registered_as_brand' &&
                    prevValues['product|registered_as_brand'] === '0' &&
                    values['product|registered_as_brand'] === '1'
                ) {
                    this.setState(prevState => ({
                        prevValues: {
                            ...prevState.prevValues,
                            'product|registered_as_brand': '1'
                        }
                    }))

                    return false
                }

                request[field[0]][field[1]] = values[fieldName]
            } else {
                if (errors[fieldName]) {
                    request[field[0]][field[1]] = values[fieldName]
                }

                if (prevValues[fieldName] && !values[fieldName]) {
                    request[field[0]][field[1]] = values[fieldName]
                }
            }
        })

        const product = request.product ? { ...request.product } : {}
        delete request.product
        delete request.images

        return { ...request, ...product }
    }

    getFormikProps = ({ groupName, fieldName }) => {
        const { errors } = this.state
        const props = idx(productModel, _ => _[groupName][fieldName])
        const name = this.getFormikFieldName({ groupName, fieldName })
        if (!props) {
            return {}
        }
        return {
            ...props,
            error: errors[name]
                ? Array.isArray(errors[name])
                    ? errors[name][0]
                    : errors[name]
                : null,
            name
        }
    }

    getFormikFieldName = ({ groupName, fieldName }) => {
        return groupName + '|' + fieldName
    }

    setInitialValues = ({ data }) => {
        let values = {}

        const isFood = !!idx(data, _ => _.category.food)

        if (!isFood) {
            delete productModel['components']
            delete productModel['nutritions']
        }

        Object.keys(productModel).forEach(groupName => {
            Object.keys(productModel[groupName]).forEach(fieldName => {
                let init = data && data[groupName] && data[groupName][fieldName]
                if (groupName === 'product') {
                    init = data && data[fieldName]
                }
                values[this.getFormikFieldName({ groupName, fieldName })] =
                    displayValue(init) || ''
            })
        })

        values['product|available_on_market'] = values[
            'product|available_on_market_from'
        ]
            ? '1'
            : '0'
        values['product|registered_as_brand'] = values['product|brand_name']
            ? '1'
            : '0'
        this.setState({
            initialValues: values,
            prevValues: values
        })
    }

    handleUploadSuccess = state => {
        const nextFiles = state.files.map(file => file.url)

        this.setState({
            images: this.state.images.concat(nextFiles)
        })
    }

    handleUploadReject = () => {
        console.log('reject')
    }

    handleLastYearsList = range => {
        const currentYear = new Date().getFullYear()
        let startYear = currentYear - range
        const years = []
        while (startYear <= currentYear) {
            years.push(startYear++)
        }

        return years.reverse()
    }

    handleSetMain = ({ id, imageId }) => {
        const { actions } = this.props
        const { images } = this.state

        this.setState(
            {
                images: images.map(image => {
                    return {
                        ...image,
                        main: image.id === imageId ? 1 : 0
                    }
                })
            },
            () => actions.setMainProductImage({ id, imageId })
        )
    }

    handleRemove = ({ id, imageId }) => {
        const { actions } = this.props
        const { images } = this.state
        actions.deleteProductImage({ id, imageId })
        this.setState({
            images: images.filter(image => image.id !== imageId)
        })
    }

    hideErrorNotification = () => {
        this.setState({
            isErrorNotification: false
        })
    }
}

const mapStateToProps = state => {
    return {
        user: state.local.user,
        product: state.db.products.current,
        dictionaries: state.db.dictionaries
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            ...actions,
            ...dictionariesActions
        },
        dispatch
    )
})

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withPermissions()
)

export default enhance(ProductCreatePageContainer)
