export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE'

export const USER_ACTIVATE_REQUEST = 'USER_ACTIVATE_REQUEST'
export const USER_ACTIVATE_SUCCESS = 'USER_ACTIVATE_SUCCESS'
export const USER_ACTIVATE_FAILURE = 'USER_ACTIVATE_FAILURE'

export const USER_REMIND_PASSWORD_REQUEST = 'USER_REMIND_PASSWORD_REQUEST'
export const USER_REMIND_PASSWORD_SUCCESS = 'USER_REMIND_PASSWORD_SUCCESS'
export const USER_REMIND_PASSWORD_FAILURE = 'USER_REMIND_PASSWORD_FAILURE'

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST'
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS'
export const USER_RESET_PASSWORD_FAILURE = 'USER_RESET_PASSWORD_FAILURE'

export const USER_LOGOUT = 'USER_LOGOUT'
