import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'

import styles from './index.module.css'

class FlashMessage extends Component {
    static defaultProps = {
        handleClose: () => {}
    }

    state = {
        visible: true
    }

    render() {
        const { cx, type, title, children } = this.props

        if (!this.state.visible) {
            return null
        }

        return (
            <div className={cx('root', { [type]: !!type })}>
                <span className={cx('icon', { [type]: !!type })} />
                <p className={cx('title')}>{title}</p>
                {!!children && <p className={cx('content')}>{children}</p>}
                <span
                    className={cx('close')}
                    onClick={() => this.toggleVisibility()}
                />
            </div>
        )
    }

    toggleVisibility = () => {
        const { id, handleClose } = this.props

        if (id || id === 0) {
            return handleClose(id)
        }

        this.setState(prevState => ({
            visible: !prevState.visible
        }))
    }
}

export default withStyles(styles)(FlashMessage)
