import { combineReducers } from 'redux'

import collectiveWrappings from './collective-wrappings'
import countries from './countries'
import units from './units'
import wrappings from './wrappings'

export default combineReducers({
    collectiveWrappings,
    countries,
    units,
    wrappings
})
