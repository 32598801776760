import React, { Component } from 'react'

import { withStyles } from '@mindz/react-hoc'
import styles from './index.module.css'

class CategoryListPage extends Component {
    render() {
        return <div>Lista kategorii</div>
    }
}

export default withStyles(styles)(CategoryListPage)
