import React, { Component } from 'react'
import classNames from 'classnames/bind'
import { withStyles } from '@mindz/react-hoc'

import { LANDING_PAGE_HOST } from 'env'
import styles from './index.module.css'
import sidebarStyles from '../Sidebar/index.module.css'
import contentStyles from '../Content/index.module.css'

const sidebarCx = classNames.bind(sidebarStyles)
const contentCx = classNames.bind(contentStyles)

class Header extends Component {
    render() {
        const { cx } = this.props

        return (
            <div className={cx('header')}>
                <div
                    className={[sidebarCx('sidebar'), cx('sidebar')].join(' ')}
                >
                    <div className={cx('logo')}>
                        <a href={LANDING_PAGE_HOST}>
                            <img
                                src="/assets/logo.png"
                                alt="Startup by Kaufland"
                            />
                        </a>
                    </div>
                </div>
                <div
                    className={[contentCx('content'), cx('content')].join(' ')}
                >
                    <div
                        className={[contentCx('inner'), cx('inner')].join(' ')}
                    >
                        {this.renderHeader()}
                    </div>
                </div>
            </div>
        )
    }

    renderHeader() {
        const { cx, render, children } = this.props

        if (render) {
            return render({ cx })
        }

        if (typeof children === 'function') {
            return children
        }

        return null
    }
}

export default withStyles(styles)(Header)
