import { combineReducers } from 'redux'

import * as actionTypes from 'constants/actionTypes'

export const filtersReducer = (state = {}, action) => {
    if (actionTypes.UPDATE_PRODUCT_LIST_FILTERS === action.type) {
        return {
            ...state,
            ...action.payload
        }
    }

    return state
}

export default combineReducers({
    filters: filtersReducer,
})
