import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import every from 'lodash.every'

import * as actions from 'actions/notifications'

import Skeleton from 'components/Skeleton'
import Notifications from './Notifications'

class NotificationsContainer extends Component {
    state = {
        loadingNotifications: false
    }

    componentDidMount() {
        const { actions } = this.props

        actions.fetchNotifications()
    }

    render() {
        const { notifications } = this.props

        return (
            <Skeleton fetchStatus={notifications.fetchStatus}>
                <Notifications
                    {...this.props}
                    loadingNotifications={this.state.loadingNotifications}
                    onScroll={this.onNotificationsScroll}
                    onNotificationClick={this.onNotificationClick}
                />
            </Skeleton>
        )
    }

    onNotificationsScroll = e => {
        const { actions, notifications } = this.props
        const { pages } = notifications
        const { scrollTop, clientHeight, scrollHeight } = e.target
        const distanceToEndOfList = scrollHeight - clientHeight - scrollTop

        if (
            !this.loadingNotifications &&
            distanceToEndOfList < 10 &&
            pages.current < pages.last
        ) {
            this.loadingNotifications = true
            this.setState({ loadingNotifications: true }, () => {
                actions
                    .fetchNotifications({ page: pages.current + 1 })
                    .then(() => {
                        this.loadingNotifications = false
                        this.setState({ loadingNotifications: false })
                    })
            })
        }
    }

    onNotificationClick = ({ notificationId, productId, read }) => {
        const { actions, history, toggleNotifications } = this.props

        toggleNotifications()

        if (!read) {
            return actions
                .tagNotificationAsRead(notificationId)
                .then(() => history.push(`/zgloszenia/${productId}`))
                .catch(() => history.push(`/zgloszenia/${productId}`))
        }

        history.push(`/zgloszenia/${productId}`)
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.local.notifications,
        noNewNotifications: every(state.local.notifications.data, 'read')
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

NotificationsContainer.propTypes = {
    cx: PropTypes.func.isRequired,
    toggleNotifications: PropTypes.func.isRequired
}

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)

export default enhance(NotificationsContainer)
