import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withPermissions } from 'HOC'
import idx from 'idx'

import * as actions from 'actions/companies'

import CompanyListPage from './CompanyListPage'
import FlashMessageContainer from 'components/FlashMassageContainer'

import flashMessageContainerStyles from './overrides/FlashMessageContainer.module.css'

class CompanyListPageContainer extends Component {
    state = {
        filters: {
            query: '',
            sort_by: 'company.name',
            sort_direction: 'asc'
        }
    }

    componentDidMount() {
        const { actions, history, isManufacturer } = this.props
        const { filters } = this.state

        if (isManufacturer) {
            return history.push('/')
        }

        actions
            .fetchCompanies({ ...filters })
            .then(res => {
                if (idx(res, _ => _.data.length)) {
                    this.areAnyCompanies = true
                }
            })
            .catch(response => {
                if (response.status === 403) {
                    history.push('/zgloszenia')
                }
            })
    }

    render() {
        return (
            <Fragment>
                <FlashMessageContainer
                    customStyles={flashMessageContainerStyles}
                />
                <CompanyListPage
                    {...this.props}
                    filters={this.state.filters}
                    areAnyCompanies={this.areAnyCompanies}
                    handleChangeQuery={this.handleChangeQuery}
                    handleSubmitQuery={this.handleSubmitQuery}
                    handleChangeSortBy={this.handleChangeSortBy}
                    handleLoadMore={this.handleLoadMore}
                    onDetailsClick={this.onDetailsClick}
                />
            </Fragment>
        )
    }

    handleChangeQuery = query => {
        this.setState({
            filters: {
                ...this.state.filters,
                query
            }
        })
    }

    handleChangeSortBy = sort_by => {
        const { actions } = this.props

        this.setState(
            {
                filters: {
                    ...this.state.filters,
                    sort_by
                }
            },
            () => {
                actions.fetchCompanies({ ...this.state.filters, page: 1 })
            }
        )
    }

    handleSubmitQuery = query => {
        const { actions } = this.props
        const { filters } = this.state

        actions.fetchCompanies({ ...filters, query, page: 1 })
    }

    handleLoadMore = () => {
        const { filters } = this.state
        const {
            actions,
            companies: {
                meta: { last, current }
            }
        } = this.props

        if (current < last && !this.fetchingNewPage) {
            this.fetchingNewPage = true
            actions
                .fetchCompaniesNextPage({ ...filters, page: current + 1 })
                .then(() => {
                    this.fetchingNewPage = false
                })
                .catch(err => {
                    this.fetchingNewPage = false
                })
        }
    }

    onDetailsClick = companyId => {
        this.props.history.push(`/firmy/${companyId}`)
    }
}

const mapStateToProps = state => {
    return {
        companies: state.local.companies
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withPermissions()
)

export default enhance(CompanyListPageContainer)
