import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { withPermissions } from 'HOC'
import * as actions from 'actions/products'
import ProductStatus from './ProductStatus'
import ChangeStatusModal from '../ChangeStatusModal'

class ProductStatusContainer extends Component {
    state = {
        isCollapsed: true,
        changeStatusModalIsOpen: false,
        changeStatusModalIsSubmitting: false,
        changeStatusModalNextStatus: null,
        changeStatusModalResponseStatus: null
    }

    get product() {
        return this.props.product
    }

    get id() {
        return this.product.id
    }

    render() {
        const { product, isManufacturer } = this.props
        const {
            isCollapsed,
            changeStatusModalIsOpen,
            changeStatusModalIsSubmitting,
            changeStatusModalNextStatus,
            changeStatusModalResponseStatus
        } = this.state

        return (
            <Fragment>
                <ProductStatus
                    {...this.props}
                    product={product}
                    isCollapsed={isCollapsed}
                    status={this.status}
                    isManufacturer={isManufacturer}
                    toggleChangeStatusModal={this.toggleChangeStatusModal}
                    handleToggle={this.handleToggle}
                />
                <ChangeStatusModal
                    isOpen={changeStatusModalIsOpen}
                    isManufacturer={isManufacturer}
                    nextStatus={changeStatusModalNextStatus}
                    isSubmitting={changeStatusModalIsSubmitting}
                    responseStatus={changeStatusModalResponseStatus}
                    handleSubmit={this.handleChangeStatus}
                    handleClose={this.toggleChangeStatusModal}
                />
            </Fragment>
        )
    }

    get status() {
        return this.product.status.name
    }

    handleChangeStatus = () => {
        const { actions } = this.props
        const { changeStatusModalNextStatus: status } = this.state

        this.setState({ changeStatusModalIsSubmitting: true })

        actions.updateProductStatus(this.id, status).catch(response => {
            this.setState({
                changeStatusModalIsSubmitting: false,
                changeStatusModalResponseStatus: response.status
            })
        })
    }

    handleToggle = () =>
        this.setState(prevState => ({
            isCollapsed: !prevState.isCollapsed
        }))

    toggleChangeStatusModal = (nextStatus = null) =>
        this.setState(prevState => ({
            changeStatusModalIsOpen: !prevState.changeStatusModalIsOpen,
            changeStatusModalIsSubmitting: false,
            changeStatusModalNextStatus: nextStatus,
            changeStatusModalResponseStatus: null
        }))
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

const enhance = compose(
    connect(
        null,
        mapDispatchToProps
    ),
    withPermissions()
)

export default enhance(ProductStatusContainer)
