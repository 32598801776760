import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { withPermissions } from 'HOC'
import * as actions from 'actions/products'
import Skeleton from 'components/Skeleton'
import SectionLoader from 'components/SectionLoader'
import ProductListPageContainer from './ProductListPageContainer'

class HasProductsContainer extends Component {
    componentDidMount() {
        const { actions } = this.props

        const params = {
            length: 1
        }

        actions.fetchProductCount(params)
    }

    render() {
        const { products, ...props } = this.props

        return (
            <Skeleton
                fetchStatus={products.fetchStatus}
                component={SectionLoader}
                render={() => (
                    <ProductListPageContainer
                        {...props}
                        hasProducts={!!products.count}
                        count={products.count}
                    />
                )}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        products: state.db.products.count
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withPermissions()
)

export default enhance(HasProductsContainer)
