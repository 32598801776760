import AuthLayout from './AuthLayout'
import Header from './Header'
import Main from './Main'
import Sidebar from './Sidebar'
import Content from './Content'

AuthLayout.Main = Main
AuthLayout.Header = Header
AuthLayout.Sidebar = Sidebar
AuthLayout.Content = Content

export default AuthLayout
