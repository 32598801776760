import { CALL_API } from 'middlewares/api'

import * as actionTypes from 'constants/actionTypes/companies'
import { urlHelpers } from '@mindz/react-helpers'

const { objToQueryString } = urlHelpers
const defaultParams = {
    query: '',
    sort_by: '',
    sort_direction: '',
    page: 1,
    length: 10
}

export const fetchCompanies = (params = defaultParams) => dispatch => {
    const omitEmpty = true
    const queryStr = objToQueryString({ ...defaultParams, ...params }, omitEmpty)

    return dispatch({
        [CALL_API]: {
            endpoint: `/companies?${queryStr}`,
            types: [
                actionTypes.FETCH_COMPANIES_REQUEST,
                actionTypes.FETCH_COMPANIES_SUCCESS,
                actionTypes.FETCH_COMPANIES_FAILURE
            ]
        }
    })
}


export const fetchCompaniesNextPage = (params = defaultParams) => dispatch => {
    const omitEmpty = true
    const queryStr = objToQueryString({ ...defaultParams, ...params }, omitEmpty)

    return dispatch({
        [CALL_API]: {
            endpoint: `/companies?${queryStr}`,
            types: [
                actionTypes.FETCH_COMPANIES_NEXT_REQUEST,
                actionTypes.FETCH_COMPANIES_NEXT_SUCCESS,
                actionTypes.FETCH_COMPANIES_NEXT_FAILURE
            ]
        }
    })
}

export const fetchCompany = id => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: `/companies/${id}`,
            types: [
                actionTypes.FETCH_COMPANY_REQUEST,
                actionTypes.FETCH_COMPANY_SUCCESS,
                actionTypes.FETCH_COMPANY_FAILURE
            ]
        }
    })
}
