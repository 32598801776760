import React, { Component } from 'react'
import { Formik } from 'formik'
import { withStyles } from '@mindz/react-hoc'
import { Field } from '@mindz/react-admin-ui'

import { Modal } from 'components/modal'
import { Button, InputField } from 'components/forms'

import styles from './index.module.css'
import dialogStyles from './overrides/Dialog.module.css'
import buttonStyles from './overrides/Button.module.css'

class RemindPasswordModal extends Component {
    render() {
        const { cx, isOpen, handleSubmit, handleClose } = this.props
        return (
            <Modal isOpen={isOpen} handleClose={handleClose} >
                <Modal.Dialog customStyles={dialogStyles}>
                    <Modal.Header handleClose={handleClose}>
                        <Modal.Title>Odzyskaj hasło</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                email: ''
                            }}
                            validateOnChange={false}
                            onSubmit={handleSubmit}
                            render={({
                                isSubmitting,
                                handleSubmit,
                                errors
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <Field
                                                    type="text"
                                                    name="email"
                                                    headerLabel="Twój e-mail"
                                                    placeholder=""
                                                    error={errors.email}
                                                    component={InputField}
                                                />
                                            </div>
                                        </div>
                                        <div className={cx('actions')}>
                                            <Button
                                                type="button"
                                                size="m-size"
                                                theme="white"
                                                customStyles={buttonStyles}
                                                className={cx('grow0')}
                                                onClick={handleClose}
                                            >
                                                Anuluj
                                            </Button>
                                            <Button
                                                type="submit"
                                                size="m-size"
                                                customStyles={buttonStyles}
                                                className={cx('grow1')}
                                                isLoading={isSubmitting}
                                            >
                                                Dalej
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        />
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        )
    }
}

export default withStyles(styles)(RemindPasswordModal)
