import React, { Component, Fragment } from 'react'
import idx from 'idx'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { withStyles } from '@mindz/react-hoc'

import { STATUSES_META } from 'constants/product'
import Skeleton from 'components/Skeleton'
import Table from 'components/Table'
import { Button } from 'components/forms'
import SectionLoader from 'components/SectionLoader'
import InfiniteScroll from 'components/InfiniteScroll'
import IconLoading from 'assets/icons/loading.module.svg'
import IconArrow from 'assets/arrow_left.module.svg'
import IconProduct from 'assets/product.module.svg'
import IconWatch from 'assets/icons/watch.module.svg'
import styles from './index.module.css'
import NoImage from 'assets/no-image.module.svg'

const IMAGE_DIMENSIONS = '?width=185&height=185'

class CompanyPage extends Component {
    render() {
        const { cx, company } = this.props

        return (
            <div className={cx('root')}>
                <Link className={cx('goBack')} to="/firmy">
                    <IconArrow />
                    <span>Wróć do firm</span>
                </Link>
                <Skeleton
                    fetchStatus={company.fetchStatus}
                    component={SectionLoader}
                    render={this.renderContent}
                />
            </div>
        )
    }

    renderContent = () => {
        const { cx, company } = this.props

        return (
            <Fragment>
                <h2>{idx(company, _ => _.data.name)}</h2>
                <div className={cx('company', 'clearfix')}>
                    {this.renderRequestSection()}
                    {this.renderCompanyInfo()}
                    {this.renderContactInfo()}
                </div>
                {this.renderLastProducts()}
            </Fragment>
        )
    }

    renderRequestSection = () => {
        const { cx, company } = this.props
        const requests = idx(company, _ => _.data.requests) || {}

        return (
            <section className={cx('requests')}>
                <div className={cx('count')}>
                    <IconProduct />
                    <span>{requests.all}&nbsp;</span>zgłoszeń
                </div>
                <div className={cx('type', 'new')}>
                    <span className={cx('circle')}>{requests.new}</span>
                    <span className={cx('status')}>
                        Oczekujące na weryfikację
                    </span>
                </div>
                <div className={cx('type', 'accepted')}>
                    <span className={cx('circle')}>{requests.accepted}</span>
                    <span className={cx('status')}>
                        Zweryfikowane pozytywnie
                    </span>
                </div>
                <div className={cx('type', 'rejected')}>
                    <span className={cx('circle')}>{requests.rejected}</span>
                    <span className={cx('status')}>Brak akceptacji</span>
                </div>
            </section>
        )
    }

    renderCompanyInfo = () => {
        const { company } = this.props

        return (
            <section>
                <h3>Informacje o firmie</h3>
                <Table>
                    <Table.Content>
                        <tr>
                            <td>Rok założenia firmy</td>
                            <td>{idx(company, _ => _.data.established)}</td>
                        </tr>
                    </Table.Content>
                </Table>

                <h4>Profil / historia działalności</h4>
                <p>{idx(company, _ => _.data.description)}</p>
            </section>
        )
    }

    renderContactInfo = () => {
        const { company } = this.props
        const manufacturer = idx(company, _ => _.data.manufacturer) || {}

        return (
            <section>
                <h3>Osoba kontaktowa</h3>
                <Table>
                    <Table.Content>
                        <tr>
                            <td>Imię i nazwisko</td>
                            <td>
                                {manufacturer.first_name}{' '}
                                {manufacturer.last_name}
                            </td>
                        </tr>
                        <tr>
                            <td>E-mail</td>
                            <td>{manufacturer.email}</td>
                        </tr>
                        <tr>
                            <td>Telefon</td>
                            <td>{manufacturer.phone}</td>
                        </tr>
                    </Table.Content>
                </Table>
            </section>
        )
    }

    renderLastProducts = () => {
        const {
            cx,
            company,
            onLoadRestRequestsClick,
            handleLoadMore,
            showLoadAllButton,
            products
        } = this.props
        const list =
            company.data.requests && company.data.requests.last
                ? company.data.requests.last
                : []

        if (!list.length) {
            return null
        }

        return (
            <div className={cx('products')}>
                <h3>Ostatnie zgłoszenia</h3>
                <InfiniteScroll
                    loadMore={handleLoadMore}
                    hasMore={
                        !showLoadAllButton &&
                        products.meta.current_page < products.meta.pages
                    }
                    loader={
                        <div className={cx('loader')} key={0}>
                            <IconLoading />
                            <span>Ładowanie...</span>
                        </div>
                    }
                >
                    <ul>
                        {list.map(product => this.renderProduct(product))}
                        {products.data.map(product =>
                            this.renderProduct(product)
                        )}
                    </ul>
                </InfiniteScroll>
                {showLoadAllButton && (
                    <div style={{ textAlign: 'center', marginBottom: 32 }}>
                        <Button
                            theme="white"
                            size="m-size"
                            onClick={onLoadRestRequestsClick}
                        >
                            Pokaż wszystkie
                        </Button>
                    </div>
                )}
            </div>
        )
    }

    renderProduct = product => {
        const { cx } = this.props

        const { id, name: productName } = product
        const status =
            STATUSES_META[product.status.name || product.status.status]

        const image =
            product.images && product.images.length
                ? product.images[0].path
                : product.image && product.image.path
                ? product.image.path
                : null

        const categoryName = product.category ? product.category.name : ''
        const date = product.requested_at
            ? moment(product.requested_at).format('YYYY-MM-DD')
            : null

        return (
            <li key={id}>
                <Link className={cx('product')} to={'/zgloszenia/' + id}>
                    <strong
                        className={cx('status')}
                        style={{
                            backgroundColor: status.backgroundColor,
                            color: status.color
                        }}
                    >
                        {status.label}
                    </strong>
                    {this.renderProductImage(image, productName)}
                    <p className={cx('categoryName')}>{categoryName}</p>
                    <h4 className={cx('productName')}>{productName}</h4>
                    {date && (
                        <div className={cx('footer')}>
                            <p>
                                <IconWatch />
                                <span>{date}</span>
                            </p>
                        </div>
                    )}
                </Link>
            </li>
        )
    }

    renderProductImage = (image, productName) => {
        const { cx } = this.props
        if (image) {
            return (
                <div
                    className={cx('image')}
                    style={{
                        backgroundImage: `url(${image}${IMAGE_DIMENSIONS})`
                    }}
                >
                    {productName}
                </div>
            )
        }

        return (
            <div className={cx('image', 'imageNotAvailable')}>
                <NoImage />
                Brak zdjęć
            </div>
        )
    }
}

export default withStyles(styles)(CompanyPage)
