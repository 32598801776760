import React, { Component } from 'react'

import { withStyles } from '@mindz/react-hoc'
import styles from './index.module.css'

class Content extends Component {
    render() {
        const { cx, children } = this.props

        return (
            <div className={cx('content')}>
                {this.renderMessage()}
                {this.renderIcon()}
                {this.renderHeader()}
                {children}
            </div>
        )
    }

    renderMessage() {
        const { message: Message } = this.props

        if (Message) {
            return <Message />
        }

        return null
    }

    renderIcon() {
        const { icon } = this.props

        if (icon) {
            return <img src={icon} alt="" />
        }

        return null
    }

    renderHeader() {
        const { title, subtitle, description } = this.props

        if (!title && !subtitle && !description) {
            return null
        }

        return (
            <header>
                {this.renderSubtitle()}
                {this.renderTitle()}
                {this.renderDescription()}
            </header>
        )
    }

    renderSubtitle() {
        const { subtitle } = this.props

        if (!subtitle) {
            return null
        }

        return <h6>{subtitle}</h6>
    }

    renderTitle() {
        const { title } = this.props

        if (!title) {
            return null
        }

        return <h2>{title}</h2>
    }

    renderDescription() {
        const { description } = this.props

        if (!description) {
            return null
        }

        return <p>{description}</p>
    }
}

export default withStyles(styles)(Content)
