import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import AccountPage from 'pages/AccountPage'
import AccountPageEdit from 'pages/AccountPageEdit'

class AccountRoutes extends Component {
    render() {
        const { match } = this.props

        return (
            <Switch>
                <Route path={match.path} exact component={AccountPage} />
                <Route
                    path={match.path + '/edytuj'}
                    component={AccountPageEdit}
                />
            </Switch>
        )
    }
}

export default AccountRoutes
