export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST'
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS'
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE'

export const FETCH_COMPANIES_NEXT_REQUEST = 'FETCH_COMPANIES_NEXT_REQUEST'
export const FETCH_COMPANIES_NEXT_SUCCESS = 'FETCH_COMPANIES_NEXT_SUCCESS'
export const FETCH_COMPANIES_NEXT_FAILURE = 'FETCH_COMPANIES_NEXT_FAILURE'

export const FETCH_COMPANY_REQUEST = 'FETCH_COMPANY_REQUEST'
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS'
export const FETCH_COMPANY_FAILURE = 'FETCH_COMPANY_FAILURE'
