import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import UserListPage from 'pages/UserListPage'
import UserPage from 'pages/UserPage'

class UserRoutes extends Component {
    render() {
        const { match } = this.props

        return (
            <Switch>
                <Route path={match.path} exact component={UserListPage} />
                <Route path={match.path + '/utworz'} component={UserPage} />
                <Route path={match.path + '/:id'} component={UserPage} />
            </Switch>
        )
    }
}

export default UserRoutes
