import { API } from 'env'

const API_HOST = API.baseUrl

export const CALL_API = 'Call API'

export const remoteEndpoint = endpoint => API_HOST + endpoint

export const checkStatus = response => {
    if (response.ok) {
        return response
    }

    return Promise.reject(response)
}

export const callApi = (endpoint, options = {}) => {
    const defaultOptions = {
        method: 'GET',
        headers: {
            ...API.authorizationHeaders,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }

    options = {
        ...defaultOptions,
        ...options,
        headers: {
            ...defaultOptions.headers,
            ...options.headers
        }
    }

    return new Promise((resolve, reject) => {
        fetch(remoteEndpoint(endpoint), options)
            .then(checkStatus)
            .then(
                response => {
                    const contentType = response.headers.get('content-type')

                    if (response.status === 200 || response.status === 201) {
                        if (
                            contentType &&
                            contentType.includes('application/json')
                        ) {
                            return response
                                .json()
                                .then(json => resolve({ response, json }))
                                .catch(() => resolve({ response }))
                        }

                        return resolve({ response })
                    }

                    if (response.status === 204) {
                        return resolve({ response })
                    }

                    reject({ response })
                },
                response => {
                    if (response.status === 401) {
                        return reject({ response })
                    }

                    if (response.status === 403) {
                        return reject({ response })
                    }

                    if (response.status === 404) {
                        return reject({ response })
                    }

                    if (response.status === 422) {
                        return reject({ response })
                    }

                    if (response.status === 429) {
                        return reject({ response })
                    }

                    if (response.status === 500) {
                        return reject({ response })
                    }

                    if (!response.status) {
                        return reject({ response })
                    }
                }
            )
            .catch(error => {})
    })
}

export default store => next => action => {
    const { [CALL_API]: callAPI, ...restAction } = action

    if (typeof callAPI === 'undefined') {
        return next(action)
    }

    const { types, payload, options } = callAPI
    let { endpoint } = callAPI

    if (typeof endpoint === 'function') {
        endpoint = endpoint(store.getState())
    }

    if (typeof endpoint !== 'string') {
        throw new Error('Specify a string endpoint URL.')
    }

    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three action types.')
    }

    if (!types.every(type => typeof type === 'string')) {
        throw new Error('Expected action types to be strings.')
    }

    const actionWith = data => {
        return {
            ...restAction,
            ...data,
            payload: Array.isArray(data.payload)
                ? {
                      ...payload,
                      ...restAction.payload,
                      data: data.payload
                  }
                : {
                      ...payload,
                      ...restAction.payload,
                      ...data.payload
                  }
        }
    }

    const [requestType, successType, failureType] = types
    next(actionWith({ type: requestType, payload }))

    return new Promise((resolve, reject) => {
        callApi(endpoint, options)
            .then(handleSuccess(resolve), handleFailure(reject))
            .catch(response => {})
    })

    function handleSuccess(resolve) {
        return ({ response, json } = { json: null }) => {
            next(
                actionWith({
                    type: successType,
                    payload: json,
                    response
                })
            )

            resolve(json)
        }
    }

    function handleFailure(reject) {
        return ({ response, json } = { json: null }) => {
            if (response.status === 401) {
                return (window.location.href = '/wyloguj')
            }

            next(
                actionWith({
                    type: failureType,
                    payload,
                    error: response,
                    response
                })
            )

            reject(response)
        }
    }
}
