import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@mindz/react-hoc'

import { Button, DebounceInputField } from 'components/forms'
import Skeleton from 'components/Skeleton'
import SubHeader from 'components/SubHeader'
import SectionLoader from 'components/SectionLoader'
import UserList from './UserList'
import ConfirmModal from './ConfirmDeleteModal'

import IconCirclePlus from 'assets/icons/circle-plus.module.svg'
import styles from './index.module.css'

import buttonStyles from './overrides/Button.module.css'

class UserListPage extends Component {
    render() {
        const { cx } = this.props

        return <section className={cx('root')}>{this.renderList()}</section>
    }

    renderList() {
        const {
            users,
            handleLoadMore,
            handleDelete,
            isOpenModal,
            toggleOpenModal,
            deletingUserId,
            isSubmittingDelete
        } = this.props

        return (
            <Fragment>
                {this.renderHeader()}
                <Skeleton
                    fetchStatus={users.fetchStatus}
                    component={SectionLoader}
                    render={() => (
                        <UserList
                            items={users.data}
                            meta={users.meta}
                            handleLoadMore={handleLoadMore}
                            handleDelete={handleDelete}
                            toggleOpenModal={toggleOpenModal}
                        />
                    )}
                />
                <ConfirmModal
                    isOpen={isOpenModal}
                    handleClose={toggleOpenModal}
                    deletingUserId={deletingUserId}
                    handleSubmit={handleDelete}
                    isSubmitting={isSubmittingDelete}
                />
            </Fragment>
        )
    }

    renderHeader() {
        const { cx, users, handleChangeQuery, handleSubmitQuery } = this.props

        return (
            <SubHeader
                title="Użytkownicy"
                count={users.meta.total}
                className={cx('header')}
            >
                <div className={cx('actionHolder')}>
                    <Link to="/uzytkownicy/utworz">
                        <Button
                            size="m-size"
                            icon={IconCirclePlus}
                            customStyles={buttonStyles}
                            className="upper"
                        >
                            Dodaj użytkownika
                        </Button>
                    </Link>
                </div>
                <div className={cx('searchHolder')}>
                    <div>
                        <DebounceInputField
                            name="search"
                            placeholder="Szukaj użytkownika"
                            search
                            value={users.filters.query}
                            handleChange={handleChangeQuery}
                            handleDebounceChange={handleSubmitQuery}
                        />
                    </div>
                </div>
            </SubHeader>
        )
    }
}

export default withStyles(styles)(UserListPage)
