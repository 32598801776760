import * as yup from 'yup'

const basicShape = {
    email: yup
        .string()
        .email('Niepoprawna forma adresu email')
        .required('To pole jest wymagane'),
    first_name: yup.string().required('To pole jest wymagane'),
    last_name: yup.string().required('To pole jest wymagane')
}

export default function(isManufacturer = false) {
    const shape = isManufacturer
        ? {
              ...basicShape,
              phone: yup
                  .number()
                  .min(100000000, ({ value }) =>
                      value < 0
                          ? 'Niepoprawna froma numeru telefonu'
                          : 'Numer musi zawierać co najmniej 9 znaków'
                  )
                  .max(9999999999, 'Za długi numer telefonu')
                  .integer('Niepoprawna froma numeru telefonu')
                  .required('To pole jest wymagane')
                  .typeError('Pole musi zawierać tylko cyfry'),
              company: yup.object().shape({
                  name: yup.string().required('To pole jest wymagane')
              })
          }
        : basicShape

    return yup.object().shape(shape)
}
