import { CALL_API } from 'middlewares/api'
import idx from 'idx'

import * as actionTypes from 'constants/actionTypes/account'
import { fetchStatusHelpers } from '@mindz/react-helpers'

const { shouldFetch } = fetchStatusHelpers

export const fetchAccount = () => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/account',
            types: [
                actionTypes.FETCH_ACCOUNT_REQUEST,
                actionTypes.FETCH_ACCOUNT_SUCCESS,
                actionTypes.FETCH_ACCOUNT_FAILURE
            ]
        }
    })
}

export const fetchAccountIfNeeded = () => (dispatch, getState) => {
    const account = idx(getState(), _ => _.local.account)

    if (shouldFetch(account)) {
        return dispatch(fetchAccount())
    } else {
        return Promise.resolve()
    }
}

export const patchAccount = (values) => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/account',
            options: {
                method: 'PATCH',
                body: JSON.stringify(values)
            },
            types: [
                actionTypes.PATCH_ACCOUNT_REQUEST,
                actionTypes.PATCH_ACCOUNT_SUCCESS,
                actionTypes.PATCH_ACCOUNT_FAILURE
            ]
        }
    })
}

export const changePassword = ({ oldPassword, newPassword }) => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/account/change-password',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    old_password: oldPassword,
                    new_password: newPassword
                })
            },
            types: [
                actionTypes.CHANGE_PASSWORD_REQUEST,
                actionTypes.CHANGE_PASSWORD_SUCCESS,
                actionTypes.CHANGE_PASSWORD_FAILURE
            ]
        }
    })
}
