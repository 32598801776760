export default {
    product: {
        name: {
            headerLabel: 'Nazwa produktu',
            placeholder: ''
        },
        available_on_market: {},
        available_on_market_from: {
            headerLabel: '',
            placeholder: 'Wpisz rok',
            unit: 'r.'
        },
        images: [],
        registered_as_brand: {},
        brand_name: {
            headerLabel: '',
            placeholder: 'Wpisz nazwę marki'
        },
        description: {
            headerLabel: 'Opis produktu',
            placeholder: 'Napisz krótko o produkcie'
        },
        components: {
            headerLabel: 'Składniki',
            placeholder: ''
        },
        netto_price: {
            headerLabel: 'Cena netto sprzedaży w Kauflandzie',
            placeholder: ''
        }
    },
    nutritions: {
        energy_value: {
            headerLabel: 'Wartość energetyczna',
            unit: 'kcal',
            placeholder: ''
        },
        fat: {
            headerLabel: 'Tłuszcz',
            unit: 'g',
            placeholder: ''
        },
        saturated_fat: {
            headerLabel: '- w tym tłuszcze nasycone',
            unit: 'g',
            placeholder: ''
        },
        carbohydrate: {
            headerLabel: 'Węglowodany',
            unit: 'g',
            placeholder: ''
        },
        sugar: {
            headerLabel: '- w tym cukry',
            unit: 'g',
            placeholder: ''
        },
        roughage: {
            headerLabel: 'Błonnik',
            unit: 'g',
            placeholder: ''
        },
        protein: {
            headerLabel: 'Białko',
            unit: 'g',
            placeholder: ''
        },
        salt: {
            headerLabel: 'Sól',
            unit: 'g',
            placeholder: ''
        }
    },
    wrapping: {
        kind: {
            headerLabel: 'Rodzaj opakowania',
            placeholder: '- Wybierz -'
        },
        value: {
            headerLabel: 'Waga / pojemność',
            placeholder: ''
        },
        unit: {
            headerLabel: 'Jednostka',
            placeholder: '--'
        },
        weight: {
            headerLabel: '',
            placeholder: ''
        },
        length: {
            headerLabel: 'Długość',
            placeholder: '',
            unit: 'cm'
        },
        width: {
            headerLabel: 'Szerokość',
            placeholder: '',
            unit: 'cm'
        },
        height: {
            headerLabel: 'Wysokość',
            placeholder: '',
            unit: 'cm'
        }
    },
    collective_wrapping: {
        kind: {
            headerLabel: 'Rodzaj opakowania',
            placeholder: '- Wybierz -'
        },
        product_count: {
            headerLabel: 'Liczba sztuk produktu',
            placeholder: ''
        },
        weight: {
            headerLabel: 'Waga',
            placeholder: '',
            unit: 'kg'
        },
        length: {
            headerLabel: 'Długość',
            placeholder: '',
            unit: 'cm'
        },
        width: {
            headerLabel: 'Szerokość',
            placeholder: '',
            unit: 'cm'
        },
        height: {
            headerLabel: 'Wysokość',
            placeholder: '',
            unit: 'cm'
        }
    },
    informations: {
        country: {
            headerLabel: 'Kraj pochodzenia',
            placeholder: ''
        },
        city: {
            headerLabel: 'Miejscowość produkcji',
            placeholder: ''
        },
        created_since: {
            headerLabel: 'Rok powstania produktu',
            placeholder: '- Wybierz -'
        }
    }
}
