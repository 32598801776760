import React, { Component } from 'react'

import { Link } from 'react-router-dom'
import { withStyles } from '@mindz/react-hoc'
import styles from './index.module.css'
import IconEdit from 'assets/icons/edit.module.svg'
import IconLock from 'assets/icons/lock.module.svg'
import IconArrow from 'assets/arrow_down.module.svg'

import ChangePasswordModal from './components/ChangePasswordModal'
import Table from 'components/Table'

class AccountPage extends Component {
    state = {
        shownIdSubcategories: null
    }
    render() {
        const {
            match,
            isManufacturer,
            isSalesman,
            account: {
                data: { first_name, last_name, email, phone }
            },
            isModalOpen,
            closeModal,
            showModal,
            handleSubmitChangePassword,
            cx
        } = this.props

        return (
            <div className={cx('root')}>
                <h2>Moje konto</h2>

                <div className={cx('content')}>
                    <section className={cx('actions')}>
                        <div className={cx('avatar')}>
                            {first_name &&
                                last_name &&
                                `${first_name[0]}${last_name[0]}`}
                        </div>
                        <Link
                            className={cx('action')}
                            to={match.path + '/edytuj'}
                        >
                            <IconEdit />
                            Edytuj dane
                        </Link>
                        <span className={cx('action')} onClick={showModal}>
                            <IconLock />
                            Zmień hasło
                        </span>
                    </section>

                    <div className={cx('summary')}>
                        <section>
                            <h3>
                                Dane
                                {isManufacturer
                                    ? ' kontaktowe'
                                    : isSalesman
                                    ? ' personalne'
                                    : ''}
                            </h3>

                            <Table>
                                <Table.Content>
                                    <tr>
                                        <td>E-mail:</td>
                                        <td>{email}</td>
                                    </tr>
                                    <tr>
                                        <td>Imię i nazwisko:</td>
                                        <td>
                                            {first_name} {last_name}
                                        </td>
                                    </tr>
                                    {isManufacturer && (
                                        <tr>
                                            <td>Telefon:</td>
                                            <td>{phone}</td>
                                        </tr>
                                    )}
                                </Table.Content>
                            </Table>
                        </section>
                        {this.renderBottomSection()}
                    </div>
                </div>

                <ChangePasswordModal
                    isOpen={isModalOpen}
                    handleClose={closeModal}
                    handleSubmit={handleSubmitChangePassword}
                />
            </div>
        )
    }

    toggleShowSubcategories = categoryId => {
        this.setState(prevState => ({
            shownIdSubcategories:
                categoryId === prevState.shownIdSubcategories
                    ? null
                    : categoryId
        }))
    }

    renderBottomSection() {
        const {
            isManufacturer,
            isSalesman,
            account,
            cx
        } = this.props
        const { shownIdSubcategories } = this.state

        if (isSalesman) {
            return (
                <section>
                    <h3>Przydzielone kategorie</h3>

                    <ul className={cx('categoriesList')}>
                        {account.data.categories
                            .filter(category => category.parent_id === null)
                            .map((category, index) => (
                                <li key={index} className={cx({
                                        shownSubcategory:
                                            category.id ===
                                            shownIdSubcategories
                                    })}>
                                    <div
                                        className={cx('category')}
                                        onClick={() =>
                                            this.toggleShowSubcategories(
                                                category.id
                                            )
                                        }
                                    >
                                        <IconArrow />
                                        {category.name}
                                    </div>
                                    <div className={cx('subcategories')}>
                                        <Table>
                                            <Table.Content>
                                                {account.data.categories.filter(subcategory => subcategory.parent_id === category.id)
                                                    .map((subcategory, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {subcategory.name}
                                                            </td>
                                                        </tr>    
                                                    ))}
                                            </Table.Content>
                                        </Table>
                                    </div>
                                </li>
                            ))}
                    </ul>
                </section>
            )
        }

        if (isManufacturer) {
            return (
                <section>
                    <h3>Dane mojej firmy</h3>

                    <Table>
                        <Table.Content>
                            <tr>
                                <td>Nazwa firmy</td>
                                <td>{account.data.company.name}</td>
                            </tr>
                            <tr>
                                <td>Rok założenia</td>
                                <td>{account.data.company.established}</td>
                            </tr>
                        </Table.Content>
                    </Table>

                    <h4>Profil / historia działalności</h4>
                    <p>{account.data.company.description}</p>
                </section>
            )
        }
    }
}

export default withStyles(styles)(AccountPage)
