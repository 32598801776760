import React, { Component } from 'react'

import { withStyles } from '@mindz/react-hoc'
import styles from './index.module.css'

class Main extends Component {
    render() {
        const { cx, children } = this.props

        return <section className={cx('main')}>{children}</section>
    }
}

export default withStyles(styles)(Main)
