import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withPermissions } from 'HOC'
import idx from 'idx'

import * as companiesActions from 'actions/companies'
import * as productsActions from 'actions/products'
import * as flashMessagesActions from 'actions/flashMessages'

import CompanyPage from './CompanyPage'

const genrateButtonStatus = ({ company, isAdmin }) => {
    const list = company.data.requests && company.data.requests.last ? company.data.requests.last : []
    const all = company.data.requests && company.data.requests.all ? company.data.requests.all : 0

    const showLoadAllButton =
        isAdmin &&
        list.length > 3 &&
        list.length < all

    return showLoadAllButton
}

class CompanyPageContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            products: {
                data: [],
                meta: {
                    current_page: 1,
                    pages: null,
                    total: null
                }
            },
            showLoadAllButton: genrateButtonStatus(props)
        }
    }

    componentDidUpdate(prevProps) {
        if (
            idx(prevProps, _ => _.company.data.id) !==
            idx(this.props, _ => _.company.data.id)
        ) {
            this.setState({
                showLoadAllButton: genrateButtonStatus(this.props)
            })
        }
    }

    componentDidMount() {
        const { actions, match, history, isManufacturer } = this.props

        if (isManufacturer) {
            return history.push('/')
        }

        actions.fetchCompany(match.params.id).catch(response => {
            if (response.status === 403) {
                actions.addFlashMessage({
                    type: 'failure',
                    title: 'Nie masz dostępu do szczegółów tej firmy.'
                })

                return history.push('/firmy')
            }

            if (response.status === 404) {
                history.push('/')
            }
        })

        document.getElementsByTagName('BODY')[0].classList.add('pageCompany')
    }

    componentWillUnmount() {
        document.getElementsByTagName('BODY')[0].classList.remove('pageCompany')
    }

    render() {
        return (
            <CompanyPage
                {...this.props}
                products={this.state.products}
                showLoadAllButton={this.state.showLoadAllButton}
                onLoadRestRequestsClick={this.onLoadRestRequestsClick}
                handleLoadMore={this.handleLoadMore}
            />
        )
    }

    onLoadRestRequestsClick = () => {
        const { actions, company } = this.props
        this.setState({ showLoadAllButton: false })

        actions
            .fetchNextProducts({
                page: 2,
                length: 4,
                user_id: company.data.manufacturer.id
            })
            .then(products => {
                this.setState({ products })
            })
    }

    handleLoadMore = () => {
        const { products } = this.state
        const { actions, company } = this.props

        actions
            .fetchNextProducts({
                page: products.meta.current_page + 1,
                length: 4,
                user_id: company.data.manufacturer.id
            })
            .then(({ data, meta }) => {
                this.setState({
                    products: {
                        data: [...this.state.products.data, ...data],
                        meta
                    }
                })
            })
    }
}

const mapStateToProps = state => {
    return {
        company: state.local.companies.current
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                ...companiesActions,
                ...productsActions,
                ...flashMessagesActions
            },
            dispatch
        )
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withPermissions()
)
export default enhance(CompanyPageContainer)
