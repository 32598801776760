import React, { Component, Fragment } from 'react'
import { withStyles } from '@mindz/react-hoc'

import { Button } from 'components/forms'
import { DebounceInputField } from 'components/forms'
import Skeleton from 'components/Skeleton'
import SubHeader from 'components/SubHeader'
import SectionLoader from 'components/SectionLoader'
import ProductList from './ProductList'
import CreateFirstProduct from './CreateFirstProduct'
import FlashMessageContainer from 'components/FlashMassageContainer'

import IconCirclePlus from 'assets/icons/circle-plus.module.svg'

import styles from './index.module.css'
import buttonCustomStyles from './overrides/Button.module.css'
import flashMessageContainerStyles from './overrides/FlashMessageContainer.module.css'

class ProductListPage extends Component {
    state = {
        isNewModalOpen: false
    }

    render() {
        const { cx } = this.props
        const { isNewModalOpen } = this.state
        return (
            <section className={cx('root')}>
                <CreateFirstProduct.Modal
                    isOpen={isNewModalOpen}
                    handleClose={() => this.setState({ isNewModalOpen: false })}
                />
                {this.renderList()}
            </section>
        )
    }

    renderList() {
        const {
            products,
            hasProducts,
            handleLoadMore,
            isManufacturer
        } = this.props

        if (isManufacturer) {
            if (!hasProducts) {
                return <CreateFirstProduct {...this.props} />
            }
        }

        if (!isManufacturer) {
            if (!hasProducts) {
                return this.renderEmptyInfo()
            }
        }

        return (
            <Fragment>
                {this.renderHeader()}
                <Skeleton
                    fetchStatus={products.fetchStatus}
                    component={SectionLoader}
                    render={() => (
                        <Fragment>
                            <FlashMessageContainer
                                customStyles={flashMessageContainerStyles}
                            />
                            <ProductList
                                items={products.data}
                                meta={products.meta}
                                handleLoadMore={handleLoadMore}
                            />
                        </Fragment>
                    )}
                />
            </Fragment>
        )
    }

    renderHeader() {
        const {
            cx,
            products,
            isManufacturer,
            hasProducts,
            title,
            query,
            handleChangeQuery,
            handleSubmitQuery
        } = this.props

        return (
            <SubHeader title={title} count={products.meta.total}>
                {!isManufacturer && hasProducts && (
                    <div className={cx('searchHolder')}>
                        <div>
                            <DebounceInputField
                                name="search"
                                placeholder="Szukaj zgłoszenia"
                                search
                                value={query}
                                handleChange={handleChangeQuery}
                                handleDebounceChange={handleSubmitQuery}
                            />
                        </div>
                    </div>
                )}
                {isManufacturer && (
                    <div className={cx('addNewEntry')}>
                        <Button
                            size="m-size"
                            icon={IconCirclePlus}
                            customStyles={buttonCustomStyles}
                            onClick={() =>
                                this.setState({
                                    isNewModalOpen: true
                                })
                            }
                        >
                            Dodaj nowe
                        </Button>
                    </div>
                )}
            </SubHeader>
        )
    }

    renderEmptyInfo() {
        const { cx } = this.props
        return (
            <Fragment>
                {this.renderHeader()}
                <section className={cx('emptyList')}>
                    <img
                        src="/assets/icons/papers-add.png"
                        alt="Nie masz jeszcze zgłoszeń."
                    />
                    <h2>Nie masz jeszcze zgłoszeń.</h2>
                </section>
            </Fragment>
        )
    }
}

export default withStyles(styles)(ProductListPage)
