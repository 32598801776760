import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@mindz/react-hoc'
import moment from 'moment'

import { STATUSES_META } from 'constants/product'
import { Button } from 'components/forms'
import InfiniteScroll from 'components/InfiniteScroll'

import styles from './ProductList.module.css'
import buttonCustomStyles from './overrides/Button.module.css'

import IconSuitcase from 'assets/icons/suitcase.module.svg'
import IconWatch from 'assets/icons/watch.module.svg'
import IconLoading from 'assets/icons/loading.module.svg'
import NoImage from 'assets/no-image.module.svg'

const IMAGE_DIMENSIONS = '?width=270&height=270'

class ProductList extends Component {
    render() {
        const { cx, items, meta, handleLoadMore } = this.props

        return (
            <div className={cx('root')}>
                <InfiniteScroll
                    loadMore={handleLoadMore}
                    hasMore={meta.current_page < meta.pages}
                    loader={
                        <div className={cx('loader')} key={0}>
                            <IconLoading />
                            <span>Ładowanie...</span>
                        </div>
                    }
                >
                    <ul>{items.map(product => this.renderProduct(product))}</ul>
                </InfiniteScroll>
            </div>
        )
    }

    renderProduct(product) {
        const { cx } = this.props

        const id = product.id

        const status = STATUSES_META[product.status.status]

        const productName = product.name

        const image = product.images.length
            ? !!product.images.find(item => item.main === 1)
                ? product.images.find(item => item.main === 1).path
                : product.images[0].path
            : null

        const categoryName = product.category ? product.category.name : ''

        const manufacturer =
            product.manufacturer && product.manufacturer.company
                ? product.manufacturer.company.name
                : ''

        const date = product.requested_at
            ? moment(product.requested_at).format('YYYY-MM-DD')
            : moment(product.created_at).format('YYYY-MM-DD')

        return (
            <li key={id}>
                <Link className={cx('product')} to={'zgloszenia/' + id}>
                    <strong
                        className={cx('status')}
                        style={{
                            backgroundColor: status.backgroundColor,
                            color: status.color
                        }}
                    >
                        {status.label}
                    </strong>
                    {this.renderProductImage(image, product.name)}
                    <abbr title={categoryName} className={cx('categories')}>
                        {categoryName}
                    </abbr>
                    <h3>{productName}</h3>
                    {this.renderFooter(manufacturer, date)}
                </Link>
            </li>
        )
    }

    renderProductImage = (image, productName) => {
        const { cx } = this.props
        if (image) {
            return (
                <div
                    className={cx('image')}
                    style={{
                        backgroundImage: `url(${image}${IMAGE_DIMENSIONS})`
                    }}
                >
                    {productName}
                </div>
            )
        }

        return (
            <div className={cx('image', 'imageNotAvailable')}>
                <NoImage />
                Brak zdjęć
            </div>
        )
    }

    renderFooter(manufacturer, created) {
        const { cx } = this.props
        return (
            <div className={cx('footer')}>
                <div className={cx('content')}>
                    <p>
                        <IconSuitcase />
                        <span>{manufacturer}</span>
                    </p>
                    <p>
                        <IconWatch />
                        <span>{created}</span>
                    </p>
                </div>
                <Button
                    theme="white"
                    size="m-size"
                    customStyles={buttonCustomStyles}
                >
                    ZOBACZ WIĘCEJ
                </Button>
            </div>
        )
    }
}

export default withStyles(styles)(ProductList)
