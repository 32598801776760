import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'

import { Modal } from 'components/modal'
import styles from './index.module.css'
import dialogStyles from './overrides/Dialog.module.css'
import {Button} from "../../../components/forms";
import buttonStyles from "./overrides/Button.module.css";

class RemindPasswordConfirmationModal extends Component {
    render() {
        const { cx, isOpen, handleClose } = this.props
        return (
            <Modal isOpen={isOpen} handleClose={handleClose}>
                <Modal.Dialog customStyles={dialogStyles}>
                    <Modal.Header>
                        <span className={cx("icon")}></span>
                        <Modal.Title>Odbierz wiadomość</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={cx("message")}>
                            Na Twojego e-maila została wysłana wiadomość z dalszą instrukcją odzyskiwnia hasła.
                        </div>
                        <div className={cx('actions', 'spaceAround')}>
                            <Button
                                type="button"
                                size="m-size"
                                theme="white"
                                customStyles={buttonStyles}
                                className={cx('grow0')}
                                onClick={handleClose}
                            >
                                Zamknij
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        )
    }
}

export default withStyles(styles)(RemindPasswordConfirmationModal)
