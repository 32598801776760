import { CALL_API } from 'middlewares/api'

import * as actionTypes from 'constants/actionTypes'

export const readWrappings = () => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/dictionaries/wrappings',
            options: {
                method: 'GET'
            },
            types: [
                actionTypes.READ_DICTIONARIES_WRAPPINGS_REQUEST,
                actionTypes.READ_DICTIONARIES_WRAPPINGS_SUCCESS,
                actionTypes.READ_DICTIONARIES_WRAPPINGS_FAILURE
            ]
        }
    })
}

export const readCollectiveWrappings = () => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/dictionaries/collective-wrappings',
            options: {
                method: 'GET'
            },
            types: [
                actionTypes.READ_DICTIONARIES_COLLECTIVE_WRAPPINGS_REQUEST,
                actionTypes.READ_DICTIONARIES_COLLECTIVE_WRAPPINGS_SUCCESS,
                actionTypes.READ_DICTIONARIES_COLLECTIVE_WRAPPINGS_FAILURE
            ]
        }
    })
}

export const readUnits = () => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/dictionaries/units',
            options: {
                method: 'GET'
            },
            types: [
                actionTypes.READ_DICTIONARIES_UNITS_REQUEST,
                actionTypes.READ_DICTIONARIES_UNITS_SUCCESS,
                actionTypes.READ_DICTIONARIES_UNITS_FAILURE
            ]
        }
    })
}

export const readCountries = () => dispatch => {
    return dispatch({
        [CALL_API]: {
            endpoint: '/dictionaries/countries',
            options: {
                method: 'GET'
            },
            types: [
                actionTypes.READ_DICTIONARIES_COUNTRIES_REQUEST,
                actionTypes.READ_DICTIONARIES_COUNTRIES_SUCCESS,
                actionTypes.READ_DICTIONARIES_COUNTRIES_FAILURE
            ]
        }
    })
}
