import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'

import { Modal } from 'components/modal'
import { Button } from 'components/forms'

import styles from './index.module.css'
import dialogStyles from './overrides/Dialog.module.css'
import buttonStyles from './overrides/Button.module.css'

class ConfirmDeleteModal extends Component {
    render() {
        const { cx, isOpen, handleSubmit, handleClose, isSubmitting } = this.props
        return (
            <Modal isOpen={isOpen} handleClose={handleClose}>
                <Modal.Dialog customStyles={dialogStyles}>
                    <Modal.Header handleClose={handleClose}>
                        <Modal.Title>
                            Czy na pewno chce usunąć użytkownika?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={cx('actions')}>
                            <Button
                                type="button"
                                size="m-size"
                                theme="white"
                                customStyles={buttonStyles}
                                className={cx('grow0')}
                                onClick={handleClose}
                            >
                                Anuluj
                            </Button>
                            <Button
                                type="submit"
                                size="m-size"
                                customStyles={buttonStyles}
                                className={cx('grow1')}
                                isLoading={isSubmitting}
                                onClick={() => handleSubmit()}
                            >
                                Usuń
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        )
    }
}

export default withStyles(styles)(ConfirmDeleteModal)
