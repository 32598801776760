import React, { Component, Fragment } from 'react'
import { Field as FormikField } from 'formik'

import ScrollToError from './ScrollToError'

const getDescendantProp = (obj, path) =>
    path.split('.').reduce((acc, part) => acc && acc[part], obj)

class Field extends Component {
    static defaultProps = {
        isReadOnly: false
    }

    constructor(props) {
        super(props)

        this.inputRef = React.createRef()
    }

    render() {
        const { name, defaultValue, isReadOnly } = this.props

        return (
            <FormikField
                name={name}
                render={({ field, form: { touched, errors, ...props } }) => {
                    const value = isReadOnly
                        ? defaultValue
                            ? defaultValue
                            : field.value
                        : field.value

                    return (
                        <Fragment>
                            <ScrollToError
                                {...props}
                                inputRef={this.inputRef}
                                name={name}
                                errors={errors}
                            />

                            {this.renderField({
                                name: field.name,
                                value: value,
                                handleChange: props.handleChange,
                                handleBlur: props.handleBlur,
                                setFieldValue: props.setFieldValue,
                                error: getDescendantProp(errors, name)
                                    ? getDescendantProp(errors, name)
                                    : errors[name]
                                    ? Array.isArray(errors[name])
                                        ? errors[name][0]
                                        : errors[name]
                                    : null
                            })}
                        </Fragment>
                    )
                }}
            />
        )
    }

    renderField(injectedProps) {
        const { render, component, children, ...rest } = this.props

        if (typeof render === 'function') {
            return render({ ...injectedProps })
        }

        return React.createElement(component, {
            ...injectedProps,
            ...rest,
            children
        })
    }
}

export default Field
