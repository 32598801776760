import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'

import { Modal } from 'components/modal'
import styles from './index.module.css'
import { Button, InputField } from 'components/forms'

import { Formik } from 'formik'
import { Field } from '@mindz/react-admin-ui'
import passwordSchema from './passwordSchema'
import buttonStyles from './overrides/Button.module.css'

class ChangePasswordModal extends Component {
    render() {
        const { isOpen, handleClose } = this.props

        return (
            <Modal isOpen={isOpen} handleClose={handleClose}>
                <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title>Zmień hasło</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.renderForm()}</Modal.Body>
                </Modal.Dialog>
            </Modal>
        )
    }

    renderForm() {
        const { cx, handleSubmit, handleClose } = this.props

        return (
            <Formik
                initialValues={{
                    oldPassword: '',
                    newPassword: '',
                    passwordConfirm: ''
                }}
                onSubmit={handleSubmit}
                validationSchema={passwordSchema}
                validateOnChange={false}
                validateOnBlur={false}
                render={({ handleSubmit, isSubmitting, values, errors }) => {
                    return (
                        <form className={cx('form')} onSubmit={handleSubmit}>
                            {errors.error && (
                                <div className={cx('messageFailure')}>
                                    {errors.error}
                                </div>
                            )}

                            <div className={cx('row')}>
                                <Field
                                    name="oldPassword"
                                    headerLabel="Stare hasło"
                                    type="password"
                                    component={InputField}
                                />
                            </div>

                            <div className={cx('row')}>
                                <Field
                                    name="newPassword"
                                    headerLabel="Nowe hasło"
                                    headerSecondLabel="Minimum 8 znaków"
                                    type="password"
                                    component={InputField}
                                />
                            </div>

                            <div className={cx('row')}>
                                <Field
                                    name="passwordConfirm"
                                    headerLabel="Powtórz nowe hasło"
                                    type="password"
                                    component={InputField}
                                />
                            </div>

                            <div className={cx('actions')}>
                                <Button
                                    type="button"
                                    size="m-size"
                                    theme="white"
                                    onClick={handleClose}
                                    customStyles={buttonStyles}
                                    className={cx('grow0')}
                                >
                                    Anuluj
                                </Button>
                                <Button
                                    type="submit"
                                    size="m-size"
                                    isLoading={isSubmitting}
                                    customStyles={buttonStyles}
                                    className={cx('grow1')}
                                >
                                    Zapisz
                                </Button>
                            </div>
                        </form>
                    )
                }}
            />
        )
    }
}

export default withStyles(styles)(ChangePasswordModal)
