import { combineReducers } from 'redux'

import * as actionTypes from 'constants/actionTypes'
import { fetchStatusHelpers } from '@mindz/react-helpers'

const {
    initialState,
    requestState,
    successState,
    failureState
} = fetchStatusHelpers

const fetchStatusReducer = (state = initialState(), action) => {
    if (
        actionTypes.READ_DICTIONARIES_UNITS_REQUEST ===
        action.type
    ) {
        return requestState()
    }

    if (
        actionTypes.READ_DICTIONARIES_UNITS_SUCCESS ===
        action.type
    ) {
        return successState(action)
    }

    if (
        actionTypes.READ_DICTIONARIES_UNITS_FAILURE ===
        action.type
    ) {
        return failureState(action)
    }

    return state
}

const dataReducer = (state = [], action) => {
    if (
        actionTypes.READ_DICTIONARIES_UNITS_SUCCESS ===
        action.type
    ) {
        return [
            ...action.payload.data
        ]
    }

    return state
}

export default combineReducers({
    fetchStatus: fetchStatusReducer,
    data: dataReducer
})
