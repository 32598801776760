import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

class PrivateRoute extends Component {
    render() {
        const { component: Component, user, children, ...rest } = this.props

        const pathname = this.props.location.pathname

        return (
            <Route
                {...rest}
                render={props =>
                    user.loggedIn === true ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to={'/logowanie?back=' + pathname} />
                    )
                }
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.local.user
    }
}

export default connect(mapStateToProps)(PrivateRoute)
