import React, { Component } from 'react'

import { withStyles } from '@mindz/react-hoc'
import Cookies from "components/Cookies"
import styles from './index.module.css'

class AuthLayout extends Component {

    render() {
        const { cx, children } = this.props

        return (
            <div className={cx("root")}>
                {children}
                <Cookies />
            </div>
        )
    }

}

export default withStyles(styles)(AuthLayout)