import { combineReducers } from 'redux'
import { fetchStatusHelpers } from '@mindz/react-helpers'

import * as actionTypes from 'constants/actionTypes'

const {
    initialState,
    requestState,
    successState,
    failureState
} = fetchStatusHelpers

const fetchStatusReducer = (state = initialState(), action) => {
    if (actionTypes.FETCH_PRODUCT_LIST_REQUEST === action.type) {
        return requestState()
    }

    if (actionTypes.FETCH_PRODUCT_LIST_SUCCESS === action.type) {
        return successState(action)
    }

    if (actionTypes.FETCH_PRODUCT_LIST_FAILURE === action.type) {
        return failureState(action)
    }

    return state
}

const nextFetchStatusReducer = (state = initialState(), action) => {
    if (actionTypes.FETCH_PRODUCT_LIST_NEXT_REQUEST === action.type) {
        return requestState()
    }

    if (actionTypes.FETCH_PRODUCT_LIST_NEXT_SUCCESS === action.type) {
        return successState(action)
    }

    if (actionTypes.FETCH_PRODUCT_LIST_NEXT_FAILURE === action.type) {
        return failureState(action)
    }

    return state
}

const dataReducer = (state = [], action) => {
    if (actionTypes.FETCH_PRODUCT_LIST_SUCCESS === action.type) {
        return [...action.payload.data]
    }

    if (actionTypes.FETCH_PRODUCT_LIST_NEXT_SUCCESS === action.type) {
        return [...state, ...action.payload.data]
    }

    return state
}

export const metaReducer = (state = {}, action) => {
    if (actionTypes.FETCH_PRODUCT_LIST_REQUEST === action.type) {
        return {}
    }

    if (
        actionTypes.FETCH_PRODUCT_LIST_SUCCESS === action.type ||
        actionTypes.FETCH_PRODUCT_LIST_NEXT_SUCCESS === action.type
    ) {
        return {
            ...action.payload.meta
        }
    }

    if (actionTypes.FETCH_PRODUCT_LIST_FAILURE === action.type) {
        return {}
    }

    return state
}

export default combineReducers({
    fetchStatus: fetchStatusReducer,
    nextFetchStatus: nextFetchStatusReducer,
    data: dataReducer,
    meta: metaReducer
})
