import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from 'actions/products'
import ProductListPage from './ProductListPage'

class ProductListPageContainer extends Component {
    componentDidMount() {
        const { actions, filters } = this.props

        const params = {
            ...filters,
            sort_by: null,
            sort_direction: null,
            length: 6
        }

        actions.fetchProducts(params)
    }

    loadMore = () => {
        const { products, filters, actions } = this.props

        if (
            !products.nextFetchStatus.isLoading &&
            products.meta.current_page + 1 <= products.meta.pages
        ) {
            const params = {
                ...filters,
                sort_by: null,
                sort_direction: null,
                page: products.meta.current_page + 1,
                length: 6
            }

            actions.fetchNextProducts(params)
        }
    }

    handleChangeQuery = value => {
        const { actions } = this.props

        actions.updateProductListFilters({
            query: value
        })
    }

    handleSubmitQuery = () => {
        const { actions, filters } = this.props

        const params = {
            sort_by: null,
            sort_direction: null,
            length: 6,
            ...filters
        }

        actions.fetchProducts(params)
    }

    render() {
        const { products, filters, count, hasProducts, ...props } = this.props
        return (
            <ProductListPage
                {...props}
                title="Zgłoszenia"
                products={products}
                count={count}
                query={filters.query}
                hasProducts={hasProducts}
                handleLoadMore={this.loadMore}
                handleChangeQuery={this.handleChangeQuery}
                handleSubmitQuery={this.handleSubmitQuery}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        products: state.db.products.list,
        filters: state.local.products.list.filters
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductListPageContainer)
