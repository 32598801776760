import { combineReducers } from 'redux'
import { fetchStatusHelpers } from '@mindz/react-helpers'

import * as actionTypes from 'constants/actionTypes'

const {
    initialState,
    requestState,
    successState,
    failureState
} = fetchStatusHelpers

const fetchStatusReducer = (state = initialState(), action) => {
    if (actionTypes.READ_PRODUCT_REQUEST === action.type) {
        return requestState()
    }

    if (actionTypes.READ_PRODUCT_SUCCESS === action.type) {
        return successState(action)
    }

    if (actionTypes.READ_PRODUCT_FAILURE === action.type) {
        return failureState(action)
    }

    if (
        actionTypes.RESET_CURRENT_PRODUCT === action.type ||
        actionTypes.UPDATE_PRODUCT_STATUS_SUCCESS === action.type
    ) {
        return initialState()
    }

    return state
}

const dataReducer = (state = {}, action) => {
    if (actionTypes.READ_PRODUCT_SUCCESS === action.type) {
        return { ...action.payload.data }
    }

    return state
}

export default combineReducers({
    fetchStatus: fetchStatusReducer,
    data: dataReducer
})
